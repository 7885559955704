import * as React from 'react';
import { Typography, Theme, Chip, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { postSelectedAction } from 'src/store/action/socialWatchAction';
import { useHistory } from 'react-router-dom';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import PostWatch from './PostWatch';
import FeedScreen from './FeedScreen';
import { scrollStyle } from 'src/constants/styles';

import { makeStyles } from '@mui/styles';
import { searchVideos } from 'src/apis/socialPost.api';

const useStyles = makeStyles((theme: Theme) => ({
  root: { overflow: 'auto' },
  wrapBoxAction: {
    width: '100%',
    height: 56,
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto'
    }
  },
  wrapTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '22px !important',
    fontWeight: '700 !important',
    marginRight: '10px !important'
  },
  chip: {
    margin: '0px 5px !important'
  },
  textChip: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  wrapPost: {
    width: '100%',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1) !important',
    backgroundColor: theme.palette.mode === 'dark' ? '#242526' : '#fff',
    height: 'calc(100vh - 325px)',
    [theme.breakpoints.down('xl')]: {
      height: 'calc(100vh - 50px)' // Điều chỉnh chiều cao cho màn hình nhỏ hơn
    },
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vh - 100px)' // Điều chỉnh chiều cao cho màn hình nhỏ hơn
    }
  },
  wrapWatch: {
    height: 'calc(100vh - 112px)',
    overflowY: 'auto',
    ...scrollStyle
  }
}));

const WatchScreen = props => {
  const { setOpen, open } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [widthLeft, setWidthLeft] = React.useState<any>(900);
  const [keyword, setKeyword] = React.useState<string>('');
  const contentRef = React.useRef<any>(null);
  const [listSearchVideos, setListSearchVideos] = React.useState<any>(null);

  const handleSearch = async () => {
    let response = await searchVideos(keyword, 'watch');
    if (response.status === 200) {
      setListSearchVideos(response.data);
    }
  };
  function scrollToTop() {
    contentRef.current.scrollTop = 0;
  }
  React.useEffect(() => {
    if (keyword) {
      handleSearch();
    }
  }, [keyword]);

  const listMenuWatch = [
    {
      id: 'watch',
      label: 'Trang chủ'
    },
    {
      id: 'watch-live',
      label: 'Trực tiếp'
    },
    {
      id: 'watch-shows',
      label: 'Chương trình'
    },
    {
      id: 'watch-save',
      label: 'Video đã lưu'
    }
  ];

  const handleClickChip = key => {
    if (key === 'watch') {
      history.push('/watch');
    } else {
      history.push(`/watch/${key}`);
    }
    setOpen && setOpen(false);
    dispatch(postSelectedAction({}));
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapBoxAction}>
        <div className={classes.wrapTitle}>
          <Typography className={classes.title}>Watch</Typography>
          <Box display="flex">
            {listMenuWatch?.map((el: any) => (
              <Chip
                className={classes.chip}
                key={el.id}
                label={
                  <Typography className={classes.textChip}>
                    {el.label}
                  </Typography>
                }
                onClick={() => handleClickChip(el.id)}
              />
            ))}
          </Box>
        </div>
        <Box sx={{ width: { xs: 200, lg: 300 } }}>
          <InputSearch
            label="Tìm kiếm video"
            keyword={keyword}
            setKeyword={setKeyword}
            type={'video'}
            action={() => {
              history.push(`/watchs/search?q=${keyword || ''}`);
              setOpen && setOpen(false);
            }}
          />
        </Box>
      </div>
      <div id="scroll-watch" ref={contentRef} className={classes.wrapWatch}>
        <div className={classes.wrapPost}>
          <PostWatch
            setWidthLeft={setWidthLeft}
            open={open}
            type="watch-screen"
          />
        </div>
        <FeedScreen
          widthLeft={widthLeft}
          setOpen={setOpen}
          scrollToTop={scrollToTop}
        />
      </div>
    </div>
  );
};

export default WatchScreen;
