import CloseIcon from '@mui/icons-material/Close';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  Avatar,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';
import DialogLayoutProfile from 'src/components/Dialog/DialogEditProfile/DialogLayoutProfile';
import DialogUpdateDescription from 'src/components/Dialog/DialogUpdateDescription';
import DialogUserHobby from 'src/components/Dialog/DialogUserHobby';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { buttonColor, modalBackdrop } from 'src/constants/styles';
import Overview from 'src/pages/User/ProfileBody/DetailIntro/Overview';
import AvatarBanner from './AvatarBanner';
import FMRelationship from './FamilyRelationship';
import Introduce from './Introduce';
import { getAboutUser } from 'src/apis/socialUser';
import DialogConfirm from '../DialogConfirm';
import group_default from 'src/assets/images/group_cover.png';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '720px',
    width: 'calc(100% - 64px)',
    borderRadius: '10px'
  },
  '& .MuiDialog-container': {
    overflow: 'hidden auto'
  }
}));

const useStyles = makeStyles((theme: Theme) => ({
  wrapTitle: {
    fontSize: '20px !important',
    fontWeight: '700 !important'
  },
  wrapSubTitle: {
    fontSize: '17px !important',
    color: `${buttonColor.backgroundColor} !important`,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  avatar: {
    width: '168px !important',
    height: '168px !important'
  },
  wrapHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  wrapContent: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0px'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}));

interface DialogEditProfileProps {
  open: boolean;
  aboutUser: any;
  setAboutUser: React.Dispatch<React.SetStateAction<any>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonSocial: any;
  lifeEvents: any;
}

const DialogEditProfile: React.FC<DialogEditProfileProps> = React.memo(
  props => {
    const { open, aboutUser, setAboutUser, setOpen, buttonSocial, lifeEvents } =
      props;
    const classes = useStyles();
    const userInfor =
      useSelector((state: any) => state.userReducer?.infor) || {};
    const [openHobby, setOpenHobby] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [hobbySelected, setHobbySelected] = React.useState<any>([]);
    const [description, setDescription] = React.useState('');
    const [keyTabAvatar, setKeyTabAvatar] = React.useState('');
    const [frameAvatar, setFrameAvatar] = React.useState<any>(null);

    const [objectUpdate, setObjectUpdate] = React.useState<any>({
      isIntroduce: false,
      children: <></>,
      title: '',
      hasAction: false
    });
    const [updateDescription, setUpdateDescription] = React.useState(false);
    const [files, setFiles] = React.useState<any>([]);
    const [type, setType] = React.useState('introduce');
    const [openAvatar, setOpenAvatar] = React.useState(false);
    const [openBanner, setOpenBanner] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const meInfo = useSelector((state: any) => state.meReducer.info);
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    const [openConfirm, setOpenConfirm] = React.useState<any>(false);
    const [dataUpdate, setDataUpdate] = React.useState<any>({});
    const [visibility, setVisibility] = React.useState<any>([
      {
        field: 'birth_year',
        visibility: 'public'
      },
      {
        field: 'birth_date',
        visibility: 'public'
      },
      {
        field: 'birth_month',
        visibility: 'public'
      },
      {
        field: 'other_name',
        visibility: 'public'
      },
      {
        field: 'place_live_id',
        visibility: 'public'
      },
      {
        field: 'gender',
        visibility: 'public'
      },
      {
        field: 'hometown_id',
        visibility: 'public'
      },
      {
        field: 'phone_number',
        visibility: 'public'
      }
    ]);
    async function fetchAboutUser(id) {
      let response = await getAboutUser(id, null);
      if (response.status === 200) {
        setAboutUser(response.data);
      }
    }

    const handleClose = () => {
      setOpen(false);
      setIsError(false);
      fetchAboutUser(meInfo?.id);
    };

    const handleCloseDialog = () => {
      handleClose();
    };

    React.useEffect(() => {
      if (open) {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      }
    }, [open]);

    React.useEffect(() => {
      if (aboutUser && Object.keys(aboutUser).length) {
        setHobbySelected(aboutUser.hobbies);
        setDescription(aboutUser.general_information?.description);
        if (type === 'introduce') {
          setObjectUpdate(prev => ({
            ...prev,
            children: (
              <Introduce
                aboutUser={aboutUser}
                dataUpdate={dataUpdate}
                setDataUpdate={setDataUpdate}
                visibility={visibility}
                setVisibility={setVisibility}
                lifeEvents={lifeEvents}
                isError={isError}
                setIsError={setIsError}
                buttonSocial={buttonSocial}
                userInfo={userInfor}
                setOpenEdit={setOpenEdit}
                setOpenDialog={setOpen}
              />
            ),
            title: 'Chỉnh sửa chi tiết',
            hasAction: true
          }));
        } else if (type === 'relationship') {
          setObjectUpdate(prev => ({
            ...prev,
            children: (
              <FMRelationship
                aboutUser={aboutUser}
                setAboutUser={setAboutUser}
              />
            ),
            title: 'Chỉnh sửa gia đình và các mối quan hệ',
            hasAction: false
          }));
        }
      }
    }, [
      type,
      JSON.stringify(aboutUser),
      JSON.stringify(files),
      JSON.stringify(dataUpdate),
      JSON.stringify(visibility),
      isError
    ]);

    React.useEffect(() => {
      if (
        aboutUser?.general_information &&
        Object.keys(aboutUser?.general_information).length
      ) {
        setDataUpdate({
          place_live: aboutUser?.general_information?.place_live,
          hometown: aboutUser?.general_information?.hometown,
          address: aboutUser?.general_information?.address,
          gender: aboutUser?.general_information?.gender,
          birth_date: aboutUser?.general_information?.birth_date,
          birth_month: aboutUser?.general_information?.birth_month,
          birth_year: aboutUser?.general_information?.birth_year,
          other_name: aboutUser?.general_information?.other_name,
          phone_number: aboutUser?.general_information?.phone_number,
          account_web_link: aboutUser?.general_information?.account_web_link,
          account_social: aboutUser?.general_information?.account_social
        });
      }
    }, [JSON.stringify(aboutUser), openEdit]);

    return (
      <div>
        <BootstrapDialog
          open={open}
          onClose={handleCloseDialog}
          scroll={'paper'}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          BackdropProps={modalBackdrop}
        >
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <Typography
                style={{ fontWeight: 'bold', fontSize: 20 }}
                textAlign={'center'}
              >
                Chỉnh sửa trang cá nhân
              </Typography>
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div className={classes.wrapHeader}>
                <Typography className={classes.wrapTitle}>
                  Ảnh đại diện
                </Typography>
                <Typography
                  onClick={() => {
                    setOpenAvatar(true);
                  }}
                  className={classes.wrapSubTitle}
                >
                  Chỉnh sửa
                </Typography>
              </div>
              <div className={classes.wrapContent}>
                <AvatarSocial
                  type="feed"
                  avatarObj={userInfor?.avatar_media ?? meInfo?.avatar_media}
                  style={{ width: 168, height: 168 }}
                />
              </div>
              <div className={classes.wrapHeader}>
                <Typography className={classes.wrapTitle}>Ảnh bìa</Typography>
                <Typography
                  onClick={() => {
                    setOpenBanner(true);
                  }}
                  className={classes.wrapSubTitle}
                >
                  Chỉnh sửa
                </Typography>
              </div>
              <div className={classes.wrapContent}>
                <div
                  style={{
                    height: 185,
                    width: 500,
                    backgroundImage: `url(${
                      meInfo?.banner?.show_url ??
                      meInfo?.banner?.preview_url ??
                      userInfor?.banner?.show_url ??
                      userInfor?.banner?.preview_url ??
                      group_default
                    })`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderRadius: 10
                  }}
                ></div>
              </div>
              <div className={classes.wrapHeader}>
                <Typography className={classes.wrapTitle}>Tiểu sử</Typography>
                <Typography
                  onClick={() => {
                    setUpdateDescription(true);
                  }}
                  className={classes.wrapSubTitle}
                >
                  {objectUpdate.isIntroduce ? 'Hủy' : 'Chỉnh sửa'}
                </Typography>
              </div>
              <div className={classes.wrapContent}>
                <Typography
                  style={{
                    fontSize: 17,
                    color: '#65676b',
                    wordBreak: 'break-all'
                  }}
                >
                  {!description ? 'Mô tả bản thân' : description}
                </Typography>
              </div>
              <div className={classes.wrapHeader}>
                <Typography className={classes.wrapTitle}>
                  Chỉnh sửa phần giới thiệu
                </Typography>
                <Typography
                  onClick={() => {
                    setOpenEdit(true);
                    setType('introduce');
                  }}
                  className={classes.wrapSubTitle}
                >
                  Chỉnh sửa
                </Typography>
              </div>
              <div className={classes.wrapContent}>
                <Overview
                  userInfo={dataUpdate}
                  relationshipInfo={aboutUser?.account_relationship}
                  lifeEvents={lifeEvents}
                  buttonSocial={buttonSocial}
                />
              </div>
              <div className={classes.wrapHeader}>
                <Typography className={classes.wrapTitle}>Sở thích</Typography>

                <Typography
                  onClick={() => {
                    // setHobbySelected(aboutUser?.hobbies);
                    setOpenHobby(true);
                  }}
                  className={classes.wrapSubTitle}
                >
                  {hobbySelected?.length ? 'Chỉnh sửa' : 'Thêm'}
                </Typography>
              </div>
              {typeof hobbySelected !== 'undefined' &&
              hobbySelected?.length > 0 ? (
                <div style={{ paddingBottom: 10 }}>
                  {hobbySelected?.map((el: any) =>
                    typeof el !== 'undefined' ? (
                      <Chip
                        label={el?.text}
                        variant="outlined"
                        avatar={<Avatar src={el?.icon} />}
                        style={{
                          fontSize: 15,
                          marginRight: 5,
                          fontWeight: 500,
                          marginTop: '10px'
                        }}
                      />
                    ) : null
                  )}
                </div>
              ) : null}
            </DialogContent>
          </>
        </BootstrapDialog>

        {openHobby && (
          <DialogUserHobby
            open={openHobby}
            handleClose={() => {
              setOpenHobby(false);
              setOpen(true);
            }}
            setAboutUser={setAboutUser}
            hobbySelected={hobbySelected}
            setHobbySelected={setHobbySelected}
          />
        )}
        {updateDescription && (
          <DialogUpdateDescription
            description={description}
            setDescription={setDescription}
            aboutUser={aboutUser}
            setAboutUser={setAboutUser}
            open={updateDescription}
            handleClose={() => {
              setUpdateDescription(false);
              setOpen(true);
              setFiles([]);
            }}
          />
        )}

        {openEdit && (
          <DialogLayoutProfile
            open={openEdit}
            setOpen={setOpenEdit}
            keyTab={keyTabAvatar}
            handleClose={() => {
              setOpenEdit(false);
              setOpen(true);
              setFiles([]);
            }}
            type={type}
            files={files}
            title={objectUpdate.title}
            children={objectUpdate.children}
            hasAction={objectUpdate.hasAction}
            dataUpdate={dataUpdate}
            aboutUser={aboutUser}
            setDataUpdate={setDataUpdate}
            visibility={visibility}
            setVisibility={setVisibility}
            setAboutUser={setAboutUser}
            isError={isError}
            setIsError={setIsError}
            buttonSocial={buttonSocial}
            userInfo={userInfor}
          />
        )}
        {openAvatar && (
          <DialogLayoutProfile
            open={openAvatar}
            keyTab={keyTabAvatar}
            handleClose={() => {
              setOpenAvatar(false);
              setKeyTabAvatar('');
              setFiles([]);
            }}
            type="avatar"
            files={files}
            title="Cập nhật ảnh đại diện"
            labelButton="Dùng làm ảnh đại diện"
            userInfo={userInfor}
            frameAvatar={frameAvatar}
            setFrameAvatar={setFrameAvatar}
            children={
              <AvatarBanner
                type="avatar"
                setKeyTabAvatar={setKeyTabAvatar}
                files={files}
                setFiles={setFiles}
                backgroundImage={
                  frameAvatar
                    ? meInfo?.avatar_media?.frame?.url
                    : meInfo?.avatar_media?.url
                }
                frameAvatar={frameAvatar}
                setFrameAvatar={setFrameAvatar}
                avatarObj={
                  userInfor?.avatar_media ?? {
                    ...userInfor?.avatar_media,
                    frame: null,
                    show_url: meInfo?.avatar_static
                  }
                }
              />
            }
          />
        )}
        {openBanner && (
          <DialogLayoutProfile
            open={openBanner}
            keyTab={keyTabAvatar}
            handleClose={() => {
              setOpenBanner(false);
              setKeyTabAvatar('');
              setFiles([]);
            }}
            type="banner"
            files={files}
            title="Cập nhật ảnh bìa"
            userInfo={userInfor}
            children={
              <AvatarBanner
                type="banner"
                setKeyTabAvatar={setKeyTabAvatar}
                files={files}
                setFiles={setFiles}
              />
            }
          />
        )}
        {openConfirm && (
          <DialogConfirm
            title={`Thông báo`}
            text="Tính năng đang phát triển.Vui lòng thử lại sau!!"
            open={openConfirm}
            handleClose={() => setOpenConfirm(false)}
            alignText="center"
            lableButton="OK"
          />
        )}
      </div>
    );
  }
);

export default DialogEditProfile;
