import {
  Box,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { cloneDeep, debounce } from 'lodash';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import logoEmso from 'src/assets/images/LogoEmso.svg';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import CommentBox from 'src/components/CommentBox/Index';
import VisibilityComponent from 'src/components/Visibility';
import { sendTypingStreaming } from 'src/socket/Streaming';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      rootPopper: {
        maxWidth: '350px',
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
        overflow: 'hidden'
      },
      header: {
        backgroundColor: '#7165E0',
        height: '56px',
        position: 'relative'
      },
      boxImage: {
        width: '100%',
        height: '40px'
      },
      imageLogo: {
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        position: 'absolute',
        top: '30px',
        left: '150px'
      },
      textBold: {
        fontSize: '15px !important',
        fontWeight: 'bold !important',
        padding: '10px 0px'
      },
      subText: {
        fontSize: '14px !important',
        opacity: '0.7'
      }
    }),
  { index: 1 }
);

interface Props {
  post?: any;
  visibility?: any;
  focus?: any;
  setFocus?: React.Dispatch<React.SetStateAction<any>>;
  setVisibility?: React.Dispatch<React.SetStateAction<any>>;
  createComment?: any;
  listComment?: any;
  setListComment?: React.Dispatch<React.SetStateAction<any>> | any;
  handleGetComment?: any;
  setGifSelected?: React.Dispatch<React.SetStateAction<any>>;
  gifSelected?: any;
  stickySelected?: any;
  setStickySelected?: React.Dispatch<React.SetStateAction<any>>;
  type?: String;
  chooseFocus?: any;
  setChooseFocus?: React.Dispatch<React.SetStateAction<any>>;
  typeLive?: String;
  setHasMore?: React.Dispatch<React.SetStateAction<any>> | any;
  typeComment?: String;
  info?: any;
}

const PostActionComment = React.memo(
  forwardRef((props: Props, ref: any) => {
    const {
      post,
      visibility,
      focus,
      setFocus,
      setVisibility,
      createComment,
      listComment,
      setListComment,
      handleGetComment,
      setGifSelected,
      gifSelected,
      stickySelected,
      setStickySelected,
      type,
      chooseFocus,
      setChooseFocus,
      typeLive,
      setHasMore,
      typeComment = 'default'
    } = props;
    const theme: any = useTheme();
    const classes = useStyles();
    const groupSelected =
      useSelector((state: any) => state.groupReducer.groupSelected) || {};
    const meInfo = useSelector((state: any) => state.meReducer.info);
    const [openPopper, setOpenPopper] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
      null
    );

    let listVisibility = [
      {
        key: 'newest',
        label: 'Mới nhất',
        subLabel:
          'Hiển thị tất cả bình luận, theo thứ tự từ mới nhất đến cũ nhất'
      },
      {
        key: 'oldest',
        label: 'Cũ nhất',
        subLabel: 'Hiển thị các bình luận cũ nhất'
      },
      {
        key: 'most-liked',
        label: 'Phù hợp nhất',
        subLabel:
          'Hiển thị tất cả bình luận của bạn bè có nhiều lượt tương tác nhất'
      }
    ];

    const functionUpdate = (id, data) => {
      setListComment(prev => {
        let temp = cloneDeep(prev);
        let index = temp.findIndex(el => el.id === id);
        if (index > -1) {
          let keys = [
            'id',
            'created_at',
            'backdated_time',
            'in_reply_to_id',
            'in_reply_to_account_id',
            'sensitive',
            'spoiler_text',
            'visibility',
            'post_type',
            'replies_count',
            'off_comment',
            'reblogs_count',
            'favourites_count',
            'reactions',
            'replies_total',
            'hidden',
            'notify',
            'comment_moderation',
            'viewer_reaction',
            'in_reply_to_parent_id',
            'group',
            'group_account_relationship',
            'page',
            'page_owner',
            'event',
            'media_attachments',
            'mentions',
            'tags',
            'replies',
            'favourites',
            'emojis',
            'status_tags',
            'card'
          ];
          keys.forEach(key => {
            temp[index][key] = data[key];
          });

          temp[index].pre_comment = false;
        }
        return temp;
      });
    };

    const sendTypingEvent = debounce(() => {
      sendTypingStreaming(post.id);
    }, 1000);

    return (
      <Box
        sx={
          typeComment === 'liveChat'
            ? {
                position: 'absolute',
                width: '99%',
                bottom: '30px'
              }
            : {}
        }
      >
        {typeLive ? (
          <ButtonInherit
            label="Tiêu chuẩn cộng đồng"
            style={{
              backgroundColor: 'transparent',
              color: 'rgb(113, 101, 224)',
              fontWeight: '600 !important'
            }}
            action={(event: React.MouseEvent<HTMLButtonElement>) => {
              setAnchorEl(event.currentTarget);
              setOpenPopper(true);
            }}
          />
        ) : null}
        {groupSelected?.inactivate_reason ||
        post?.off_comment ||
        post?.page?.page_relationship?.block_page ? (
          <div
            style={{
              width: '100%',
              borderRadius: '6px',
              padding: '5px 8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #eaeaea'
            }}
          >
            <i
              className="fa-solid fa-message"
              style={{
                fontSize: 15,
                marginRight: '8px',
                padding: '8px',
                backgroundColor: '#d7d7d7',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%'
              }}
            ></i>
            <span
              style={{
                fontSize: 15,
                fontWeight: '500',
                color: '#676767',
                textAlign: 'center'
              }}
            >
              {post?.page?.page_relationship?.block_page
                ? 'Không thể bình luận do bạn đã chặn trang này. '
                : groupSelected?.inactivate_reason
                ? 'Tương tác đã bị tắt cho bài viết này'
                : 'Chủ bài viết đã giới hạn những người có thể bình luận về bài viết này.'}
            </span>
          </div>
        ) : (
          <CommentBox
            ref={ref}
            focus={focus}
            setFocus={setFocus}
            gifSelected={gifSelected}
            setGifSelected={setGifSelected}
            stickySelected={stickySelected}
            setStickySelected={setStickySelected}
            post={post}
            onSubmitForm={e => {
              e['in_reply_to_id'] = post.id;
              createComment(e, 'create', functionUpdate);
            }}
            sendTypingEvent={sendTypingEvent}
            accept={'image/jpeg,image/png,video/mp4,video/x-m4v'}
            avatarUrl={meInfo?.avatar_media?.url ?? ''}
            type={type}
            chooseFocus={chooseFocus}
            setChooseFocus={setChooseFocus}
            typeComment={typeComment}
          />
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {typeComment !== 'liveChat' ? (
            <VisibilityComponent
              visibility={visibility}
              setVisibility={setVisibility}
              listVisibility={listVisibility}
              setListComment={setListComment}
            />
          ) : null}
        </div>
        {openPopper ? (
          <ClickAwayListener
            onClickAway={() => {
              setOpenPopper(false);
              setAnchorEl(null);
            }}
          >
            <Popper
              open={openPopper}
              anchorEl={anchorEl}
              placement="bottom-start"
              disablePortal={true}
              style={{
                borderRadius: '8px',
                zIndex: 1001,
                marginTop: '15px !important',
                padding: '0px'
              }}
            >
              <Paper>
                <Box className={classes.rootPopper}>
                  <Box className={classes.header}></Box>
                  <Box className={classes.boxImage}>
                    <img
                      className={classes.imageLogo}
                      src={logoEmso}
                      alt="logoEmso"
                    />
                  </Box>
                  <Box sx={{ padding: '0px 15px 20px' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                      Tiêu chuẩn cộng đồng của chúng tôi
                    </Typography>
                    <Typography sx={{ padding: '16px 0px', opacity: '0.7' }}>
                      Những chính sách này được xây dựng dựa trên ý kiến đóng
                      góp của người dùng Emso và lời khuyên của chuyên gia.
                      Trong đó bao gồm nhiều quan điểm, niềm tin khác nhau và
                      đặc biệt nhằm mục đích bảo vệ những ai có thể bị xem nhẹ
                      hoặc yếu thế.
                    </Typography>
                    <Divider />
                    <Typography className={classes.textBold}>
                      Bắt nạt và quấy rối
                    </Typography>
                    <Typography className={classes.subText}>
                      Giữ tinh thần tôn trọng và thân thiện bằng cách tránh làm
                      những việc như đe dọa hoặc bắt nạt nhau.
                    </Typography>
                    <Typography className={classes.textBold}>
                      Ngôn từ gây thù ghét
                    </Typography>
                    <Typography className={classes.subText}>
                      Mọi người nên được bảo vệ, đặc biệt là trước sự tấn công
                      về chủng tộc, dân tộc, tôn giáo, ngôn ngữ, giới tính hoặc
                      thiên hướng tính dục.
                    </Typography>
                    <Box sx={{ marginTop: '12px' }}>
                      <ButtonInherit
                        label="Tôi hiểu"
                        style={{
                          width: '100%',
                          backgroundColor: '#7165E0',
                          color: '#fff'
                        }}
                        action={() => {
                          setOpenPopper(false);
                          setAnchorEl(null);
                        }}
                      />
                      <ButtonInherit
                        label="Xem tất cả tiêu chuẩn cộng đồng"
                        style={{ width: '100%', marginTop: '10px' }}
                        action={() =>
                          window.open(
                            '/terms_item/standard_community?helpref=hc_enav',
                            '_blank'
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Popper>
          </ClickAwayListener>
        ) : null}
      </Box>
    );
  })
);

export default PostActionComment;
