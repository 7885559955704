import { Link } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonCustom from 'src/components/Button/Button';
import { buttonColor } from 'src/constants/styles';

type SocialEmptyLayoutProps = {
  icon?: string;
  primaryText: string;
  secondaryText?: string;
  styles?: any;
};

function SocialEmptyLayout({
  icon,
  primaryText,
  secondaryText,
  styles
}: SocialEmptyLayoutProps) {
  const history = useHistory();
  const theme: any = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '95vh',
        position: 'absolute',
        width: '100%',
        left: '50px',
        top: 0,
        zIndex: 1001,
        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
        ...styles
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '500px',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <i
          className={icon}
          style={{
            fontSize: 80,
            color: theme.palette.mode === 'dark' ? '#fff' : '#6c6c6c',
            margin: '12px'
          }}
        ></i>
        <span
          style={{
            color: theme.palette.mode === 'dark' ? '#fff' : '#6c6c6c',
            fontSize: 22,
            fontWeight: 600
          }}
        >
          {primaryText}
        </span>
        <span
          style={{
            color: '#6c6c6c',
            fontSize: 18,
            textAlign: 'center',
            margin: '8px 0px 12px 0px'
          }}
        >
          {secondaryText}
        </span>
        <ButtonCustom
          label={'Đi tới bảng tin'}
          style={{
            backgroundColor: buttonColor.backgroundColor,
            color: '#e7f3ff',
            width: '180px',
            margin: '0px 0px 6px 0px'
          }}
          action={() => history.push('')}
        />
        <Link
          underline="hover"
          style={{
            fontWeight: '600',
            margin: '0px 0px 6px 0px',
            fontSize: 16,
            cursor: 'pointer'
          }}
          onClick={() => history.goBack()}
        >
          Quay lại
        </Link>
        <Link
          underline="hover"
          style={{
            fontWeight: '600',
            margin: '0px 0px 6px 0px',
            fontSize: 16,
            cursor: 'pointer'
          }}
          onClick={() => history.push('/help')}
        >
          Truy cập trung tâm trợ giúp
        </Link>
      </div>
    </div>
  );
}

export default SocialEmptyLayout;
