import { Paper } from '@material-ui/core';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  Box,
  Fade,
  Link,
  Popper,
  Theme,
  Tooltip,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PopupState, { bindHover, bindPopper } from 'material-ui-popup-state';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  deleteMemberGroupApi,
  getGroupAccountApi,
  getGroupDetailApi
} from 'src/apis/socialGroup';
import { getDetailPageApi, likeFollowPage } from 'src/apis/socialPages.api';
import {
  addObjectBlock,
  blockPages,
  cancelRequestFriendApi,
  followUser,
  getAboutUser,
  getInforUser,
  requestFriendApi,
  unFollowUser,
  unFriendUser,
  approveRequestFriendApi
} from 'src/apis/socialUser';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import DialogConfirm from 'src/components/Dialog/DialogConfirmDelete';
import DialogReport from 'src/components/Dialog/DialogReport';
import DialogShopReport from 'src/components/Dialog/DialogShopReport';
import MenuAction from 'src/components/Menu';
import DialogConfirmation from 'src/components/Dialog/DialogConfirmDelete';
import { buttonColor } from 'src/constants/styles';
import {
  handleChatNow,
  handleCreateChatPage
} from 'src/pages/Chat/functionChat';
import { saveUserInfo } from 'src/store/action/saveUser';
import { getPageDetailReq } from 'src/store/action/socialPageAction';
import { getInforlUserReq } from 'src/store/action/socialUserAction';
import IconButtonOptions from '../Button/IconButtonOption';
import CollapseText from '../CollapseText/CollapseText';
import DialogInvite from '../Dialog/DialogInvite';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { groupRelationShipReq } from 'src/store/action/socialGroupAction';
import {
  updateStatusFriend,
  getFriendOneSuccess
} from 'src/store/action/socialFriendsAction';
import DialogEditProfile from 'src/components/Dialog/DialogEditProfile/DialogEditProfile';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';
const useStyles = makeStyles((theme: Theme) => ({
  wrapContent: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  paper: {
    width: 400,
    height: 'auto',
    padding: '12px 16px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2) !important',
    border: '1px solid rgba(0,0,0,0.05)',
    borderBottom: '0px !important',
    borderRadius: '10px !important'
  },
  paperDark: {
    width: 400,
    height: 'auto',
    padding: '12px 16px',
    boxShadow: '0 1px 2px rgba(236, 236, 236, 0.2) !important',
    border: '1px solid #555555',
    borderBottom: '0px !important',
    borderRadius: '10px !important'
  },
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-all',
    overflow: 'hidden'
  }
}));
const iconStyle = { fontSize: '20px' };

interface Props {
  object?: any;
  children?: any;
  type?: String;
  typePopup?: String;
  listData?: any;
  setListData?: React.Dispatch<React.SetStateAction<any>> | any;
  onClick?: any;
  isInvitePage?: boolean;
  hideMessageButton?: boolean;
  setPostData?: any;
}

export default function BasicPopover(props: Props) {
  const {
    object,
    children,
    type,
    typePopup,
    listData,
    setListData,
    onClick,
    isInvitePage,
    hideMessageButton,
    setPostData
  } = props;
  const [objectInfor, setObjectInfor] = React.useState<any>({});
  const [aboutUser, setAboutUser] = React.useState<any>({});
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [loadingConfirm, setLoadingConfirm] = React.useState(false);
  const [delayHandler, setDelayHandler] = React.useState<any>(null);
  const [listAvatar, setListAvatar] = React.useState<any>([]);
  const [anchorElMenu, setAnchorElMenu] =
    React.useState<HTMLButtonElement | null>(null);
  const [openPopperMenu, setOpenPopperMenu] = React.useState(false);
  const [openDialogReport, setOpenDialogReport] = React.useState<any>(false);
  const [openShopReportDialog, setOpenShopReportDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [errorApi, setErrorApi] = React.useState('');
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const pageInfo = useSelector((state: any) => state?.pageReducer.info) || {};
  const rocketId = meInfo.id || '';
  const dataReport = useSelector((state: any) => state.socialReportReducer);
  const { listReport: ReportInfo, listReportShop } = dataReport;

  const rolePreviewEdit: RolePreviewEdit = useSelector(
    (state: any) => state?.pageReducer.rolePreviewEdit
  );
  const buttonSocial = useSelector(
    (state: any) => state.userReducer.listSocial
  );
  const listPopupChat = useSelector(
    (state: any) => state.socialChatReducer.listPopupChat
  );
  const listFriendUpdate =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];

  const theme: any = useTheme();
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [openDialogInvite, setOpenDialogInvite] = React.useState(false);
  const [openDialogOutGroup, setOpenDialogOutGroup] =
    React.useState<boolean>(false);
  const [loadListMenu, setLoadListMenu] = React.useState<any>(null);
  const [openDialogEditProfile, setOpenDialogEditProfile] =
    React.useState(false);
  React.useEffect(() => {
    let index = listFriendUpdate.findIndex(
      (el: any) => el.id === objectInfor?.id
    );
    if (index !== -1) {
      setObjectInfor(prev => {
        return {
          ...prev,
          relationships: {
            ...prev.relationships,
            ...listFriendUpdate[index]?.relationships
          }
        };
      });
    }
  }, [listFriendUpdate]);

  // render list avatar members of group
  const getMemberGroup = async (id, params) => {
    let response;
    response = await getGroupAccountApi(id, params);
    if (response.status === 200) {
      return response.data;
    }
  };

  const getMembersGroup = async () => {
    let paramsFrd = {
      role: type
    } as any;
    let paramsMem = {
      role: type
    } as any;
    paramsFrd.role = 'member';
    paramsFrd.include_friend = true;
    const responseFriend = await getMemberGroup(object.id, {
      ...paramsFrd
    });
    paramsMem.role = 'member';
    paramsMem.exclude_friend = true;
    const responseMember = await getMemberGroup(object?.id, {
      ...paramsMem
    });
    if (responseFriend && responseMember) {
      setListAvatar([...responseFriend, ...responseMember]);
    }
  };

  let listMenu = [
    {
      key: 'unFollow',
      icon: 'fa-light fa-square-xmark',
      label: objectInfor.relationships?.following ? 'Bỏ theo dõi' : 'Theo dõi',
      action: () => {
        handleAction(
          objectInfor.relationships?.following ? 'unFollow' : 'follow',
          object
        );
        handleCloseMenu();
      }
    },
    {
      key: 'unFriend',
      icon: 'fa-light fa-user-xmark',
      label: 'Hủy kết bạn',
      action: () => {
        setOpenConfirm(true);
        handleCloseMenu();
      }
    }
  ];

  let listMenuApprove = [
    {
      key: 'approve',
      label: 'Xác nhận',
      action: () => {
        handleAction('approve', object);
        setLoadingAction(true);
        handleCloseMenu();
      }
    },
    {
      key: 'removeRequest',
      label: 'xóa lời mời',
      action: () => {
        handleAction('removeRequest', object);
        setLoadingAction(true);
        handleCloseMenu();
      }
    }
  ];
  const handlePostUserBlock = async () => {
    let tempParams = {
      account_id: aboutUser.id
    };
    let response = await addObjectBlock('blocks', tempParams);
    if (response.status === 200) {
      history.push('/settings/block');
    }
  };

  const handleBlockPage = async (id: any) => {
    let response = await blockPages({ page_id: id });
    if (response.status === 200) {
      history.push('/settings/block');
    }
  };
  const listUserOptions = [
    [
      // {
      //   id: 'audio-call',
      //   label: 'Gọi thoại',
      //   icon: 'fa-light fa-phone',
      //   styleIcon: iconStyle,
      //   action: () => {}
      // },
      // {
      //   id: 'video-call',
      //   label: 'Gọi video',
      //   icon: 'fa-light fa-video',
      //   styleIcon: iconStyle,
      //   action: () => {}
      // },
      {
        id: 'help-report',
        label: 'Tìm hỗ trợ hoặc báo cáo',
        icon: 'fa-light fa-square-info',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogReport(true);
        }
      },
      {
        id: 'block',
        label: 'Chặn',
        icon: 'fa-light fa-ban',
        styleIcon: iconStyle,
        action: () => {
          handlePostUserBlock();
        }
      }
    ]
  ];


  const listUserAdminOptions = [
    [
      {
        id: 'invite-friend',
        label: 'Mời bạn bè',
        icon: 'fa-light fa-calendar-plus',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogInvite(true);
        }
      },
      {
        id:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'unfollows'
            : 'follows',
        label:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'Đã theo dõi'
            : 'Theo dõi',
        icon:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'fa-light fa-calendar-check'
            : 'fa-light fa-calendar-plus',
        styleIcon: iconStyle,
        action: () => {
          handleLikeFollowPage(
            pageInfo?.page_relationship?.following ||
              objectInfor?.page_relationship?.following
              ? 'unfollows'
              : 'follows',
            'follow'
          );
        }
      }
    ]
  ];

  const listPageOptions = [
    [
      {
        id: 'help-report',
        label: 'Tìm hỗ trợ hoặc báo cáo',
        icon: 'fa-light fa-triangle-exclamation',
        styleIcon: iconStyle,
        action: () => {
          objectInfor.page_purpose === 'sell_products'
            ? setOpenShopReportDialog(true)
            : setOpenDialogReport(true);
        }
      },
      {
        id: 'block',
        label: 'Chặn',
        icon: 'fa-light fa-ban',
        styleIcon: iconStyle,
        action: () => {
          handleBlockPage(objectInfor?.id);
        }
      },
      {
        id: 'invite-friend',
        label: 'Mời bạn bè',
        icon: 'fa-light fa-calendar-plus',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogInvite(true);
        }
      },
      {
        id:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'unfollows'
            : 'follows',
        label:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'Đã theo dõi'
            : 'Theo dõi',
        icon:
          pageInfo?.page_relationship?.following ||
          objectInfor?.page_relationship?.following
            ? 'fa-light fa-calendar-check'
            : 'fa-light fa-calendar-plus',
        styleIcon: iconStyle,
        action: () => {
          handleLikeFollowPage(
            pageInfo?.page_relationship?.following ||
              objectInfor?.page_relationship?.following
              ? 'unfollows'
              : 'follows',
            'follow'
          );
        }
      }
    ]
  ];

  const listGroupOptions = [
    [
      {
        id: 'help-report',
        label: 'Tìm hỗ trợ hoặc báo cáo',
        icon: 'fa-light fa-square-info',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogReport(true);
        }
      },
      {
        id: 'leave_group',
        label: 'Rời nhóm',
        icon: 'fa-solid fa-arrow-right-from-bracket',
        styleIcon: iconStyle,
        action: () => {
          setOpenDialogOutGroup(true);
        },
        hidden:
          //Ở đây chỉ dành cho member rời nhóm
          !(
            objectInfor?.group_relationship?.member &&
            !objectInfor?.group_relationship?.admin &&
            !objectInfor?.group_relationship?.moderator
          )
      }
    ]
  ];

  const renderSubText = () => {
    return `Bạn có muốn rời khỏi ${objectInfor?.title} không ?
      Bạn sẽ không thể tương tác với nhóm này nữa.`;
  };

  const handleOutGroup = async () => {
    try {
      let response = await deleteMemberGroupApi(objectInfor?.id);
      if (response.status === 200) {
        dispatch(groupRelationShipReq([response.data]));
        setOpenDialogOutGroup(false);
      }
    } catch (err: any) {
      setOpenSnackbar(true);
      setNoti({
        code: err.response.status,
        message: 'Có lỗi xảy ra trong quá trình gửi.Vui lòng thử lại sau!'
      });
    }
  };

  const messButton = () => {
    if (hideMessageButton) return;
    return (
      <ButtonInherit
        style={{ width: '145px' }}
        label="Nhắn tin"
        icon={<i className="fa-brands fa-facebook-messenger"></i>}
        action={() => {
          if (object?.page_relationship || isInvitePage)
            handleCreateChatPage(object, dispatch);
          else {
            const existsPopup = listPopupChat.find(el =>
              el.conversation.rid.includes(object.id)
            );
            if (!existsPopup) {
              handleChatNow(object, rocketId, dispatch);
            }
          }
        }}
      />
    );
  };

  const handleClick = (event: any) => {
    setAnchorElMenu(event.currentTarget);
    setOpenPopperMenu(true);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const handleAction = async (type, object) => {
    try {
      let response;
      if (type === 'removeRequest') {
        response = await cancelRequestFriendApi(objectInfor?.id);
      } else if (type === 'addFriend') {
        response = await requestFriendApi(objectInfor?.id);
      } else if (type === 'unFriend') {
        response = await unFriendUser(objectInfor?.id);
      } else if (type === 'unFollow') {
        response = await unFollowUser(objectInfor?.id);
      } else if (type === 'follow') {
        response = await followUser(objectInfor?.id);
      } else if (type === 'approve') {
        response = await approveRequestFriendApi(objectInfor?.id);
      }

      if (response.status === 200) {
        if (listData) {
          let index = listData.findIndex((el: any) => el.id === object?.id);
          setListData(prev => [
            ...prev.slice(0, index),
            {
              ...prev[index],
              relationships: response.data
            },
            ...prev.slice(index + 1)
          ]);
        }

        const user = {
          ...objectInfor,
          relationships: {
            ...objectInfor?.relationships,
            ...response.data
          }
        };

        let index = listFriendUpdate.findIndex(
          (el: any) => el.id === object?.id
        );
        if (index !== -1) {
          dispatch(
            updateStatusFriend(
              objectInfor.username,
              response.data.friendship_status
            )
          );
        } else {
          dispatch(getFriendOneSuccess(user));
        }

        if (type === 'unFollow' || type === 'follow') {
          setOpenSnackbar(true);
          setNoti({
            code: 200,
            message: (() => {
              if (type === 'unFollow') return 'Đã bỏ theo dõi';
              if (type === 'follow') return 'Đã theo dõi';
            })()
          });
        }
        if (type === 'approve') {
          setNoti({
            code: 200,
            message: 'Đã chấp nhận lời mời kết bạn'
          });
        }

        setLoadingAction(false);
        setObjectInfor(prev => {
          return {
            ...prev,
            relationships: {
              ...prev.relationships,
              ...response.data
            }
          };
        });
        setOpenConfirm(false);
        setLoadingConfirm(false);
      }
    } catch (e) {}
  };

  const handleLikeFollowPage = async (action: any, type) => {
    try {
      let response: any = await likeFollowPage(objectInfor?.id, action);
      if (response?.status === 200) {
        dispatch(getPageDetailReq(objectInfor?.id));
        if (type === 'like') {
          setObjectInfor(prev => ({
            ...prev,
            page_relationship: {
              ...prev.page_relationship,
              like: action === 'likes' ? true : false
            }
          }));
        } else {
          setObjectInfor(prev => ({
            ...prev,
            page_relationship: {
              ...prev.page_relationship,
              following: action === 'unfollows' ? false : true
            }
          }));
          setPostData &&
            setPostData(prev => ({
              ...prev,
              page: {
                ...prev?.page,
                page_relationship: {
                  ...prev?.page?.page_relationship,
                  following: action === 'follows'
                }
              }
            }));
        }
      }
    } catch (error) {}
  };

  const renderButton = object => {
    //Button User
    if (objectInfor?.relationships?.friendship_status === 'ARE_FRIENDS') {
      return (
        <div className={classes.button}>
          <div style={{ display: 'flex' }} onClick={handleClick}>
            <ButtonInherit
              label={
                <Typography style={{ fontWeight: 500 }}>Bạn bè</Typography>
              }
              action={() => {
                setLoadListMenu(objectInfor?.relationships?.friendship_status);
              }}
              style={{
                padding: '0px 8px',
                flex: 'auto!important',
                height: '36px',
                width: '145px'
              }}
              icon={
                <i
                  style={{ fontSize: 15 }}
                  className="fa-solid fa-user-check"
                ></i>
              }
            />

            <div style={{ width: 16 }}></div>
          </div>
          {messButton()}
        </div>
      );
    } else if (
      objectInfor?.relationships?.friendship_status === 'OUTGOING_REQUEST'
    ) {
      return (
        <div className={classes.button}>
          <ButtonInherit
            label="Hủy lời mời"
            icon={
              <i
                style={{ fontSize: 15 }}
                className="fa-solid fa-user-xmark"
              ></i>
            }
            color={buttonColor.backgroundColor}
            action={() => {
              handleAction('removeRequest', object);
              handleAction('unFollow', object);
              setLoadingAction(true);
            }}
            loading={loadingAction}
            style={{ width: '145px' }}
          />
          <div style={{ width: 7 }}></div>
          {messButton()}
        </div>
      );
    } else if (
      objectInfor?.relationships?.friendship_status === 'CAN_REQUEST'
    ) {
      return (
        <div className={classes.button}>
          <ButtonInherit
            label="Thêm bạn bè"
            icon={
              <i style={{ fontSize: 15 }} className="fa-solid fa-user-plus"></i>
            }
            color={buttonColor.backgroundColor}
            action={() => {
              handleAction('addFriend', object);
              setLoadingAction(true);
            }}
            loading={loadingAction}
            style={{
              width: '145px',
              color: '#e7f3ff',
              backgroundColor: buttonColor.backgroundColor
            }}
          />
          <div style={{ width: 7 }}></div>
          {messButton()}
        </div>
      );
    } else if (
      objectInfor?.relationships?.friendship_status === 'INCOMING_REQUEST'
    ) {
      return (
        <div className={classes.button}>
          <div style={{ display: 'flex' }} onClick={handleClick}>
            <ButtonInherit
              label="Phản hồi"
              icon={
                <i
                  style={{ fontSize: 15 }}
                  className="fa-solid fa-user-check"
                ></i>
              }
              color={buttonColor.backgroundColor}
              action={() => {
                setLoadListMenu(objectInfor?.relationships?.friendship_status);
              }}
              loading={loadingAction}
              style={{
                width: '145px',
                color: '#e7f3ff',
                backgroundColor: buttonColor.backgroundColor
              }}
            />

            <div style={{ width: 7 }}></div>
          </div>
          {messButton()}
        </div>
      );
    } else if (object?.id === meInfo.id) {
      return (
        <ButtonInherit
          style={{ width: 'auto', flex: 'auto' }}
          label="Chỉnh sửa trang cá nhân"
          action={() =>
            // history.push(`/user/${meInfo.username ?? meInfo.id}`)
            setOpenDialogEditProfile(true)
          }
          icon={<i className="fa fa-pen"></i>}
        />
      );
    }

    //Button Page
    else if (objectInfor?.page_relationship) {
      if (
        rolePreviewEdit?.role === 'admin' ||
        rolePreviewEdit?.role === 'owner' ||
        rolePreviewEdit?.role === 'moderator'
      ) {
        return (
          <div style={{ display: 'flex', width: '100%' }}>
            <ButtonCustom
              label={'Quảng cáo'}
              icon={
                <i
                  style={{
                    color: '#fff'
                  }}
                  className="fa fa-bullhorn"
                ></i>
              }
              style={{
                backgroundColor: buttonColor.backgroundColor,
                color: '#fff'
              }}
              action={() => {}}
            />
          </div>
        );
      } else {
        return (
          <div className={classes.button}>
            <ButtonCustom
              label={
                objectInfor?.page_relationship?.like ? 'Đã thích' : 'Thích'
              }
              icon={
                <i
                  style={{
                    color: !objectInfor?.page_relationship?.like
                      ? buttonColor.backgroundColor
                      : theme.palette.mode === 'dark'
                      ? '#ffffff'
                      : '#050505'
                  }}
                  className="fa-solid fa-thumbs-up"
                ></i>
              }
              style={{
                color: !objectInfor?.page_relationship?.like
                  ? buttonColor.backgroundColor
                  : theme.palette.mode === 'dark'
                  ? '#ffffff'
                  : '#050505',
                width: hideMessageButton ? '300px' : '130px'
              }}
              action={() => {
                handleLikeFollowPage(
                  objectInfor?.page_relationship?.like ? 'unlikes' : 'likes',
                  'like'
                );
              }}
            />
            <div style={{ width: 16 }}></div>
            {messButton()}
          </div>
        );
      }
    }

    //Button Group
    else if (
      !object?.display_name &&
      !object?.page_categories &&
      typePopup !== 'payment'
    ) {
      return (
        <ButtonCustom
          label={'Truy cập vào nhóm'}
          style={{
            backgroundColor: '#e7f3ff',
            color: buttonColor.backgroundColor,
            width: '100%',
            fontWeight: '600'
          }}
          action={() => {
            history.push(`/group/${object.username ?? object.id}`);
          }}
        />
      );
    }
    // Button Course/Grow
    else if (typePopup === 'payment') {
      return (
        <ButtonCustom
          label={
            type === 'course' ? 'Truy cập vào khóa học' : 'Truy cập vào dự án'
          }
          style={{
            backgroundColor: '#e7f3ff',
            color: buttonColor.backgroundColor,
            width: '100%',
            fontWeight: '600'
          }}
          action={() => {
            type === 'course'
              ? history.push(`/course/${object.id}/about`)
              : history.push(`/grow/${object.id}/about`);
          }}
        />
      );
    }
  };

  const fetchDataObject = async type => {
    try {
      let response;
      const getApi = () => {
        if (object.display_name) {
          return getInforUser(object.id, null);
        } else if (object.group_relationship) {
          return getGroupDetailApi(object.id);
        } else {
          return getDetailPageApi(object.id);
        }
      };
      response = await getApi();

      if (response.status === 200) {
        if (object.display_name && type === 'action') {
          dispatch(getInforlUserReq(response.data));
          dispatch(saveUserInfo(response.data));
        }
        setObjectInfor(response.data);
        setLoadingAction(false);
      }
    } catch (e) {
      setErrorApi('Không tìm được kết quả');
    }
  };

  const fetchAboutUser = async () => {
    let response;
    if (object.display_name) {
      response = await getAboutUser(object.id, null);
    }

    if (response.status === 200) {
      setAboutUser(response.data);
    }
  };

  const handleMouseEnter = event => {
    setDelayHandler(
      setTimeout(() => {
        setOpenPopper(true);
      }, 500)
    );
  };

  const handleMouseLeave = () => {
    delayHandler && clearTimeout(delayHandler);
    setOpenPopper(false);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <PopupState variant="popper" popupId="demoPopper">
        {popupState => {
          if (
            popupState.anchorEl &&
            object?.id &&
            popupState.isOpen &&
            !Object.keys(objectInfor).length &&
            !errorApi
          ) {
            fetchDataObject('get');
            if (object?.display_name) {
              fetchAboutUser();
              //get member of group
            } else if (!object?.display_name && !object?.page_relationship) {
              getMembersGroup();
            }
          }
          return (
            <div
              aria-owns="mouse-over-popper"
              aria-haspopup="true"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {children(popupState, bindHover)}
              <Popper
                {...bindPopper(popupState)}
                open={openPopper}
                placement="bottom"
                disablePortal
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                transition
                style={{ zIndex: 1300 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={200}>
                    <Paper
                      className={
                        theme.palette.mode === 'dark'
                          ? classes.paperDark
                          : classes.paper
                      }
                      style={{
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#242526' : '#fff'
                      }}
                    >
                      <Box className={classes.wrapContent}>
                        <Link
                          style={{ textDecoration: 'none', cursor: 'pointer' }}
                          onClick={onClick}
                        >
                          {objectInfor?.display_name ||
                          objectInfor?.page_categories ? (
                            <AvatarSocial
                              type="feed"
                              style={{ width: 96, height: 96, marginRight: 12 }}
                              avatarObj={
                                object?.avatar_media ??
                                objectInfor?.avatar_media ??
                                meInfo?.avatar_static
                              }
                            />
                          ) : (
                            <AvatarSocial
                              type="feed"
                              style={{ width: 96, height: 96, marginRight: 12 }}
                              avatarObj={object?.banner}
                              isBanner
                            />
                          )}
                        </Link>
                        <div style={{ width: '68%' }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginBottom: '15px',
                              gap: '5px'
                            }}
                          >
                            <Link
                              style={{
                                textDecoration: 'none',
                                cursor: 'pointer',
                                color:
                                  theme.palette.mode === 'dark'
                                    ? '#e4e6eb'
                                    : '#050505'
                              }}
                              onClick={onClick}
                            >
                              <Typography
                                className={classes.lineClamp}
                                style={{
                                  fontSize: 20,
                                  fontWeight: 700
                                }}
                              >
                                {object?.display_name ?? object?.title}
                              </Typography>
                            </Link>
                            {(object?.display_name && object.certified) ||
                            (objectInfor?.display_name &&
                              objectInfor.certified) ? (
                              <Tooltip
                                placement="top-start"
                                title="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                                arrow
                                sx={{
                                  '& .MuiTooltip-tooltip': {
                                    fontSize: '14px',
                                    padding: '10px'
                                  }
                                }}
                              >
                                <VerifiedIcon
                                  // titleAccess="Tài khoản đã xác minh"
                                  fontSize="small"
                                  viewBox="0 -2 24 24"
                                  sx={{
                                    color: buttonColor.backgroundColor,
                                    fontSize: '14px',
                                    '&:hover': {
                                      color: buttonColor.backgroundColor
                                    }
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                          </Box>

                          <div>
                            <Typography
                              style={{
                                marginBottom: 15
                              }}
                            >
                              {!objectInfor.display_name &&
                              !objectInfor.page_categories &&
                              typePopup !== 'payment' ? (
                                <Typography sx={{ color: 'text.primary' }}>
                                  <i
                                    className={
                                      objectInfor.is_private
                                        ? 'fas fa-lock fa-lg'
                                        : 'fa-solid fa-globe-asia fa-lg'
                                    }
                                  ></i>
                                  &ensp;&nbsp;Nhóm{' '}
                                  {objectInfor.is_private
                                    ? 'Riêng tư'
                                    : 'Công khai'}
                                </Typography>
                              ) : typePopup === 'payment' ? (
                                <Typography sx={{ color: 'text.primary' }}>
                                  <i
                                    className={
                                      object.visibility === 'private'
                                        ? 'fas fa-lock fa-lg'
                                        : 'fa-solid fa-globe-asia fa-lg'
                                    }
                                  ></i>
                                  &ensp;&nbsp;
                                  {type === 'course'
                                    ? 'Khóa học'
                                    : 'Dự án'}{' '}
                                  {object.visibility === 'private'
                                    ? 'Riêng tư'
                                    : 'Công khai'}
                                </Typography>
                              ) : object?.display_name ? (
                                object.relationships?.mutual_friend_count ||
                                objectInfor.relationships
                                  ?.mutual_friend_count ? (
                                  <Typography sx={{ color: 'text.primary' }}>
                                    <i className="fa-solid fa-user-group"></i>
                                    &ensp;&nbsp;
                                    {object.relationships
                                      ?.mutual_friend_count ||
                                      objectInfor.relationships
                                        ?.mutual_friend_count}{' '}
                                    bạn chung
                                  </Typography>
                                ) : (
                                  <>
                                    {aboutUser?.general_information?.hometown
                                      ?.name && (
                                      <Typography
                                        sx={{ color: 'text.primary' }}
                                      >
                                        <i className="fa-solid fa-location-dot"></i>
                                        &ensp;&nbsp; Đến từ{' '}
                                        <span style={{ fontWeight: '500' }}>
                                          {
                                            aboutUser?.general_information
                                              ?.hometown?.name
                                          }
                                        </span>
                                      </Typography>
                                    )}
                                  </>
                                )
                              ) : (
                                (
                                  <Typography sx={{ color: 'text.primary' }}>
                                    <i className="fa-solid fa-user-group"></i>
                                    &ensp;&nbsp;
                                    {object?.like_count} người thích trang
                                  </Typography>
                                ) ?? (
                                  <Typography sx={{ color: 'text.primary' }}>
                                    {object?.page_categories?.map(
                                      (el: any, index) => (
                                        <Typography
                                          sx={{ color: 'text.primary' }}
                                          key={index}
                                        >
                                          {el.text}
                                        </Typography>
                                      )
                                    )}
                                  </Typography>
                                )
                              )}
                            </Typography>
                            <Typography
                              style={{
                                marginBottom: 15
                              }}
                            >
                              {!objectInfor.display_name &&
                              !objectInfor.page_categories &&
                              typePopup !== 'payment' ? (
                                <Typography sx={{ color: 'text.primary' }}>
                                  <i className="fa-solid fa-user-group"></i>
                                  &ensp;&nbsp;
                                  {objectInfor.member_count ?? 0} thành viên
                                  <Typography
                                    style={{
                                      marginLeft: '38px',
                                      marginTop: '15px',
                                      marginBottom: '40px'
                                    }}
                                    sx={{ color: 'text.primary' }}
                                  >
                                    {listAvatar
                                      ?.filter(
                                        item => item?.account?.id !== meInfo.id
                                      )
                                      .slice(0, 8)
                                      .map((el: any, index) => (
                                        <div
                                          key={el?.account?.id}
                                          style={{
                                            display: 'inline-block',
                                            position: 'relative'
                                          }}
                                          onClick={() => {
                                            history.push(
                                              `/user/${
                                                el?.account.username ??
                                                el?.account?.id
                                              }`
                                            );
                                          }}
                                        >
                                          <AvatarSocial
                                            type="feed"
                                            avatarObj={
                                              el?.account?.avatar_media ??
                                              meInfo?.avatar_static
                                            }
                                            style={{
                                              height: 24,
                                              width: 24,
                                              marginLeft: -6,
                                              display: 'inline-block'
                                            }}
                                            object={el?.account}
                                          />
                                        </div>
                                      ))}
                                  </Typography>
                                </Typography>
                              ) : typePopup === 'payment' ? (
                                <Typography sx={{ color: 'text.primary' }}>
                                  <i className="fa-solid fa-user-group"></i>
                                  &ensp;&nbsp;
                                  {type === 'course'
                                    ? `${
                                        object?.participants_count
                                          ? object?.participants_count
                                          : 0
                                      } thành viên tham gia khóa học`
                                    : `${
                                        object?.backers_count
                                          ? object?.backers_count
                                          : 0
                                      } thành viên ủng hộ hoặc đầu tư dự án`}
                                </Typography>
                              ) : objectInfor?.display_name ? (
                                aboutUser?.general_information?.place_live ||
                                aboutUser?.general_information?.address ? (
                                  //bỏ quê quán: aboutUser?.general_information?.address
                                  <Typography sx={{ color: 'text.primary' }}>
                                    <i className="fa-solid fa-house-chimney"></i>
                                    &ensp;&nbsp;Sống tại{' '}
                                    <strong>
                                      {aboutUser?.general_information
                                        ?.place_live?.title?.length > 50
                                        ? `${(aboutUser?.general_information?.place_live?.title).substring(
                                            0,
                                            20
                                          )}...`
                                        : aboutUser?.general_information
                                            ?.place_live?.title}
                                    </strong>
                                  </Typography>
                                ) : objectInfor?.followers_count ? (
                                  <Typography sx={{ color: 'text.primary' }}>
                                    <i
                                      className="fa-solid fa-wifi"
                                      style={{ transform: 'rotate(43deg)' }}
                                    ></i>
                                    &ensp;&nbsp;Có{' '}
                                    <span style={{ fontWeight: '500' }}>
                                      {objectInfor?.followers_count} người
                                    </span>{' '}
                                    theo dõi
                                  </Typography>
                                ) : (
                                  <Typography sx={{ color: 'text.primary' }}>
                                    <i className="fa-solid fa-clock"></i>
                                    &ensp;&nbsp;Tham gia vào{' '}
                                    <span style={{ fontWeight: '500' }}>
                                      {formatDateTimeToStringDateTime(
                                        objectInfor?.created_at,
                                        false
                                      )}
                                    </span>{' '}
                                  </Typography>
                                )
                              ) : (
                                <Box sx={{ display: 'flex' }}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                      paddingTop: '10px'
                                    }}
                                  >
                                    <i
                                      style={{
                                        color:
                                          theme.palette.mode === 'dark'
                                            ? '#fff'
                                            : '#000'
                                      }}
                                      className="fa-solid fa-quote-left fa-lg"
                                    ></i>
                                  </Box>
                                  <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ display: 'flex' }}>&ensp;</Box>
                                    <Box sx={{ display: 'flex' }}>&ensp;</Box>
                                    <Typography
                                      className={classes.lineClamp}
                                      sx={{
                                        color: 'text.primary'
                                      }}
                                    >
                                      <CollapseText
                                        text={
                                          objectInfor.description ??
                                          objectInfor.phone_number ??
                                          objectInfor.website ??
                                          objectInfor.email
                                        }
                                        count={120}
                                      ></CollapseText>
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </Box>

                      <Box mt="10px" className={classes.button}>
                        {renderButton(object)}

                        {object?.id !== meInfo.id && typePopup !== 'payment' && (
                          <IconButtonOptions
                            icon={
                              <i
                                style={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  marginLeft: '0px!important'
                                }}
                                className="fa-solid fa-ellipsis"
                              ></i>
                            }
                            openPopup={true}
                            options={
                              objectInfor?.page_relationship &&
                              objectInfor?.page_relationship?.role !== 'admin'
                                ? listPageOptions
                                : objectInfor?.page_relationship?.role ===
                                  'admin'
                                ? listUserAdminOptions
                                : objectInfor?.group_relationship
                                ? listGroupOptions
                                : listUserOptions
                            }
                            typePopup="popover"
                          />
                        )}
                      </Box>

                      {anchorElMenu && (
                        <MenuAction
                          anchorEl={anchorElMenu}
                          openPopper={openPopperMenu}
                          setOpenPopper={setOpenPopperMenu}
                          handleCloseMenu={handleCloseMenu}
                          listMenu={
                            loadListMenu === 'INCOMING_REQUEST'
                              ? listMenuApprove
                              : listMenu
                          }
                        />
                      )}

                      {openConfirm && (
                        <DialogConfirm
                          open={openConfirm}
                          handleClose={() => {
                            setOpenConfirm(false);
                            setLoadingConfirm(false);
                          }}
                          title={`Hủy kết bạn với ${object.display_name}`}
                          text={`Bạn có chắc chắn muốn xóa ${object.display_name} khỏi danh sách bạn bè không`}
                          labelButton="Xác nhận"
                          action={() => {
                            handleAction('unFriend', object);
                            setLoadingConfirm(true);
                          }}
                          loading={loadingConfirm}
                        />
                      )}
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>
          );
        }}
      </PopupState>
      {openDialogReport && (
        <DialogReport
          open={openDialogReport}
          handleClose={() => setOpenDialogReport(false)}
          title={'Báo cáo'}
          headerText={'Tìm hỗ trợ hoặc báo cáo tài khoản'}
          headerSubText={'Bạn có thể báo cáo tài khoản sau khi chọn vấn đề.'}
          listCategoriesReport={ReportInfo}
          accountReport={aboutUser}
          postReport={objectInfor}
          typeReport={
            objectInfor?.page_relationship
              ? 'page'
              : objectInfor?.group_relationship
              ? 'reportGroup'
              : 'account'
          }
          groupId={objectInfor?.group_relationship ? objectInfor.id : null}
        />
      )}

      {openShopReportDialog && (
        <DialogShopReport
          open={openShopReportDialog}
          handleClose={() => setOpenShopReportDialog(false)}
          title={'Báo cáo'}
          headerText={'Tìm hỗ trợ hoặc báo cáo shop'}
          headerSubText={'Hãy giúp chúng tôi hiểu chuyện gì đang xảy ra.'}
          listReasons={listReportShop}
          pageInfo={objectInfor}
          setOpenSnackbar={setOpenSnackbar}
          setNoti={setNoti}
        />
      )}
      {openDialogOutGroup && (
        <DialogConfirmation
          title={'Rời khỏi nhóm ?'}
          text={renderSubText()}
          open={openDialogOutGroup}
          handleClose={() => {
            setOpenDialogOutGroup(false);
          }}
          action={() => {
            handleOutGroup();
            history.push(`/group/${object.username ?? object.id}`);
          }}
          alignText={'center'}
          labelButton={'Rời khỏi nhóm'}
        />
      )}

      {openDialogInvite && (
        <DialogInvite
          open={openDialogInvite}
          setOpen={setOpenDialogInvite}
          pageInfo={objectInfor}
        />
      )}
      <SnackbarNotification
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
      {openDialogEditProfile && (
        <DialogEditProfile
          open={openDialogEditProfile}
          setOpen={setOpenDialogEditProfile}
          aboutUser={aboutUser}
          setAboutUser={setAboutUser}
          lifeEvents={''}
          buttonSocial={buttonSocial}
        />
      )}
    </div>
  );
}
