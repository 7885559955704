import { combineReducers } from 'redux';
import { loginReducer } from './login';
import { meReducer } from './meReducer';
import { settingReducer } from './settingReducer';
import { notiChatReducer } from 'src/containers/MessageIcon/reducer';
import { socialPostReducer } from './socialPostReducer';
import { socialSavedReducer } from './socialSavedReducer';
import { groupReducer } from './socialGroupReducer';
import { userReducer } from './socialUserReducer';
import { pageReducer } from './socialPageReducer';
import { eventReducer } from './socialEventReducer';
import { growReducer } from './socialGrowReducer';
import { recruitReducer } from './socialRecruitReducer';
import { courseReducer } from './socialCourseReducer';
import { productReducer } from './socialProductReducer';
import { watchReducer } from './socialWatchReducer';
import { searchGlobalReducer } from './socialSearchGlobalReducer';
import { socialChatReducer } from './socialChatReducer';
import { socialReportReducer } from './socialReport';
import { socialLiveStreamReducer } from './socialLivestreamReducer';
import { socialFriendsReducer } from './socialFriendsReducer';
import { musicReducer } from './socialMusicReducer';
import { socialAlbumReducer } from './socialAlbumReducer';
import { saveCacheReducer } from './saveCacheReducer';
import { socialSaveUserReducer } from './saveUserReducer';
import { paymentReducer } from './paymentReducer';
import { scrollReducer } from './scrollReducer';
import { headerReducer } from './headerReducer';

const rootReducer = combineReducers({
  loginReducer,
  meReducer,
  settingReducer,
  notiChatReducer,
  socialPostReducer,
  socialSavedReducer,
  groupReducer,
  userReducer,
  pageReducer,
  eventReducer,
  growReducer,
  recruitReducer,
  courseReducer,
  musicReducer,
  productReducer,
  watchReducer,
  searchGlobalReducer,
  socialChatReducer,
  socialReportReducer,
  socialFriendsReducer,
  socialLiveStreamReducer,
  socialAlbumReducer,
  saveCacheReducer,
  socialSaveUserReducer,
  paymentReducer,
  headerReducer,
  scrollReducer
});

export default rootReducer;
