import { Theme, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import DialogPreviewMedia from '../Dialog/DialogPreviewMedia';
import VideoJs from './VideoJs';
import ReactPlayerVideo from './ReactPlayerVideo';
import PostWatch from 'src/pages/Watch/WatchScreen/PostWatch';

interface Props {
  video?: any;
  videoPost?: any;
  action?: any;
  typePage?: String | any;
  style?: React.CSSProperties | any;
  type?: String | any;
  typeWatch?: String | any;
  typeLive?: String | any;
  typePost?: String | any;
  styleVideo?: React.CSSProperties | any;
  handleClose?: any;
  setPostData?: any;
  openPreview?: boolean | any;
  setOpenPreview?: React.Dispatch<React.SetStateAction<boolean>> | any;
  autoPlay?: boolean | any;
  closeDialogVideo?: boolean;
  openDialogVideo?: boolean;
  scrollToTop?: Function;
  muted?: boolean;
  offset?: number;
  checkOpenDialog?: boolean;
  inDialog?: boolean;
}

const WatchPlayer = (props: Props) => {
  const {
    video,
    videoPost,
    action,
    typePage,
    style,
    type,
    typeWatch,
    typeLive,
    styleVideo, //css for tag video
    handleClose,
    setPostData,
    openPreview,
    setOpenPreview,
    autoPlay = false,
    closeDialogVideo, // bắt sự kiện đóng dialog
    // openDialogVideo, // bắt sự kiện đóng dialog
    muted,
    offset,
    checkOpenDialog = true, // Kiểm tra bấm vào video có mở dialog hay không
    inDialog = false // Kiểm tra bấm vào video có nằm trong dialog hay không, dùng trong trường hợp share bài post
  } = props;
  const [isClickAction, setIsClickAction] = React.useState(false);
  const [idViewPort, setIdViewPort] = React.useState(null as any);
  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) => ({
    wrapPlayer: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      justifyContent: 'center',
      '&>.plyr': {
        width: '100%',
        ...style
      }
    },
    videoPlayer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      position: 'relative',
      justifyContent: 'center',
      cursor: 'pointer',
      '&>.plyr': {
        width: '100%',
        ...style
      }
    }
  }));
  const classes = useStyles();

  return (
    <>
      <div
        className={classes.wrapPlayer}
        style={{
          height:
            type === 'watch-screen'
              ? '100%'
              : typePage === 'preview_live'
              ? 'calc(100% - 110px)'
              : 'calc(100% - 45px)'
        }}
      >
        <div className={classes.videoPlayer} style={{ ...style }}>
          {video?.remote_url === 'pending' ? (
            <div
              style={{
                background: 'black',
                height: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '0px 20px'
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <i
                  className="fa-solid fa-circle-exclamation"
                  style={{
                    color: '#fff',
                    fontSize: '35px',
                    marginLeft: '10px'
                  }}
                ></i>
                <Typography
                  style={{
                    color: '#fff',
                    margin: '0px 10px'
                  }}
                >
                  Chúng tôi đang xử lý video này.
                </Typography>
              </Box>
            </div>
          ) : (
            <ReactPlayerVideo
              src={
                video?.remote_url || video?.url
                  ? video?.remote_url ?? video?.url
                  : videoPost?.media_attachments[0]?.remote_url ??
                    videoPost?.media_attachments[0]?.url
              }
              duration={
                video?.meta?.original?.duration ||
                videoPost?.media_attachments[0]?.meta?.original?.duration
              }
              previewMedia={
                video?.preview_url
                  ? video?.preview_url ?? video?.preview_remote_url
                  : videoPost?.media_attachments[0]?.preview_url
              }
              id={video?.id}
              postId={videoPost?.id}
              action={(typeAction = null) => {
                if (typeLive) {
                  history.push(
                    `/live/detail/${videoPost?.id}?host=${videoPost?.account?.username}&postMediaId=${videoPost?.id}&mediaId=${videoPost?.media_attachments[0]?.id}`
                  );
                  setOpenPreview(true);
                } else {
                  if (action) {
                    if (typeAction === 'scroll') action.scrollToTop();
                    else if (typeAction === 'navigate') action.navigate();
                    else action();
                  }
                }
              }}
              // idViewPort={idViewPort}
              // typePage={typePage}
              // isClickAction={isClickAction}
              type={typeWatch}
              // typeLive={typeLive}
              autoPlay={autoPlay}
              // styleVideo={styleVideo}
              // styleJs={style}
              // handleClose={handleClose}
              muted={muted}
              // openDialogVideo={openDialogVideo}
              checkOpenDialog={checkOpenDialog}
              closeDialogVideo={closeDialogVideo}
              inDialog={inDialog}
              offset={offset}
            />
          )}
        </div>
      </div>
      {openPreview && Object.keys(videoPost).length && (
        <DialogPreviewMedia
          open={openPreview}
          setOpen={setOpenPreview}
          post={videoPost}
          postMedia={videoPost}
          setPostMedia={setPostData}
          setMediaSelected={setPostData}
          typePreview="preview_live"
        />
      )}
    </>
  );
};

export default WatchPlayer;
