import * as types from 'src/constants/store/headerFixed';

const initialState = {
  width: '100%'
};

export const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESPONSIVE_HEADER: {
      let type = action.payload;
      return {
        ...state,
        width: type
      };
    }
    default:
      return state;
  }
};
