import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor, lineClamp } from 'src/constants/styles';
import IconButtonOptions from '../Button/IconButtonOption';
import BaseLink from '../Link/BaseLink';
import AvatarSocial from '../ProfileCardImage/AvatarSocial';
import userDefault from 'src/assets/images/user.jpg';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      avatar: {
        width: '56px !important',
        height: '56px !important',
        marginRight: '10px'
      },
      list: {
        width: '100%'
      },
      checked: { color: `${buttonColor.backgroundColor}!important` },
      selected: {
        backgroundColor: theme.palette?.button.primary.background
      },
      darkSelected: {
        backgroundColor: theme.palette?.button.primary.background
      },
      iconSelected: {
        backgroundColor: `${buttonColor.backgroundColor} !important`,
        color: '#fff',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '3px'
      },
      icon: {
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        marginRight: '3px'
      },
      rootAccordionChat: {
        paddingLeft: '0px !important'
      },
      rootAccordionCommon: {
        paddingLeft: '16px !important'
      },
      lineClamp: (props: any) =>
        ({
          width: '100%',
          ...lineClamp(props.line)
        } as any)
    }),
  { index: 1 }
);

interface Props {
  item?: any;
  itemChildren?: any;
  selectedItem?: any;
  chooseItem?: any;
  setChooseItem?: any;
  width?: any;
  colorIcon?: any;
  styleChildren?: React.CSSProperties | any;
  noButtonChildren?: any;
  styleAccordion?: React.CSSProperties | any;
  styleAccordionDetail?: React.CSSProperties | any;
  styleBg?: React.CSSProperties | any;
  styleHover?: React.CSSProperties | any;
  expandInfoStyle?: React.CSSProperties | any;
  dividerBottomAccordion?: any;
  dividerBottomList?: any;
  type?: String;
  typeAction?: String;
  checkedIcon?: any;
  specialAvatarStyle?: React.CSSProperties | any;
  styleTitle?: React.CSSProperties | any;
  stylePadding?: React.CSSProperties | any;
  styleMargin?: React.CSSProperties | any;
  styleBtnGrow?: React.CSSProperties | any;
  styleBtnMyGrow?: React.CSSProperties | any;
  stackButton?: any;
  line?: any;
  listSwitch?: any;
  setListSwitch?: any;
  styleListItemText?: React.CSSProperties;
}

function ListButton(props: Props) {
  const {
    item,
    itemChildren,
    selectedItem,
    chooseItem,
    setChooseItem,
    width,
    colorIcon,
    styleChildren,
    noButtonChildren,
    styleAccordion,
    styleAccordionDetail,
    styleBg,
    styleHover,
    expandInfoStyle,
    dividerBottomAccordion,
    dividerBottomList,
    type,
    typeAction,
    checkedIcon,
    specialAvatarStyle,
    styleTitle,
    stylePadding,
    styleMargin,
    styleBtnGrow,
    styleBtnMyGrow,
    stackButton,
    line,
    listSwitch,
    setListSwitch,
    styleListItemText
  } = props;
  const propsStype = {
    line: line || 2
  };
  const classes = useStyles(propsStype);
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [expandChildren, setExpandChildren] = useState<string | false>(false);
  if (!item?.length) {
    return (
      <Typography style={{ margin: '14px 14px' }} variant="subtitle2">
        Không có dữ liệu
      </Typography>
    );
  }
  const handleChooseAction = (actionChoose: any, id: any) => {
    if (actionChoose) {
      actionChoose();
    }
  };

  const handleChangedSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    idItem: any
  ) => {
    setListSwitch &&
      setListSwitch(prevSwitches =>
        prevSwitches.map(switchItem =>
          switchItem.id === idItem
            ? { ...switchItem, checked: !switchItem.checked }
            : switchItem
        )
      );
  };

  const handleChange = (event: any, value) => {
    let newChooseItem: any = [...chooseItem];
    if (event.target.checked) {
      setChooseItem(newChooseItem.concat([value]));
    } else {
      setChooseItem(newChooseItem.filter((el: any) => el.id !== value.id));
    }
  };
  const handleChoose = (item: any) => {
    if (chooseItem) {
      const isItemExist = chooseItem.some(el => el?.id === item?.id);
      if (isItemExist) {
        const saveChoose = chooseItem.filter(e => e.id !== item.id);
        setChooseItem(saveChoose);
      } else {
        const updatedChooseItem = [...chooseItem, item];
        setChooseItem(updatedChooseItem);
      }
    }
  };

  const renderAvatar = item => {
    if (item?.avatar_social && !item?.avatar_social) {
      return (
        <Avatar
          variant="rounded"
          className={classes.avatar}
          src={userDefault}
          sx={{
            width: '39px !important',
            height: '39px !important',
            borderRadius: '50%'
          }}
        ></Avatar>
      );
    } else if (item?.avatar_social) {
      return (
        <AvatarSocial
          type="avatar"
          avatarObj={item.avatar_social}
          style={{
            minWidth: 30,
            width: 30,
            height: 30,
            margin: '0px 15px 0px 0px',
            border: '1px solid #fff',
            ...item?.styleAvatar_social
          }}
        />
      );
    } else if (item?.avatar_icon) {
      return (
        <ListItemAvatar
          className={classes.icon}
          style={{
            width: 31,
            minWidth: 31,
            height: 31,
            ...item?.styleAvatar
          }}
        >
          <img
            style={{
              height: 30,
              width: 30,
              borderRadius: '50%',
              objectFit: 'cover',
              ...item?.styleImgAvatar
            }}
            src={item?.avatar_icon}
            alt="icons"
          />
        </ListItemAvatar>
      );
    } else if (item?.iconImage) {
      return (
        <ListItemAvatar
          className={classes.icon}
          style={{
            width: 35,
            minWidth: 35,
            height: 35,
            ...item.style
          }}
        >
          {item?.iconImage && (
            <img
              style={{
                height: 20,
                width: 20
              }}
              src={item.iconImage}
              alt="icons"
            />
          )}
        </ListItemAvatar>
      );
    } else if (item?.icon && type !== 'watch') {
      return (
        <ListItemAvatar
          style={{
            width: 35,
            minWidth: 35,
            height: 35,
            ...item.style,
            ...specialAvatarStyle
          }}
          className={
            colorIcon && selectedItem === item?.id
              ? classes.iconSelected
              : classes.icon
          }
        >
          {item.icon}
          {item?.notiHasArrow && (
            <Box
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: 'red',
                marginTop: '8px'
              }}
            ></Box>
          )}
        </ListItemAvatar>
      );
    } else if (item?.icon && type === 'watch') {
      return (
        <ListItemIcon sx={{ minWidth: 24 }}>
          <i className={`${item.icon} fa-lg`}></i>
        </ListItemIcon>
      );
    } else if (item?.cover_image_url) {
      return (
        <ListItemIcon>
          <Avatar
            variant="rounded"
            className={classes.avatar}
            src={item?.cover_image_url}
          ></Avatar>
        </ListItemIcon>
      );
    }
    return null;
  };

  const componentItem = (item: any, style: any, buttonChildren: any) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          overflow: 'hidden',
          borderRadius: '8px',
          marginTop: '4px',
          ...styleBg
        }}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          item.redirectAction && item.redirectAction();
        }}
      >
        {item && (
          <ListItem
            sx={{
              borderRadius: '10px',
              paddingLeft: '5px',
              marginLeft: '5px',
              backgroundColor:
                selectedItem === item?.id
                  ? 'button.primary.background'
                  : 'transparent',
              ...style,
              ...item?.styleButton,
              ...styleHover
            }}
            button={buttonChildren || false}
            onClick={() => {
              if (typeAction !== 'no_action') {
                if (item?.action) {
                  handleChooseAction(item?.action, item?.id);
                } else {
                  handleChoose(item);
                }
              }
            }}
            key={item?.id}
            className={selectedItem === item?.id ? classes.selected : undefined}
            secondaryAction={
              item?.checkbox ? (
                <Checkbox
                  edge="end"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(event, item)
                  }
                  checked={
                    chooseItem?.find((el: any) => el?.id === item.id)
                      ? true
                      : false
                  }
                  classes={{ checked: classes.checked }}
                />
              ) : checkedIcon && selectedItem === item.id ? (
                <i
                  style={{
                    color: buttonColor.backgroundColor,
                    margin: '0 10px'
                  }}
                  className="fa-light fa-check"
                ></i>
              ) : item?.switch ? (
                <Switch
                  checked={listSwitch?.find(el => el?.id === item?.id)?.checked}
                  onChange={event => handleChangedSwitch(event, item?.id)}
                  {...item.label}
                  color="primary"
                />
              ) : item?.numberNoti ? (
                <Box
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: 'red',
                    borderRadius: '100%',
                    color: '#fff',
                    fontSize: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position:
                      width === '48px' || specialAvatarStyle?.minWidth === 50
                        ? 'absolute'
                        : 'unset',
                    top: '-23px',
                    right:
                      specialAvatarStyle?.minWidth === 50 ? '-4px' : '-12px'
                  }}
                >
                  {item.numberNoti}
                </Box>
              ) : item?.isNoti ? (
                <Box
                  sx={{
                    width: '6px',
                    height: '6px',
                    backgroundColor: 'red',
                    borderRadius: '100%',
                    color: '#fff',
                    fontSize: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position:
                      width === '48px' || specialAvatarStyle?.minWidth === 50
                        ? 'absolute'
                        : 'unset',
                    top: '-23px',
                    right:
                      specialAvatarStyle?.minWidth === 50 ? '-4px' : '-12px'
                  }}
                ></Box>
              ) : null
            }
          >
            {renderAvatar(item)}
            <div
              style={{
                width:
                  type === 'shareGrow'
                    ? '45%'
                    : listSwitch && type === 'chatFullScreen'
                    ? '80%'
                    : '100%',
                ...item?.styleText
              }}
            >
              {item?.titleTop && (
                <ListItemText
                  primary={
                    <Typography variant="caption" sx={{ fontSize: 12 }}>
                      <b>{item?.titleTop}</b>
                    </Typography>
                  }
                  sx={{ margin: '-5px 0' }}
                />
              )}
              <ListItemText
                className={classes.lineClamp}
                style={{
                  marginLeft: 10,
                  ...styleListItemText
                }}
                primary={
                  <Typography
                    className={classes.lineClamp}
                    sx={{
                      fontSize: 15,
                      fontWeight: 600,
                      ...item?.styleTitle,
                      ...styleTitle
                    }}
                  >
                    {item?.title}
                  </Typography>
                }
                secondary={
                  item?.expandInfo ? (
                    <>
                      <Typography
                        sx={{
                          fontSize: 12,
                          // margin: '0px 5px',
                          ...item?.expandInfoStyle,
                          ...expandInfoStyle
                        }}
                      >
                        {item?.expandInfo}
                      </Typography>
                      {item?.expandInfoToo ? (
                        <Typography
                          sx={{
                            fontSize: 12,
                            margin: '0px 5px',
                            ...item?.expandInfoStyle,
                            ...expandInfoStyle
                          }}
                        >
                          {item?.expandInfoToo}
                        </Typography>
                      ) : null}
                    </>
                  ) : null
                }
              />
            </div>
            {item?.button && (
              <ListItem
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  ...item?.styleParentListButton
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent'
                  },
                  cursor: item?.button?.disabled ? 'no-drop' : 'pointer'
                }}
                secondaryAction={
                  <>
                    {item?.buttonPrev && (
                      <ButtonInherit
                        action={item.buttonPrev.action}
                        color={
                          item.buttonPrev.inherit
                            ? ''
                            : buttonColor.backgroundColor
                        }
                        label={item.buttonPrev.label}
                        loading={item.buttonPrev.loading}
                        style={{
                          fontSize: 14,
                          marginRight: 0,
                          ...item.buttonPrev.style
                        }}
                        disabled={item.buttonPrev.disabled}
                      />
                    )}
                    <ButtonInherit
                      action={item?.button.action}
                      color={
                        item?.button.inherit ? '' : buttonColor.backgroundColor
                      }
                      label={item?.button.label}
                      loading={item?.button.loading}
                      style={{
                        fontSize: 14,
                        margin: 0,
                        ...item?.button.style
                      }}
                      disabled={item?.button.disabled}
                    />
                  </>
                }
              ></ListItem>
            )}
          </ListItem>
        )}
        {item?.stackButton?.length && (
          <Grid
            sx={{
              width: '100%',
              display: 'flex',
              marginBottom: '10px',
              ...stackButton
            }}
            container
            item
            spacing={1}
            xs={12}
          >
            {item?.stackButton.map((el: any, index: any) => {
              return el.type === 'expand_button' ? (
                <Grid key={index} item xs={4}>
                  <IconButtonOptions
                    name={el?.label}
                    icon={
                      <i
                        style={{ fontSize: '14px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 0px',
                      minWidth: '101px',
                      width: '100%'
                    }}
                    options={el?.option}
                    styleListMenu={{ minWidth: '300px' }}
                    styleNameButton={{ fontSize: 14, color: 'text.primary' }}
                  />
                </Grid>
              ) : (
                <Grid key={index} item xs={8}>
                  <ButtonInherit
                    action={el?.action}
                    color={el?.inherit ? '' : '#e7f3ff'}
                    textColor={el.inherit ? '' : buttonColor.backgroundColor}
                    icon={el?.icon}
                    label={el?.label}
                    loading={el?.loading}
                    style={{
                      fontSize: 14,
                      margin: 0,
                      width: '100%',
                      backgroundColor:
                        theme.palette.mode === 'dark' ? '#263951' : '#e4e6eb',
                      ...el?.style,
                      ...el?.styleButton
                    }}
                    fullWidth={el?.fullWidth}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
        {item?.divider && <Divider sx={{ marginTop: '-5px' }} />}
      </Box>
    );
  };
  const handleChangeExpanded = (isExpanded: boolean, panel: string) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <List className={classes.list}>
      {item?.map((el: any, index: any) => (
        <BaseLink
          key={index}
          url={el?.url ? el?.url : null}
          component={
            !el?.accordion ? (
              componentItem(
                {
                  ...el,
                  styleButton: {
                    width: width,
                    ...el?.styleButton
                  }
                },
                null,
                true
              )
            ) : (
              <Accordion
                sx={{
                  overflow: 'hidden',
                  boxShadow: 'none',
                  margin: '0px 5px 0px 5px',
                  backgroundImage: 'none',
                  backgroundColor: 'transparent',
                  width: width ? width : '100%',
                  ...styleAccordion,
                  ...styleMargin,
                  '& .MuiAccordionSummary-root': {
                    ...styleBtnMyGrow
                  }
                }}
                disableGutters
                expanded={expanded === el?.id}
                onChange={(event, isExpanded) =>
                  handleChangeExpanded(isExpanded, el?.id)
                }
              >
                <AccordionSummary
                  aria-controls="panel1bh-content"
                  classes={{
                    root: type
                      ? classes.rootAccordionChat
                      : classes.rootAccordionCommon
                  }}
                  sx={{
                    borderRadius: '10px',
                    '& .MuiAccordionSummary-content': {
                      margin: 0
                    },
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                      marginLeft: '-40px'
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      marginLeft: '-40px',
                      ...styleBtnGrow
                    },
                    backgroundColor: 'transparent',
                    ...stylePadding,
                    '& .MuiIconButton-root': {
                      '&:hover': {
                        backgroundColor: 'unset !important'
                      }
                    }
                  }}
                  className={
                    selectedItem === el?.id
                      ? theme.palette.mode === 'dark'
                        ? classes.darkSelected
                        : classes.selected
                      : undefined
                  }
                  expandIcon={
                    <IconButton
                      sx={{
                        borderRadius: '50%',
                        color: 'text.primary'
                      }}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  }
                >
                  {componentItem(el, null, true)}
                </AccordionSummary>
                {dividerBottomAccordion && (
                  <Divider sx={{ margin: '0px 5px' }} />
                )}
                <AccordionDetails
                  sx={{
                    marginTop: '-8px',
                    ...styleAccordionDetail
                  }}
                >
                  {itemChildren?.length ? (
                    itemChildren.map((item: any, index) => (
                      <BaseLink
                        key={index}
                        url={item?.url ? item?.url : null}
                        component={componentItem(
                          item,
                          styleChildren,
                          !noButtonChildren
                        )}
                      />
                    ))
                  ) : el?.itemChildren?.length ? (
                    el?.itemChildren.map((element: any, index: any) => {
                      return element?.itemChild?.length ? (
                        <Accordion
                          key={index}
                          sx={{
                            overflow: 'hidden',
                            boxShadow: 'none',
                            margin: '0px 5px 0px 5px',
                            backgroundImage: 'none',
                            backgroundColor: 'transparent',
                            width: width ? width : '100%',
                            ...styleAccordion
                          }}
                          disableGutters
                          expanded={expandChildren === element?.id}
                          onChange={(event, isExpanded) => {
                            setExpandChildren(isExpanded ? element?.id : false);
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1bh-content"
                            classes={{
                              root: type
                                ? classes.rootAccordionChat
                                : classes.rootAccordionCommon
                            }}
                            sx={{
                              borderRadius: '10px',
                              '& .MuiAccordionSummary-content': {
                                margin: 0
                              },
                              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded':
                                {
                                  marginLeft: '-40px'
                                },
                              '& .MuiAccordionSummary-expandIconWrapper': {
                                marginLeft: '-40px'
                              },
                              backgroundColor: 'transparent'
                            }}
                            className={
                              selectedItem === el?.id
                                ? theme.palette.mode === 'dark'
                                  ? classes.darkSelected
                                  : classes.selected
                                : undefined
                            }
                            expandIcon={
                              <IconButton
                                sx={{
                                  borderRadius: '50%',
                                  color: 'text.primary'
                                }}
                              >
                                <ExpandMoreIcon />
                              </IconButton>
                            }
                          >
                            {componentItem(element, null, true)}
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              marginTop: '-8px',
                              ...styleAccordionDetail
                            }}
                          >
                            {element?.itemChild?.map(
                              (itemChild: any, index: any) => (
                                <BaseLink
                                  key={index}
                                  component={componentItem(
                                    itemChild,
                                    null,
                                    !noButtonChildren
                                  )}
                                />
                              )
                            )}
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <BaseLink
                          key={index}
                          component={componentItem(
                            element,
                            element?.styleChildren,
                            !noButtonChildren
                          )}
                        />
                      );
                    })
                  ) : (
                    <Typography style={{ margin: 10 }} variant="subtitle2">
                      Không có dữ liệu
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            )
          }
        />
      ))}
      {dividerBottomList && <Divider sx={{ margin: '0px 5px -15px 5px' }} />}
    </List>
  );
}

export default ListButton;
