import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ButtonCustom from 'src/components/Button/Button';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import SearchGlobalLeft from 'src/pages/SeachGlobal/SeachGlobalLeft';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import {
  getCalendarCourseApi,
  getCourseCategories
} from 'src/apis/socialCourse.api';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import InputSearch from 'src/components/Input/SocialInput/InputSearch';
import ListButton from 'src/components/List/ListButton';
import StepperSocial from 'src/components/Stepper';
import { resetCourse } from 'src/store/action/socialCourseAction';
import CourseCreateUpdate from '../CourseCreateUpdate/CourseCreateUpdate';
import SnackbarNotification from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import Loading from 'src/components/Loading/Loading';
import { getListPagesApi } from 'src/apis/socialPages.api';
import { updateHasMore } from 'src/store/action/socialPageAction';
import avatarDefault from 'src/assets/images/avatar_default.jpg';
import IconFavorite from 'src/components/IconFavorite';

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      root: {
        height: '100%',
        backgroundColor: theme.palette.background.primary,
        position: 'fixed',
        boxShadow: '1px 0px 5px 0.4px rgb(0 0 0 / 10%)'
      },
      headerPage: {
        minHeight: 50,
        margin: '10px 0px 0px'
      },
      footerPage: {
        position: 'absolute',
        bottom: '50px',
        left: 0,
        width: '96%',
        zIndex: 1200
      },
      rootPage: {
        width: '100%',
        height: 'calc(100% - 50px)',
        overflowY: 'hidden',
        '&:hover': {
          overflowY: 'auto'
        },
        '&:hover&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#bdbdbd',
          borderRadius: 10
        }
      },
      wrapCard: {
        boxShadow: 'none !important',
        borderRadius: '10px !important'
      },
      flexCenter: {
        display: 'flex',
        alignItems: 'center'
      },
      wrapCardMedia: {
        maxWidth: '80px',
        marginLeft: '12px',
        borderRadius: '8px'
      },
      wrapCardContent: {
        width: '100%',
        paddingRight: '8px'
      },
      iconOffline: {
        backgroundColor: '#999999',
        color: '#fff',
        height: 35,
        width: 35,
        margin: '0 5px'
      },
      iconBack: {
        backgroundColor: '#b4b3b3',
        color: '#fff',
        height: 35,
        width: 35,
        margin: '0 5px'
      },
      title: {
        margin: '8px 14px 8px 3px !important'
      },
      input: {
        margin: '8px 14px 8px 3px',
        width: '100%'
      },
      wrapCategory: {
        margin: '8px 8px 0px'
      },
      divider: {
        margin: '0px 0px 5px'
      },
      rootCategory: {
        width: '300px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '105px'
      },
      listItem: {
        paddingLeft: 20
      },
      wrapCreate: {
        margin: '0px 10px 160px 0px'
      },
      wrapHeader: {
        margin: '0px 10px 0px 0px',
        padding: '0px 5px'
      },
      wrapButton: {
        padding: '0px 10px',
        width: 300
      },
      wrapBodyCenter: {
        width: '300px',
        display: 'flex',
        flexDirection: 'column'
      },
      divFlexCol: {
        display: 'flex',
        flexDirection: 'column'
      },
      wrapTitleBody: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '8px'
      },
      wrapFooter: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 0px 12px 12px',
        backgroundColor: theme.palette.background.primary,
        marginBottom: '-5px'
      },
      divFlexEnd: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      button: {
        marginTop: '12px',
        width: '70%'
      },
      lineClamp: {
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-word',
        overflow: 'hidden'
      },
      notData: {
        paddingLeft: '10px'
      },
      highlight: {
        backgroundColor: theme.palette.background.rgba
      }
    }),
  { index: 1 }
);

const CourseLeft = () => {
  const classes = useStyles();
  const history = useHistory();
  const match: any = useRouteMatch();
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const matchesCreate = useMediaQuery('(max-width:1000px)');
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const courseInfo = useSelector((state: any) => state.courseReducer.info);
  const hasMore = useSelector((state: any) => state.pageReducer.hasMore);
  const [pagesAdminCourse, setPagesAdminCourse] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<any>('home');
  const [categories, setCategories] = React.useState<any>([]);
  const [createCourse, setCreateCourse] = useState(false);
  const [updateCourse, setUpdateCourse] = useState<any>(false);
  const [courseOwner, setCourseOwner] = React.useState<any>({});
  const [listCourseMe, setListCourseMe] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [listDiscoverCourse, setListDiscoverCourse] = React.useState<any>(null);
  const [checkCreateUpdate, setCheckCreateUpdate] = React.useState<any>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [indexCategories, setIndexCategories] = React.useState<any>(null);
  const [checkStep, setCheckStep] = React.useState<any>({
    step1: false,
    step2: false
  });

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [noti, setNoti] = React.useState<any>({
    code: 200,
    message: ''
  });
  const [keyword, setKeyword] = React.useState<any>('');
  const steps = 3;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  const fetchCategories = async () => {
    let response = await getCourseCategories();
    if (response.status === 200) {
      setCategories(response.data);
    }
  };
  React.useEffect(() => {
    const getPagesCourse = async () => {
      let res = await getListPagesApi({
        limit: 5,
        page_purpose: 'sell_courses',
        roles: ['admin', 'owner']
      });
      setPagesAdminCourse(res.data);
    };
    getPagesCourse();
  }, []);
  React.useEffect(() => {
    fetchCategories();
  }, []);

  React.useEffect(() => {
    if (match.path === PATHS.COURSE_CREATE) {
      dispatch(resetCourse());
    }
  }, [match.path]);

  const checkAvatar = () => {
    if (courseInfo?.page_owner) {
      return courseInfo?.page_owner?.avatar_url;
    } else {
      if (meInfo.avatar_media) {
        return (
          meInfo.avatar_media.show_url ??
          meInfo.avatar_media.preview_url ??
          meInfo.avatar_media.url
        );
      } else {
        return avatarDefault;
      }
    }
  };

  React.useEffect(() => {
    setCourseOwner({
      page_owner_id: courseInfo?.page_owner ? courseInfo?.page_owner?.id : null,
      id: courseInfo?.page_owner ? courseInfo?.page_owner?.id : meInfo.id,
      title: courseInfo?.page_owner
        ? courseInfo?.page_owner?.title
        : meInfo.display_name,
      avatar_icon: checkAvatar(),
      description: courseInfo?.page_owner
        ? 'Bên tổ chức - Trang của bạn'
        : 'Giảng viên - Trang cá nhân'
    });
  }, [JSON.stringify(courseInfo)]);

  const fetchCalendarCourse = async (params: any, setData: any) => {
    setIsLoading(true);
    let response = await getCalendarCourseApi(params);
    if (response.status === 200) {
      setData(response.data);
      setIsLoading(false);
    }
  };

  const listMenuCourse = [
    {
      id: 'course_home',
      title: 'Khám phá',
      styleTitle: { fontWeight: '600' },
      icon: (
        <i
          className="fa-light fa-calendar-days"
          style={{ fontSize: '20px' }}
        ></i>
      ),
      action: () => {
        history.push(PATHS.COURSE);
      },
      style: { height: 36, width: 36 },
      position: 'top'
    },
    {
      id: 'calendar',
      title: 'Khóa học của bạn',
      styleTitle: { fontWeight: '600' },
      icon: <i className="fa-light fa-user" style={{ fontSize: '20px' }}></i>,
      action: () => {
        history.push('/courses/calendar?q=going');
      },
      accordion: true,
      style: { height: 36, width: 36 },
      position: 'top',
      itemChildren: [
        {
          id: 'hosting',
          title: 'Tổ chức',
          styleButton: { width: '95%', marginLeft: '15px', marginTop: '5px' },
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-house"
            ></i>
          ),
          action: () => {
            history.push(`/courses/hosting`);
          }
        },
        {
          id: 'invites',
          title: 'Lời mời',
          styleTitle: { fontWeight: '400' },
          styleButton: { width: '95%', marginLeft: '15px', marginTop: '5px' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-envelope"
            ></i>
          ),
          action: () => {
            history.push(`/courses/invites`);
          }
        },
        {
          id: 'interested',
          title: 'Quan tâm',
          styleButton: { width: '95%', marginLeft: '15px', marginTop: '5px' },
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-star"
            ></i>
          ),
          action: () => {
            history.push(`/courses/interested`);
          }
        },
        {
          id: 'past',
          title: 'Khóa học đã học',
          styleButton: { width: '95%', marginLeft: '15px', marginTop: '5px' },
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-arrow-rotate-left"
            ></i>
          ),
          action: () => {
            history.push(`/courses/past`);
          }
        },
        {
          id: 'library',
          title: 'Thư viện nội dung',
          styleButton: { width: '95%', marginLeft: '15px', marginTop: '5px' },
          styleTitle: { fontWeight: '400' },
          icon: (
            <i
              style={{
                fontSize: 20,
                color: theme.palette.mode === 'dark' ? '#fff' : '#000'
              }}
              className="fa-light fa-book-blank"
            ></i>
          ),
          action: () => {
            dispatch(resetCourse());
            history.push(`/courses/library`);
          }
        }
      ]
    }
    // {
    //   id: 'course_notification',
    //   title: 'Thông báo',
    //   styleTitle: { fontWeight: '600' },
    //   icon: (
    //     <i
    //       className="fa-light fa-bell"
    //       style={{
    //         fontSize: '20px',
    //         color: theme.palette.mode === 'dark' ? '#fff' : '#000'
    //       }}
    //     ></i>
    //   ),
    //   action: () => {},
    //   style: { height: 36, width: 36 },
    //   position: 'top'
    // }
  ];

  React.useEffect(() => {
    let { id, key, tab } = match?.params;
    switch (match.path) {
      case PATHS.COURSE:
        setSelectedIndex('course_home');
        setIndexCategories(null);
        break;
      case PATHS.COURSE_MINE: {
        if (id) {
          setSelectedIndex(id);
          setIndexCategories(null);
        }
        if (key) {
          setSelectedIndex(key);
          setIndexCategories(null);
        }
        if (tab) {
          setSelectedIndex(tab);
          setIndexCategories(null);
        }
        break;
      }
      case PATHS.COURSE_CATEGORY: {
        setSelectedIndex(id);
        setIndexCategories(id);
        break;
      }
      case PATHS.COURSE_CREATE: {
        setSelectedIndex('create_course');
        setIndexCategories(null);
        break;
      }
      case PATHS.COURSE_DETAIL: {
        setSelectedIndex('');
        setIndexCategories(null);
        break;
      }
    }
  }, [match.path, JSON.stringify(match.params)]);

  React.useEffect(() => {
    if (
      match.path === PATHS.COURSE_MINE ||
      match.path === PATHS.COURSE_DETAIL
    ) {
      if (courseInfo?.course_category?.id) {
        fetchCalendarCourse(
          {
            exclude_current_user: 'true',
            course_category_id: courseInfo?.course_category?.id,
            limit: 5,
            status: 'approved'
          },
          setListCourseMe
        );
      }
      if (listDiscoverCourse === null) {
        fetchCalendarCourse(
          {
            exclude_paticipant: 'true',
            limit: 5,
            status: 'approved'
          },
          setListDiscoverCourse
        );
      }
    }
    return () => {
      setListCourseMe(null);
    };
  }, [match.path, courseInfo?.id]);

  React.useEffect(() => {
    if (match.params.key === 'edit') {
      setActiveStep(2);
    }
  }, [match.params.key]);

  const renderListCourse = (course: any, index: any) => {
    return (
      <Card className={classes.wrapCard} key={index}>
        <CardActionArea
          onClick={() => history.push(`/course/${course.id}/about`)}
        >
          <Box className={classes.flexCenter}>
            <CardMedia
              className={classes.wrapCardMedia}
              component="img"
              height="50px"
              image={course?.banner ? course.banner.preview_url : ''}
            />
            <CardContent className={classes.wrapCardContent}>
              <Typography fontSize={12} color="#f3425f">
                {course?.start_time}
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={600}
                className={classes.lineClamp}
              >
                {course?.title}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    );
  };

  const loadActivity = async maxId => {
    let res = await getListPagesApi({
      max_id: maxId,
      limit: 5,
      page_purpose: 'sell_courses'
    });
    if (res.data.length < 5) {
      dispatch(updateHasMore(false));
    }
    setPagesAdminCourse(prev => [...prev, ...res.data]);
  };
  const funcLoad = () => {
    let pageLast = [...pagesAdminCourse].pop();
    let maxId = pageLast?.id;
    loadActivity(maxId);
  };

  const renderHeader = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.COURSE_CREATE: {
        if (key === 'offline') {
          return (
            <Grid container>
              <Grid container item xs={12}>
                <IconButton
                  className={classes.iconOffline}
                  onClick={() => history.push(PATHS.COURSE)}
                >
                  <ClearIcon />
                </IconButton>
                &nbsp;
                <Typography fontWeight={700} variant="h5">
                  Chi tiết khóa học
                </Typography>
              </Grid>
            </Grid>
          );
        } else {
          return (
            <Grid container>
              <Grid container item xs={12}>
                <IconButton
                  className={classes.iconBack}
                  onClick={() => history.push(PATHS.COURSE)}
                >
                  <ClearIcon />
                </IconButton>
                &nbsp;
                <Typography fontWeight={700} variant="h5">
                  Tạo khóa học
                </Typography>
              </Grid>
            </Grid>
          );
        }
      }
      case PATHS.COURSE_DETAIL: {
        switch (id) {
          case 'search':
            return null;
        }
        switch (key) {
          case 'edit':
            return (
              <Grid container>
                <Grid container item xs={12}>
                  <IconButton
                    className={classes.iconOffline}
                    onClick={() => history.push(`/course/${id}/about`)}
                  >
                    <ClearIcon />
                  </IconButton>
                  &nbsp;
                  <Typography fontWeight={700} variant="h5">
                    Chỉnh sửa khóa học
                  </Typography>
                </Grid>
              </Grid>
            );

          default:
            return (
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    className={classes.title}
                    fontWeight={700}
                    variant="h5"
                  >
                    Khóa học
                  </Typography>
                  <div className={classes.input}>
                    <InputSearch
                      keyword={keyword}
                      setKeyword={setKeyword}
                      label="Tìm kiếm khóa học"
                      action={() => history.push(`/course/search?q=${keyword}`)}
                    />
                  </div>
                </Grid>
              </Grid>
            );
        }
      }
      default:
        return (
          <Grid container spacing={2}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                className={classes.title}
                fontWeight={700}
                variant="h5"
              >
                Khóa học
              </Typography>

              <IconFavorite
                styleContainer={{ marginRight: '10px' }}
                type="courses"
              />
              <div className={classes.input}>
                <InputSearch
                  keyword={keyword}
                  setKeyword={setKeyword}
                  label="Tìm kiếm khóa học"
                  action={() => history.push(`/course/search?q=${keyword}`)}
                />
              </div>
            </Grid>
          </Grid>
        );
    }
  };

  const renderCategory = () => {
    return (
      <div className={classes.rootCategory}>
        <Divider className={classes.divider} />
        <div className={classes.wrapCategory}>
          <Typography fontSize={16} fontWeight={600} gutterBottom>
            Hạng mục
          </Typography>
        </div>
        {categories?.map((item: any) => {
          return (
            <React.Fragment key={item.id}>
              <ListItem
                disablePadding
                onClick={() => setIndexCategories(item?.id)}
                className={
                  item && item?.id === indexCategories ? classes.highlight : ''
                }
              >
                <ListItemButton
                  className={classes.listItem}
                  onClick={() => history.push(`/courses/categories/${item.id}`)}
                >
                  <ListItemText
                    primary={
                      <Typography fontSize={15} fontWeight={600}>
                        {item.text}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderBody = () => {
    let { key, id } = match?.params;
    switch (match.path) {
      case PATHS.COURSE_CREATE:
        switch (key) {
          case 'online':
            return {
              bodyTop: null,
              bodyCenter: <div></div>,
              bodyFooter: null
            };
          case 'offline':
            return {
              bodyTop: null,
              bodyCenter: (
                <div className={classes.wrapCreate}>
                  <IconButtonOptions
                    name={courseOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={courseOwner.avatar_icon ?? avatarDefault}
                    description={courseOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    style={{
                      margin: '0px 10px',
                      width: matchesCreate ? '100%' : '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setCourseOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description: 'Giảng viên - Trang cá nhân'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdminCourse?.map((el: any) => ({
                          id: el.id,
                          username: el.username,
                          avatar_icon: el.avatar_media
                            ? el.avatar_media.show_url ??
                              el.avatar_media.preview_url ??
                              el.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setCourseOwner({
                              page_owner_id: el.id,
                              title: el.title,
                              avatar_icon: el.avatar_media
                                ? el.avatar_media.show_url ??
                                  el.avatar_media.preview_url ??
                                  el.avatar_media.url
                                : avatarDefault,
                              description: 'Giảng viên - Trang của bạn'
                            });
                          },
                          label: el.title,
                          styleLabel: { width: '228px' }
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{
                      zIndex: '1001',
                      width: matchesCreate ? '100%' : '330px'
                    }}
                    searchFeature={true}
                    paramsSearchPage={{ page_purpose: 'sell_courses' }}
                    actionSearch={el => {
                      setCourseOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Giảng viên - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                    scrollableTarget="scroll-paper"
                  />
                  <CourseCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    organization={courseOwner}
                    createCourse={createCourse}
                    setCreateCourse={setCreateCourse}
                    categories={categories}
                    fetchCategories={fetchCategories}
                    activeStep={activeStep}
                    setCheckStep={setCheckStep}
                    checkStep={checkStep}
                    match={match}
                    setNoti={setNoti}
                    setOpenSnackbar={setOpenSnackbar}
                  />
                </div>
              ),
              bodyFooter: null
            };

          default:
            return {
              bodyTop: null,
              bodyCenter: (
                <div className={classes.wrapHeader}>
                  <IconButtonOptions
                    name={courseOwner.title}
                    styleNameButton={{
                      width: '190px',
                      color: 'text.primary'
                    }}
                    avatar={courseOwner.avatar_icon}
                    description={courseOwner.description}
                    styleAvatar={{ height: 36, width: 36, marginRight: '12px' }}
                    icon={
                      <i
                        style={{ fontSize: '16px' }}
                        className="fa-solid fa-caret-down"
                      ></i>
                    }
                    openPopup={true}
                    scrollableTarget="scroll-paper"
                    style={{
                      margin: '0px 10px',
                      width: '330px',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: 'button.primary.background'
                    }}
                    options={[
                      [
                        { title: 'Hồ sơ' },
                        {
                          id: meInfo.id,
                          label: meInfo.display_name,
                          avatar_icon: meInfo.avatar_media
                            ? meInfo.avatar_media.show_url ??
                              meInfo.avatar_media.preview_url ??
                              meInfo.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setCourseOwner({
                              id: meInfo.id,
                              title: meInfo.display_name,
                              avatar_icon: meInfo.avatar_media
                                ? meInfo.avatar_media.show_url ??
                                  meInfo.avatar_media.preview_url ??
                                  meInfo.avatar_media.url
                                : avatarDefault,
                              description: 'Giảng viên - Trang cá nhân'
                            });
                          },
                          styleLabel: { width: '228px' }
                        }
                      ],
                      [{ title: 'Trang' }].concat(
                        pagesAdminCourse?.map((el: any) => ({
                          id: el.id,
                          username: el.username,
                          avatar_icon: el.avatar_media
                            ? el.avatar_media.show_url ??
                              el.avatar_media.preview_url ??
                              el.avatar_media.url
                            : avatarDefault,
                          checkbox: true,
                          action: () => {
                            setCourseOwner({
                              page_owner_id: el.id,
                              title: el.title,
                              avatar_icon: el.avatar_media
                                ? el.avatar_media.show_url ??
                                  el.avatar_media.preview_url ??
                                  el.avatar_media.url
                                : avatarDefault,
                              description: 'Giảng viên - Trang của bạn'
                            });
                          },
                          label: el.title,
                          styleLabel: { width: '228px' }
                        }))
                      )
                    ]}
                    styleListMenu={{
                      minWidth: '320px',
                      height: '250px',
                      overflowY: 'scroll'
                    }}
                    stylePopup={{ zIndex: '1001', width: '330px' }}
                    searchFeature={true}
                    actionSearch={el => {
                      setCourseOwner({
                        page_owner_id: el.id,
                        title: el.title,
                        avatar_icon: el.avatar_media?.show_url
                          ? el.avatar_media.show_url
                          : avatarDefault,
                        description: 'Giảng viên - Trang của bạn'
                      });
                    }}
                    infiniteScroll={true}
                    funcLoad={funcLoad}
                    hasMore={hasMore}
                  />

                  <ListButton
                    item={[
                      {
                        id: 'create_course',
                        title: 'Tạo khóa học',
                        styleTitle: { fontWeight: '600' },
                        icon: (
                          <i
                            className="fa-solid fa-user-group"
                            style={{ fontSize: '18px' }}
                          ></i>
                        ),
                        style: { height: 36, width: 36 },
                        position: 'top',
                        action: () => setActiveStep(0)
                      }
                    ]}
                    selectedItem={selectedIndex}
                    width={'340px'}
                    typeAction={'action'}
                  />
                </div>
              ),
              bodyFooter: null
            };
        }
      case PATHS.COURSE_CREATE:
      case PATHS.COURSE:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuCourse.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'280px'}
                type="course"
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleAccordionDetail={{ padding: '3px' }}
                typeAction={'action'}
              />
              <div className={classes.wrapButton}>
                <ButtonCustom
                  label="Tạo khóa học mới"
                  action={() => {
                    dispatch(resetCourse());
                    setActiveStep(0);
                    history.push('/courses/create/offline');
                  }}
                  solid={true}
                  style={{
                    color: '#e7f3ff',
                    backgroundColor: buttonColor.backgroundColor
                  }}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.COURSE_CATEGORY:
        return {
          bodyTop: (
            <>
              <ListButton
                item={listMenuCourse.filter(el => el.position === 'top')}
                selectedItem={selectedIndex}
                width={'280px'}
                type="course"
                stylePadding={{ padding: '0px !important', margin: '6px 0' }}
                styleAccordionDetail={{ padding: '3px' }}
                typeAction={'action'}
              />
              <div className={classes.wrapButton}>
                <ButtonCustom
                  label="Tạo khóa học mới"
                  action={() => {
                    dispatch(resetCourse());
                    setActiveStep(0);
                    history.push('/courses/create/offline');
                  }}
                  style={{ color: '#e7f3ff' }}
                  solid={true}
                  icon={<i className="far fa-plus"></i>}
                />
              </div>
            </>
          ),
          bodyCenter: null,
          bodyFooter: renderCategory()
        };
      case PATHS.COURSE_MINE:
      case PATHS.COURSE_DETAIL:
        switch (id) {
          case 'search':
            return {
              bodyTop: null,
              bodyCenter: (
                <div>
                  <SearchGlobalLeft
                    type="course"
                    styleGui={{
                      height: '100%',
                      width: 320,
                      backgroundColor: theme.palette.background.primary,
                      position: 'none',
                      overflowY: 'hidden'
                    }}
                  />
                  ;
                </div>
              ),
              bodyFooter: null
            };
        }
        switch (key) {
          case 'edit':
            return {
              bodyTop: (
                <div className={classes.wrapCreate}>
                  <IconButtonOptions
                    name={courseInfo?.page_owner?.title ?? courseOwner.title}
                    avatar={
                      courseInfo?.page_owner?.avatar_icon ??
                      courseOwner.avatar_icon
                    }
                    description={
                      courseInfo?.page_owner?.description ??
                      courseOwner.description
                    }
                    styleAvatar={{
                      height: 36,
                      width: 36,
                      marginRight: '12px'
                    }}
                    style={{
                      margin: '0px 0px',
                      width: '100%',
                      minHeight: '64px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      backgroundColor: '#fff'
                    }}
                    disabled
                  />
                  <CourseCreateUpdate
                    setCheckCreateUpdate={setCheckCreateUpdate}
                    updateCourse={updateCourse}
                    idUpdate={id}
                    setUpdateCourse={setUpdateCourse}
                    categories={categories}
                    fetchCategories={fetchCategories}
                    activeStep={activeStep}
                    setNoti={setNoti}
                    setOpenSnackbar={setOpenSnackbar}
                    setCheckStep={setCheckStep}
                    checkStep={checkStep}
                    organization={courseOwner}
                    match={match}
                  />
                </div>
              ),
              bodyCenter: null,
              bodyFooter: null
            };

          default:
            return {
              bodyTop: (
                <>
                  <ListButton
                    item={listMenuCourse.filter(el => el.position === 'top')}
                    selectedItem={selectedIndex}
                    width={'280px'}
                    type="course"
                    stylePadding={{
                      padding: '0px !important',
                      margin: '6px 0'
                    }}
                    styleAccordionDetail={{ padding: '8px 5px 5px' }}
                    typeAction={'action'}
                  />
                  <div className={classes.wrapButton}>
                    <ButtonCustom
                      label="Tạo khóa học mới"
                      action={() => {
                        dispatch(resetCourse());
                        setActiveStep(0);
                        history.push('/courses/create/offline');
                      }}
                      solid={true}
                      style={{ color: '#e7f3ff' }}
                      icon={<i className="far fa-plus"></i>}
                    />
                  </div>
                </>
              ),
              bodyCenter: (
                <div className={classes.wrapBodyCenter}>
                  <div className={classes.divFlexCol}>
                    <Divider />
                    <div className={classes.wrapTitleBody}>
                      <Typography fontSize={16} fontWeight={600}>
                        Khóa học tương tự
                      </Typography>
                      <Typography
                        onClick={() =>
                          history.push(
                            `/courses/categories/${courseInfo?.course_category?.id}`
                          )
                        }
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {isLoading ? (
                      <Loading />
                    ) : listCourseMe?.length ? (
                      listCourseMe
                        ?.filter((item: any) => item?.id !== courseInfo?.id)
                        ?.map((el: any, index) => renderListCourse(el, el.id))
                    ) : (
                      <span className={classes.notData}>Không có dữ liệu</span>
                    )}
                  </div>
                  <div className={classes.divFlexCol}>
                    <Divider />
                    <div className={classes.wrapTitleBody}>
                      <Typography fontSize={16} fontWeight={600}>
                        Khóa học đề xuất
                      </Typography>
                      <Typography
                        onClick={() => history.push('/courses/home')}
                        fontSize={14}
                        color={buttonColor.backgroundColor}
                        sx={{ cursor: 'pointer' }}
                      >
                        Xem tất cả
                      </Typography>
                    </div>
                    {listDiscoverCourse?.length ? (
                      listDiscoverCourse
                        ?.filter((item: any) => item?.id !== courseInfo?.id)
                        ?.map((el: any, index) => renderListCourse(el, el.id))
                    ) : (
                      <span className={classes.notData}>Không có dữ liệu</span>
                    )}
                  </div>
                </div>
              ),
              bodyFooter: renderCategory()
            };
        }
      default:
        return { bodyTop: null, bodyCenter: null, bodyFooter: null };
    }
  };

  const renderFooter = () => {
    let { key, id } = match.params;
    switch (match.path) {
      case PATHS.COURSE_CREATE: {
        if (key) {
          return (
            <Box className={classes.wrapFooter}>
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box className={classes.divFlexEnd} sx={{ alignItems: 'end' }}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={createCourse}
                    label="Lưu bản nháp"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setCreateCourse(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      height: '36px',
                      marginLeft: '7px',
                      color: 'white',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      }
                    }}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                    }
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        } else return null;
      }
      case PATHS.COURSE_CATEGORY:
      case PATHS.COURSE_MINE:
        return null;
      case PATHS.COURSE_DETAIL:
        if (key === 'edit') {
          return (
            <Box className={classes.wrapFooter}>
              <StepperSocial activeStep={activeStep} steps={steps} />
              <Box className={classes.divFlexEnd}>
                {activeStep !== 0 && (
                  <ButtonInherit
                    label="Quay lại"
                    action={() => {
                      handleBack();
                    }}
                    textColor={buttonColor.backgroundColor}
                    style={{ marginTop: '12px' }}
                  />
                )}
                {activeStep === steps - 1 ? (
                  <ButtonInherit
                    loading={updateCourse}
                    label="Cập nhật khóa học"
                    color={buttonColor.backgroundColor}
                    textColor="#fff"
                    style={{ marginTop: '12px', width: '70%' }}
                    action={() => {
                      setUpdateCourse(true);
                    }}
                    disabled={!checkCreateUpdate}
                  />
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      marginTop: '12px',
                      marginLeft: '7px',
                      width: '70%',
                      backgroundColor: buttonColor.backgroundColor,
                      '&:hover': {
                        backgroundColor: buttonColorHover.backgroundColor
                      }
                    }}
                    disabled={
                      activeStep === 0
                        ? !checkStep.step1
                        : activeStep === 1
                        ? !checkStep.step2
                        : activeStep === 2
                    }
                  >
                    Tiếp
                  </Button>
                )}
              </Box>
            </Box>
          );
        }
        break;

      default:
        return null;
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        position:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 'fixed'
            : 'static',

        width:
          match.path === PATHS.COURSE_CREATE ||
          (match.params?.key === 'edit' && !!match.params?.id)
            ? matchesCreate
              ? '100%'
              : 385
            : renderBody().bodyTop ||
              renderBody().bodyCenter ||
              renderBody().bodyFooter
            ? 310
            : 0,
        margin:
          renderBody().bodyTop ||
          renderBody().bodyCenter ||
          renderBody().bodyFooter
            ? 0
            : '5px 10px 40px -20px',
        padding:
          match.path === PATHS.COURSE_CREATE ||
          (match.params?.key === 'edit' && !!match.params?.id)
            ? '5px 5px 40px 5px'
            : match.params?.id !== 'search'
            ? '5px 0px 40px 3px'
            : '0px'
      }}
    >
      {match.params?.id !== 'search' && (
        <div className={classes.headerPage}>{renderHeader()}</div>
      )}

      <div className={classes.rootPage}>
        {renderBody().bodyTop}
        {match.path !== PATHS.PAGE_CREATE && match.params?.id !== 'search' && (
          <div style={{ height: 10 }}></div>
        )}
        {renderBody().bodyCenter}
        {match.path === PATHS.COURSE_DETAIL ||
        match.path === PATHS.COURSE_MINE ||
        match.path === PATHS.COURSE ||
        match.path === PATHS.COURSE_CATEGORY
          ? renderBody().bodyFooter
          : null}
      </div>

      <div className={classes.footerPage}>
        {match.path !== PATHS.PAGE_CREATE &&
          match.path !== PATHS.PAGE &&
          renderFooter() && <Divider />}
        {renderFooter()}
      </div>
      <SnackbarNotification
        style={{ marginLeft: '20px' }}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
        code={noti?.code}
        message={noti?.message}
      />
    </div>
  );
};

export default CourseLeft;
