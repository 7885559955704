/* eslint-disable jsx-a11y/alt-text */ /* eslint-disable react-hooks/exhaustive-deps */ import {
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react';
import { OnChangeHandlerFunc } from 'react-mentions';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { getPreviewUrlApi } from 'src/apis/socialPost.api';
import { searchUserGlobal } from 'src/apis/socialSearch.api';
import background from 'src/assets/images/background.png';
import target_default from 'src/assets/images/target.svg';
import { formatDateTimeToStringDateTime } from 'src/common/string';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import ImageRender from 'src/components/Dialog/DialogCUPost/CUMenuUi/ImageRender';
import Emoji from 'src/components/Emoji/EmojiPopup';
import Mentions from 'src/components/Mentions/Mentions';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import CardPost from 'src/components/SocialPost/SocialContent/Card';
import GroupPageShared from 'src/components/SocialPost/SocialContent/GroupPageShared';
import ShareUI from 'src/components/SocialPost/SocialContent/Share';
import ShareProductOrCourse from 'src/components/SocialPost/SocialContent/ShareProductOrCourse';
import { PATHS } from 'src/constants/paths';
import { urlify } from 'src/helpers/common';
import CUFormPoll from './CUMenuUi/CUFormPoll';
import CUImagePost from './CUMenuUi/CUImagePost';
import CULocationPost from './CUMenuUi/CULocationPost';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

type NamePage = string | false;

const useStyles = makeStyles(() => ({
  rootCenter: {
    width: '100%',
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
    overflow: 'hidden'
  },
  rootCenterBottom: {
    width: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bottom: '1px',
    padding: '12px 16px 0px 16px'
  },
  rootCenterBottomShare: {
    width: '10%',
    position: 'absolute',
    float: 'right',
    top: '0px',
    right: '14px',
    padding: '0px 16px 0px 16px'
  },
  background: {
    height: 40,
    width: 40,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  backgroundPost: {
    height: 26,
    width: 26,
    '&:hover': {
      cursor: 'pointer'
    },
    marginRight: 5,
    borderRadius: 5,
    marginLeft: 1,
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.1)'
  },
  icon: {
    backgroundColor: '#e4e6eb',
    width: 32,
    height: 32,
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconSelected: {
    backgroundColor: '#e4e6eb',
    width: 32,
    height: 32,
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #fff'
  },
  wrapIconNone: {
    width: 32,
    height: 32,
    borderRadius: 6,
    '&:hover': {
      cursor: 'pointer'
    },
    border: '2px solid rgba(0,0,0,0.2)',
    backgroundColor: '#fff'
  },
  textArea: {
    fontWeight: '400',
    fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
    width: '470px',
    wordBreak: 'break-word',
    backgroundColor: 'transparent',
    resize: 'none',
    minHeight: '80px !important',
    marginLeft: '20px'
  },
  CUMenuUi: {
    margin: '0px 16px 0px 16px',
    width: '470px',
    boxSizing: 'border-box'
  },
  CUShareUI: {
    width: '470px',
    boxSizing: 'border-box',
    margin: '0px 10px 0px 10px',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  CUGif: {
    margin: '0px 16px 0px 16px',
    width: '470px',
    boxSizing: 'border-box',
    position: 'relative'
  },
  CUMenuLocation: {
    border: '1px solid rgba(0,0,0,0.2)',
    margin: '0px 16px 0px 16px',
    height: '150px',
    width: '470px',
    boxSizing: 'border-box',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  buttonVote: {
    zIndex: 400,
    width: 28,
    height: 28,
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,0.12)',
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 8,
    right: 8,
    color: '#000'
  },
  wrapAvatar: {
    height: 216,
    width: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100
  },
  wrapItem: {
    display: 'flex',
    alignItems: ' center'
  },
  wrapText: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  subTitle: {
    fontSize: '13px !important',
    color: '#65676b',
    display: 'flex',
    alignItems: 'center'
  },
  boxInput: {
    width: '100%',
    height: '100%',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center'
  }
}));

interface CUCenterTopProps {
  type?: String;
  post?: any;
  files?: any;
  setFiles?: React.Dispatch<React.SetStateAction<any>> | any;
  linkCard?: any;
  setLinkCard?: React.Dispatch<React.SetStateAction<any>> | any;
  openPoll?: boolean | any;
  setOpenPoll?: React.Dispatch<React.SetStateAction<boolean>> | any;
  pollData?: any;
  setPollData?: React.Dispatch<React.SetStateAction<any>> | any;
  gifSelected?: any;
  handleAction?: any;
  setGifSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  handleDeleteKey?: any;
  locationSelected?: any;
  listBackground?: any;
  backgroundSelected?: any;
  setBackgroundSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenBackground?: React.Dispatch<React.SetStateAction<boolean>> | any;
  handleClose?: any;
  textContent?: String | any;
  lifeEvent?: any;
  setLifeEvent?: React.Dispatch<React.SetStateAction<any>> | any;
  setTextContent?: React.Dispatch<React.SetStateAction<string>> | any;
  openUploadMedia?: boolean | any;
  setOpenUploadMedia?: React.Dispatch<React.SetStateAction<boolean>> | any;
  bgAnswerSelected?: any;
  setBgAnswerSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  group?: any;
  page?: any;
  event?: any;
  eventInfo?: any;
  project?: any;
  projectInfo?: any;
  recruit?: any;
  course?: any;
  courseInfo?: any;
  product?: any;
  pageShared?: any;
  groupShared?: any;
  setObjectMentions?: React.Dispatch<React.SetStateAction<any>> | any;
  objectMentions?: any;
  isTypeTarget?: any;
  showBGDemo?: any;
  setShowBGDemo?: React.Dispatch<React.SetStateAction<any>> | any;
  music?: any;
  setMenuSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  setIsTypeTarget?: React.Dispatch<React.SetStateAction<any>> | any;
  sharePostInNewFeed?: any;
  friendShared?: any;
  setOpenEventLife?: React.Dispatch<React.SetStateAction<any>> | any;
  setEventLife?: React.Dispatch<React.SetStateAction<any>> | any;
  setUpdateEvent?: React.Dispatch<React.SetStateAction<any>> | any;
  container?: any;
  setNotificationLeave?: React.Dispatch<React.SetStateAction<any>> | any;
  setListKeyDisabled?: React.Dispatch<React.SetStateAction<any>> | any;
}

const CUCenterTop: React.FC<CUCenterTopProps> = React.memo(props => {
  const classes = useStyles();
  const match = useRouteMatch();
  const {
    type,
    post,
    files,
    setFiles,
    linkCard,
    setLinkCard,
    openPoll,
    setOpenPoll,
    pollData,
    setPollData,
    gifSelected,
    handleAction,
    setGifSelected,
    handleDeleteKey,
    locationSelected,
    listBackground,
    backgroundSelected,
    setBackgroundSelected,
    setOpenBackground,
    handleClose,
    textContent,
    lifeEvent,
    setLifeEvent,
    setTextContent,
    openUploadMedia,
    setOpenUploadMedia,
    bgAnswerSelected,
    setBgAnswerSelected,
    group,
    page,
    event,
    eventInfo,
    project,
    projectInfo,
    recruit,
    course,
    courseInfo,
    product,
    pageShared,
    groupShared,
    setObjectMentions,
    objectMentions,
    isTypeTarget,
    showBGDemo,
    setShowBGDemo,
    music,
    setMenuSelected,
    setIsTypeTarget,
    sharePostInNewFeed,
    friendShared,
    setOpenEventLife,
    setEventLife,
    setUpdateEvent,
    container,
    setNotificationLeave,
    setListKeyDisabled
  } = props;

  const theme: any = useTheme();
  const pageInfo = useSelector((state: any) => state?.pageReducer.info) || {};
  const meInfo = useSelector((state: any) => state?.meReducer?.info) || {};
  const inputRef: any = React.useRef();
  const [showButtonBg, setShowButtonBg] = React.useState(true);
  const [isShowMap, setIsShowMap] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const friends =
    useSelector((state: any) => state.socialFriendsReducer.listFriends) ?? [];
  const [listMentions, setListMentions] = React.useState<any>(
    friends.slice(0, 8)
  );
  const [localContentPost, setLocalContentPost] = React.useState(
    textContent ?? ''
  );
  const [positionCursorContentPost, setPositionCursorContentPost] =
    React.useState<number>(textContent.length ?? 0);
  const inputElement: any = document.getElementById('textInputCreatePost');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseEmoji = () => {
    setAnchorEl(null);
  };

  const handleIconChange = e => {
    setLocalContentPost(pre => pre + `${e}`);
  };
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const getCardMediaLink = async link => {
    let res: any = await getPreviewUrlApi(link);
    if (res.status === 200) {
      setLinkCard([{ ...res.data[0], link: link.urls[0] }]);
    }
  };

  const handleChange: OnChangeHandlerFunc = (event, newValue, displayValue) => {
    const checkShowAlertWarning: any =
      sharePostInNewFeed || pageShared?.id || groupShared?.id;
    setNotificationLeave(
      event.target.value.length > 0 && !checkShowAlertWarning
    );
    setLocalContentPost(newValue);
  };

  const handleFocus = e => {
    const { selectionStart } = e.target;
    if (inputRef.current && post && post.content) {
      inputRef.current.selectionStart = inputRef.current.selectionEnd =
        selectionStart + post.content.length + 1;
    }
  };

  const handlePaste = e => {
    if (Array.from(e.clipboardData.files).length > 0) {
      let filesState = [...files];
      let fileAccepts = Array.from(e.clipboardData.files).map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      let newFiles = filesState.concat(fileAccepts);
      setFiles(newFiles);
      setOpenUploadMedia(true);
    }
    if (e.clipboardData.getData('Text').length) {
      setPositionCursorContentPost(
        positionCursorContentPost + e.clipboardData.getData('Text').length
      );
    }
  };

  const handleKeyDown = e => {
    if (e.key === '@') {
      setListMentions(friends.slice(0, 8));
    }
  };

  const debounceSearchGlobal = React.useCallback(
    _.debounce(async function (q, callback) {
      if (!q) {
        callback(
          friends.map((item: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }))
        );
      }
      let response: any = await searchUserGlobal({
        q,
        limit: 5,
        type: ['accounts', 'pages', 'groups']
      });

      if (response.status === 200) {
        let accounts: any = response.data.accounts.slice(0, 3);
        let pages: any = response.data.pages.slice(0, 3);
        let groups: any = response.data.groups.slice(0, 3);
        let optionNewSearch: any = [...accounts, ...groups, ...pages];

        if (optionNewSearch.length) {
          const results = optionNewSearch.map((item: any, index: any) => ({
            id: item.id,
            display: item?.display_name ?? item?.title,
            avatar_media: item?.avatar_media ?? item?.banner,
            relationships: item
          }));
          setListMentions(optionNewSearch);
          callback(results);
        }
      }
    }, 800),
    []
  );

  const handleActionMention = id => {
    const mentionEndPosition = inputElement.selectionEnd;

    const item = listMentions?.find(item => item.id === id);

    let typeObjectMention = item?.page_relationship
      ? 'Page'
      : item?.group_relationship
      ? 'Group'
      : 'Account';
    let nameObjectMention = item?.display_name ?? item?.title;

    setObjectMentions(prev => [
      ...prev,
      {
        id: item?.id ?? '',
        type: typeObjectMention,
        name: nameObjectMention,
        position: mentionEndPosition
      }
    ]);
    inputRef.current.focus();
  };

  React.useEffect(() => {
    if (locationSelected && Object.keys(locationSelected).length) {
      if (post && post?.media_attachments?.length > 0) {
        setIsShowMap(false);
      } else {
        if (!event && !project && !recruit && !course) setIsShowMap(true);
      }
    }
  }, [JSON.stringify(locationSelected), post?.media_attachments]);

  React.useEffect(() => {
    if (
      post?.share_product_or_course ||
      post?.share_status_account_id ||
      post?.share_event ||
      post?.shared_music ||
      post?.shared_project ||
      post?.shared_recruit ||
      sharePostInNewFeed ||
      gifSelected ||
      openUploadMedia ||
      bgAnswerSelected.isChange ||
      textContent?.length > 151 ||
      linkCard ||
      pollData ||
      lifeEvent ||
      group ||
      page ||
      event ||
      project ||
      music ||
      recruit ||
      course ||
      (post?.reblog &&
        !post?.reblog?.page &&
        !post?.reblog?.group &&
        !post?.reblog?.event &&
        !post?.reblog?.recruit &&
        !post?.reblog?.project)
    ) {
      setShowButtonBg(false);
    } else {
      setShowButtonBg(true);
    }
  }, [
    post,
    openUploadMedia,
    textContent,
    gifSelected,
    isShowMap,
    JSON.stringify(post),
    JSON.stringify(lifeEvent),
    JSON.stringify(pollData),
    JSON.stringify(linkCard),
    JSON.stringify(bgAnswerSelected)
  ]);

  // React.useEffect(() => {
  //   if (inputElement) {
  //     inputElement.style.color = theme.palette.text.primary;
  //     if (backgroundSelected) {
  //       inputElement.style.marginTop = '40px';
  //       inputElement.style.textAlign = 'center';
  //       inputElement.style.color =
  //         backgroundSelected?.style?.fontColor ??
  //         theme.palette.text.customColor;
  //       inputElement.style.fontWeight = '500';
  //     } else {
  //       inputElement.style.textAlign = 'left';
  //     }
  //   }
  // }, [backgroundSelected, inputElement]);

  React.useEffect(() => {
    if (textContent?.length > 130) {
      setBackgroundSelected(null);
      handleDeleteKey('background');
      setListKeyDisabled([]);
      setShowButtonBg(false);
      setShowBGDemo(false);
    }

    if (textContent?.length && urlify(textContent)?.length && !linkCard) {
      let linkFirst = urlify(textContent)[0];
      getCardMediaLink({
        urls: [linkFirst + '']
      });
    }
  }, [textContent]);

  React.useEffect(() => {
    setTextContent(localContentPost);
    setObjectMentions(
      objectMentions.filter(el => el.position < localContentPost.length)
    );
  }, [localContentPost.length]);

  const pageOwnerStatus = (): NamePage => {
    if (courseInfo?.page_owner) return courseInfo?.page_owner?.title;

    if (projectInfo?.page_owner) return projectInfo?.page_owner?.title;

    if (eventInfo?.page_owner) return eventInfo?.page_owner?.title;

    return false;
  };

  const renderPlaceHolder = () => {
    if (pageShared?.title && !groupShared && !friendShared) {
      return `Viết gì đó cho ${pageShared?.title}`;
    } else if (groupShared?.title && !sharePostInNewFeed) {
      return `Viết gì đó cho ${groupShared?.title}`;
    } else if (friendShared?.title) {
      return `Viết gì đó cho ${friendShared?.title}`;
    } else if (
      (post?.page || type === 'page' || type === 'header-post-page') &&
      match.path !== PATHS.WATCH &&
      Object.keys(pageInfo).length &&
      !sharePostInNewFeed
    ) {
      return `Viết gì đó cho ${pageInfo?.title}`;
    } else if (pageInfo && type === 'livestream') {
      return 'Video trực tiếp của bạn nói về điều gì?';
    } else {
      return `${
        rolePage?.page_owner_id && (sharePostInNewFeed || rolePage?.role)
          ? rolePage?.title
          : pageOwnerStatus() || meInfo.display_name.split(' ').pop()
      } ơi, bạn đang nghĩ gì?`;
    }
  };

  return (
    <>
      <Box
        style={{
          backgroundImage: backgroundSelected
            ? `url(${backgroundSelected.url})`
            : 'none',

          height:
            backgroundSelected?.id === 293
              ? 'auto'
              : backgroundSelected
              ? '350px'
              : 'auto'
        }}
        className={classes.rootCenter}
      >
        <div
          className={classes.boxInput}
          style={{ justifyContent: backgroundSelected ? 'center' : 'unset' }}
        >
          <div style={{ position: 'relative', minHeight: '120px' }}>
            <Mentions
              inputRef={inputRef}
              idInput={'textInputCreatePost'}
              name="textInputCreatePost"
              type="post"
              placeholder={renderPlaceHolder()}
              style={{
                width: '440px',
                wordBreak: 'break-word',
                backgroundColor: 'transparent',
                resize: 'none',
                marginLeft: '20px',
                fontWeight: '400',
                fontFamily: 'Segoe UI, Roboto, Helvetica, Arial, sans-serif',
                fontSize: textContent?.length <= 84 ? 24 : 16,
                ...backgroundSelected?.style,
                margin: backgroundSelected ? '0px' : '0px 16px 30px 16px',
                // paddingTop:
                //   backgroundSelected?.id === 293
                //     ? '0px'
                //     : backgroundSelected
                //     ? '40px'
                //     : '0px',
                lineHeight: backgroundSelected
                  ? backgroundSelected.style.fontSize === '35px'
                    ? '50px' // trường hợp fontSize 35px
                    : '55px' // trường hợp fontSize 40px
                  : textContent?.length <= 84
                  ? '40px' // trường hợp fontSize 24px
                  : '30px', // trường hợp fontSize 16px
                textAlign: 'center'
              }}
              styleInput={{
                color: theme.palette.mode === 'light' ? '#000' : '#fff',
                ...backgroundSelected?.style,
                // paddingTop:
                //   backgroundSelected?.id === 293
                //     ? '0px'
                //     : backgroundSelected
                //     ? '40px'
                //     : '0px',
                lineHeight: backgroundSelected
                  ? backgroundSelected.style.fontSize === '35px'
                    ? '50px' // trường hợp fontSize 35px
                    : '55px' // trường hợp fontSize 40px
                  : textContent?.length <= 84
                  ? '40px' // trường hợp fontSize 24px
                  : '30px', // trường hợp fontSize 16px,
                ...(backgroundSelected && {
                  textAlign: 'center',
                  color:
                    backgroundSelected?.style?.fontColor ??
                    theme.palette.text.customColor,
                  minHeight: '120px'
                })
              }}
              styleHighLighter={{
                padding: 0,
                paddingLeft: backgroundSelected ? '9px' : '0px',
                textAlign: backgroundSelected ? 'center' : 'start'
              }}
              localContent={localContentPost}
              handleFocus={handleFocus}
              handleChange={handleChange}
              handlePaste={handlePaste}
              debounceSearchGlobal={debounceSearchGlobal}
              handleActionMention={handleActionMention}
              handleKeyDown={handleKeyDown}
              container={container}
            />

            {!showButtonBg ? (
              <IconButton
                style={{
                  position: 'absolute',
                  bottom: 1,
                  right: 0,
                  padding: '2px'
                }}
                onClick={handleClick}
              >
                <i className="far fa-smile"></i>
              </IconButton>
            ) : null}
          </div>
          {type === 'product' ||
          type === 'update_product' ||
          type === 'header-post' ? null : (
            <div
              className={
                sharePostInNewFeed ||
                pageShared ||
                (post?.post_type === 'moment' && post)
                  ? classes.rootCenterBottomShare
                  : classes.rootCenterBottom
              }
            >
              {showBGDemo ? (
                <>
                  <div
                    onClick={() => setShowBGDemo(false)}
                    className={classes.icon}
                  >
                    <i className="fas fa-angle-left fa-lg"></i>
                  </div>
                  <div
                    onClick={() => {
                      setBackgroundSelected(null);
                      handleDeleteKey('background');
                      setListKeyDisabled([]);
                    }}
                    className={classes.wrapIconNone}
                  ></div>
                  {listBackground.slice(0, 8)?.map((item: any, index) => (
                    <img
                      key={index + ''}
                      onClick={() => {
                        setBackgroundSelected(item);
                        handleAction('background');
                      }}
                      className={
                        backgroundSelected?.id === item.id
                          ? classes.iconSelected
                          : classes.icon
                      }
                      src={item.url}
                      alt={item.name}
                    />
                  ))}
                  <div
                    onClick={() => {
                      setOpenBackground(true);
                    }}
                    className={classes.icon}
                  >
                    <i
                      className="fas fa-th-large fa-lg"
                      style={{ color: '#000' }}
                    ></i>
                  </div>
                </>
              ) : showButtonBg &&
                !sharePostInNewFeed &&
                !pageShared &&
                post?.post_type !== 'moment' &&
                match.path !== PATHS.LIVESTREAM_CREATE ? (
                <>
                  <img
                    onClick={() => {
                      setShowBGDemo(true);
                    }}
                    className={classes.background}
                    src={background}
                    alt="background"
                  />
                </>
              ) : (
                <div></div>
              )}
              {showButtonBg && (
                <IconButton onClick={handleClick}>
                  <i className="far fa-smile" style={{ color: '#65676b' }}></i>
                </IconButton>
              )}
            </div>
          )}
        </div>
        {openPoll ? (
          <div className={classes.CUMenuUi}>
            <CUFormPoll
              type={type}
              post={post}
              pollData={pollData}
              setIsPoll={setOpenPoll}
              setPollData={setPollData}
            />
          </div>
        ) : null}
        {linkCard && !openUploadMedia && !backgroundSelected && (
          <div style={{ position: 'relative' }}>
            <CardPost
              post={{
                content: textContent,
                card: { ...linkCard[0], image: linkCard[0]?.url }
              }}
            />

            <div
              onClick={() => {
                setLinkCard(null);
                setListKeyDisabled([]);
              }}
              className={classes.buttonVote}
            >
              <i className="far fa-times"></i>
            </div>
          </div>
        )}
        {openUploadMedia && (
          <div className={classes.CUMenuUi}>
            <CUImagePost
              post={post}
              files={files}
              setFiles={setFiles}
              handleOther={handleClose}
              handleClose={() => {
                handleDeleteKey('media');
                setOpenUploadMedia(false);
                setListKeyDisabled([]);
              }}
              type={type}
            />
          </div>
        )}

        {isShowMap &&
          !group &&
          !page &&
          !openUploadMedia &&
          !gifSelected &&
          !backgroundSelected &&
          !pollData &&
          !lifeEvent &&
          !music &&
          !product &&
          !post && (
            <div className={classes.CUMenuLocation}>
              <CULocationPost locationSelected={locationSelected} />
              <div
                onClick={() => {
                  setIsShowMap(false);
                  setListKeyDisabled([]);
                }}
                className={classes.buttonVote}
              >
                <i className="far fa-times"></i>
              </div>
            </div>
          )}
        {gifSelected && (
          <div className={classes.CUGif}>
            <img
              style={{
                width: 470,
                borderRadius: 10,
                border: '1px solid rgba(0,0,0,0.12)'
              }}
              alt="gif"
              src={gifSelected}
            />
            <div
              onClick={() => {
                setGifSelected(null);
                setListKeyDisabled([]);
              }}
              className={classes.buttonVote}
            >
              <i className="far fa-times"></i>
            </div>
          </div>
        )}
        {lifeEvent && (
          <div
            style={{
              border: '1px solid rgba(0,0,0,0.12)',
              borderRadius: 10,
              width: 470,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div style={{ position: 'relative', maxHeight: 500 }}>
              <ButtonInherit
                label={'Chỉnh sửa'}
                icon={
                  <i
                    style={{
                      fontSize: 17,
                      color: '#000'
                    }}
                    className="fa-light fa-pen"
                  ></i>
                }
                style={{
                  width: '130px',
                  position: 'absolute',
                  top: '10px',
                  left: '10px',
                  backgroundColor: '#fff',
                  color: '#000',
                  zIndex: 1000
                }}
                action={() => {
                  setOpenEventLife(true);
                  setEventLife(lifeEvent);
                  setUpdateEvent(true);
                }}
              />
              {files.length ? (
                <ImageRender
                  type="create-upload"
                  images={files?.map((el: any) => el.preview)}
                  imagesType={files?.map((el: any) => el.type)}
                  medias={files}
                />
              ) : (
                <video
                  autoPlay={true}
                  muted
                  loop
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px 10px 0px 0px'
                  }}
                  src={files[0]?.preview ?? lifeEvent?.default_media_url}
                />
              )}
            </div>

            <div
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                padding: '0px 8px'
              }}
            >
              <Typography style={{ fontSize: 17, fontWeight: 400 }}>
                {lifeEvent?.name}{' '}
              </Typography>
              {lifeEvent?.place_object ? (
                <>
                  {lifeEvent.place_object.title && (
                    <Typography style={{ fontSize: 17, fontWeight: 400 }}>
                      tại&nbsp;
                      <span style={{ fontWeight: 500 }}>
                        {lifeEvent.place_object.title}
                      </span>
                    </Typography>
                  )}
                  {lifeEvent.place_object.display_name && (
                    <Typography style={{ fontSize: 17, fontWeight: 400 }}>
                      với&nbsp;
                      <span style={{ fontWeight: 500 }}>
                        {lifeEvent.place_object.display_name}
                      </span>
                    </Typography>
                  )}
                </>
              ) : null}
              <Typography style={{ fontSize: 15, color: '#65676b' }}>
                {formatDateTimeToStringDateTime(lifeEvent?.start_date, false)}
              </Typography>
            </div>
            <div
              onClick={() => {
                setLifeEvent(null);
                setEventLife(null);
                setFiles([]);
                setListKeyDisabled([]);
              }}
              className={classes.buttonVote}
            >
              <i className="far fa-times"></i>
            </div>
          </div>
        )}
        {bgAnswerSelected.isChange && (
          <div style={{ position: 'relative', marginBottom: '60px' }}>
            <div
              style={{
                height: 576,
                width: 432,
                background: bgAnswerSelected.backgroundImage,
                borderRadius: 14,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div className={classes.wrapAvatar}>
                {isTypeTarget ? (
                  <AvatarSocial
                    type="feed"
                    src={target_default}
                    style={{ width: 168, height: 168 }}
                  ></AvatarSocial>
                ) : (
                  <AvatarSocial
                    type="feed"
                    avatarObj={meInfo?.avatar_media ?? meInfo?.avatar_static}
                    style={{ width: 168, height: 168 }}
                  ></AvatarSocial>
                )}
              </div>
              <Typography
                textAlign="center"
                style={{
                  fontSize:
                    bgAnswerSelected?.text?.length <= 50
                      ? '30px'
                      : bgAnswerSelected?.text?.length <= 196
                      ? '24px'
                      : '20px',
                  fontWeight: '700',
                  color: 'rgba(255,255,255,0.8)',
                  margin: '16px',
                  position: 'absolute',
                  top: 216,
                  wordBreak: 'break-word'
                }}
              >
                {bgAnswerSelected.text}
              </Typography>
            </div>
            <div
              onClick={() => {
                setMenuSelected([]);
                setBgAnswerSelected(prev => ({
                  ...prev,
                  text: 'Bạn hỏi gì đi...',
                  isChange: false,
                  id: null,
                  hasChanged: true
                }));
                setIsTypeTarget(false);
                setListKeyDisabled([]);
              }}
              className={classes.buttonVote}
            >
              <i className="far fa-times"></i>
            </div>
          </div>
        )}
        {(post &&
          type !== 'update' &&
          type !== 'update_product' &&
          !post?.shared_group &&
          !post?.shared_page &&
          !post?.shared_event &&
          !post?.shared_project &&
          !post?.shared_recruit &&
          !post?.shared_course &&
          !post?.shared_music &&
          !post?.reblog?.shared_group &&
          !post?.reblog?.shared_page &&
          !post?.reblog?.shared_event &&
          !post?.reblog?.shared_project &&
          !post?.reblog?.shared_recruit &&
          !post?.reblog?.shared_course &&
          !post?.reblog?.shared_music &&
          !lifeEvent) || //trong ShareUI cũng có lifeEvent
        post?.reblog ? (
          <div className={classes.CUShareUI}>
            <ShareUI type="share" post={post?.reblog ? post?.reblog : post} />
          </div>
        ) : null}
        {(product && type === 'product') ||
        (type === 'update_product' && post?.share_product_or_course) ? (
          <ShareProductOrCourse
            styleContainerShareProduct={{ width: '90%' }}
            postData={product}
            imageProduct={
              product?.product_image_attachments?.[0].attachment?.preview_url ||
              product?.preview_url
            }
          />
        ) : null}
        {(group ||
          page ||
          event ||
          project ||
          recruit ||
          course ||
          music ||
          (post &&
            (post?.shared_group ||
              post?.shared_page ||
              post?.shared_event ||
              post?.shared_project ||
              post?.shared_recruit ||
              post?.shared_course ||
              post?.shared_music ||
              post?.reblog?.shared_group ||
              post?.reblog?.shared_page ||
              post?.reblog?.shared_event ||
              post?.reblog?.shared_project ||
              post?.reblog?.shared_recruit ||
              post?.reblog?.shared_course ||
              post?.reblog?.shared_music))) &&
          !post?.reblog && (
            <GroupPageShared
              type="create"
              group={group ?? post?.shared_group ?? post?.reblog?.shared_group}
              page={page ?? post?.shared_page ?? post?.reblog?.shared_page}
              event={event ?? post?.shared_event ?? post?.reblog?.shared_event}
              project={
                project ?? post?.shared_project ?? post?.reblog?.shared_project
              }
              music={music ?? post?.shared_music ?? post?.reblog?.shared_music}
              recruit={
                recruit ?? post?.shared_recruit ?? post?.reblog?.shared_recruit
              }
              course={
                course ?? post?.shared_course ?? post?.reblog?.shared_course
              }
            />
          )}
      </Box>

      <Emoji
        popover={true}
        anchorEl={anchorEl}
        handleCloseEmoji={handleCloseEmoji}
        iconSelect={e => {
          handleIconChange(e);
        }}
      />
    </>
  );
});

export default CUCenterTop;
