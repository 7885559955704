import {
  AvatarGroup,
  Badge,
  Box,
  ListItem,
  ListItemButton,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useMemo } from 'react';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { collapseString } from 'src/common/string';
import { handleTimeShow } from 'src/helpers/string';
import { urlRocketChat } from 'src/util/config';
import {
  createDirectMessage,
  favoriteConversation,
  getMessageReadReceipts,
  getSubscriptionRoom,
  getUser,
  handleCleanHistory,
  seenMessage,
  seenMessagePage,
  unSeenMessage,
  unseenMessagePage
} from 'src/apis/socialChat.apis';
import { subUserStatus } from 'src/socket/RocketChatSubscriptions';
import {
  changedPinnedConversation,
  deleteConversation,
  handleReadUnreadMessageSuccess,
  handleReadUnreadPageMessageSuccess,
  removeUserBoxChat,
  selectedUserBoxChat
} from 'src/store/action/socialChatAction';
import Dexie from 'dexie';
import IconButtonOptions from 'src/components/Button/IconButtonOption';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import CustomizedSnackBars from 'src/components/Snackbar/SnackbarSocial';
import { buttonColor, buttonColorHover } from 'src/constants/styles';
import DialogAddLabel from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogAddLabel';
import DialogLeaveGroup from 'src/pages/Chat/BoxChat/DialogFunctionChat/DialogLeaveGroup';
import {
  fetchListAdmin,
  handleLinkToUserPage,
  handleSettingNotification,
  renderAllAvatar,
  renderNameChat
} from 'src/pages/Chat/functionChat';
import { PATHS } from 'src/constants/paths';

import VerifiedIcon from '@mui/icons-material/Verified';

const useStyles = makeStyles((theme: Theme) => ({
  lineClamp: {
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  boxVideo: {
    width: '100% !important',
    height: '30px !important',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 10px !important'
  },
  listVideo: {
    width: '100%',
    height: '100%',
    paddingLeft: '0px !important',
    display: 'flex !important',
    alignItems: 'center !important',
    paddingRight: '8px !important',
    borderRadius: '10px !important'
  },
  iconTime: {
    marginRight: '10px !important'
  }
}));

function Conversation(props) {
  const {
    type,
    conversation,
    handleClose,
    listConversations,
    handleClosePreview,
    pageId,
    setListSearchVideos,
    typeBoxChat,
    setIsHovered,
    styleConversation,
    setListNewMessagePending
  } = props;

  const classes = useStyles();
  const [showAction, setShowAction] = React.useState(false);
  const [hoverAction, setHoverAction] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState<any>({});
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const matches = useMediaQuery('(min-width:924px)');

  const theme = useTheme();
  const match: any = useRouteMatch();
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const isMounted = React.useRef<boolean>(true);
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const meStatus =
    useSelector((state: any) => state.socialChatReducer.me) || {};
  // const [listMemberGrChat, setListMemberGrChat] = React.useState<any>([]);
  const [openDialogDelete, setOpenDialogDelete] =
    React.useState<boolean>(false);

  const [listReadReceiptsLastMessage, setListReadReceiptsLastMessage] =
    React.useState([]);
  const [isToastMessage, setIsToastMessage] = React.useState<boolean>(false);
  const [codeMessage, setCodeMessage] = React.useState<any>(200);
  const [titleToastMessage, setTitleToastMessage] = React.useState<string>(
    'Chúng tôi đã ghi nhận lỗi này. Vui lòng tải lại trang!'
  );
  const [openDialogLeaveGroup, setOpenDialogLeaveGroup] =
    React.useState<boolean>(false);
  const [openDialogAddLabel, setIsOpenDialogAddLabel] =
    React.useState<boolean>(false);
  const [currentRole, setCurrentRole] = React.useState<'member' | 'admin'>(
    'member'
  );

  const [listAdmin, setListAdmin] = React.useState<any>([]);

  const isAdminGroup = useMemo(() => {
    return listAdmin.length > 0 && listAdmin.some(el => el.u._id === meInfo.id);
  }, [listAdmin, meInfo.id]);
  const typeReadUnreadMessage =
    conversation?.t === 'cp'
      ? conversation?.countUnreadPage > 0
        ? 'read'
        : 'make_unread'
      : conversation?.lastMessage?.unread
      ? 'read'
      : 'make_unread';
  const isMessageOfMeInGroupChat =
    conversation.lastMessage?.u?._id === rocketId;

  const handleActionCleanHistory = async () => {
    try {
      const temp_rid = conversation.rid;
      let res = await handleCleanHistory({
        roomId: conversation.rid,
        latest: '9999-12-31T23:59:59.000Z',
        oldest: '0001-01-01T00:00:00.000Z',
        inclusive: false,
        limit: 2000,
        excludePinned: false,
        filesOnly: false,
        ignoreDiscussion: false,
        ignoreThreads: false,
        users: []
      });
      if (res.status === 200) {
        if (match.params.id === conversation.rid) {
          if (listConversations[0].rid === conversation.rid) {
            history.replace(`${listConversations[1].rid}`);
          } else history.replace(`${listConversations[0].rid}`);
        }
        if (query.get('rid') == conversation.rid) {
          const urlWithoutQuery = window.location.pathname;
          history.replace(urlWithoutQuery);
        }
        if (setListNewMessagePending)
          setListNewMessagePending(prev => {
            return prev.filter(el => el.rid !== conversation.rid);
          });
        dispatch(deleteConversation(temp_rid, conversation.t));
        setOpenDialogDelete(false);
        setIsToastMessage(true);
        setTitleToastMessage('Xoá đoạn chat thành công.');
        setCodeMessage(200);
        dispatch(removeUserBoxChat(conversation));
      }
    } catch (error) {
      setOpenDialogDelete(false);
      setIsToastMessage(true);
      setCodeMessage(400);
      setTitleToastMessage('Xoá đoạn chat thất bại.');
    }
  };
  React.useEffect(() => {
    if (
      conversation &&
      conversation.lastMessage?._id &&
      isMessageOfMeInGroupChat &&
      conversation?.lastMessage?.status === undefined &&
      conversation?.rid === match.params.id
    ) {
      getLastMessageReadReceipts(conversation?.lastMessage?._id);
    }
    return () => {
      isMounted.current = false;
    };
  }, [
    conversation?.lastMessage?._id,
    isMessageOfMeInGroupChat,
    match.params.id
  ]);

  React.useEffect(() => {
    if (!showPopper) {
      setHoverAction(false);
      setShowAction(false);
    }
  }, [showPopper]);

  React.useEffect(() => {
    if ((conversation.t === 'p' || conversation.t === 'c') && showPopper) {
      fetchListAdmin(conversation, setCurrentRole, setListAdmin, rocketId);
    }
  }, [showPopper]);
  const getLastMessageReadReceipts = React.useCallback(
    async (messageId: string) => {
      try {
        const response = await getMessageReadReceipts(messageId);

        if (response.status === 200) {
          if (isMounted.current) {
            setListReadReceiptsLastMessage(
              response.data.receipts
                .filter(el => el.user._id !== meInfo.id)
                .slice(0, 4)
                .map(el => {
                  el.avatar_media = {
                    show_url: `${urlRocketChat}/avatar/${el.user.username}`
                  };
                  return el;
                })
            );
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    [conversation._id]
  );
  // React.useEffect(() => {
  //   if (
  //     conversation?.type === 'group' ||
  //     conversation?.t === 'c' ||
  //     conversation?.t === 'p'
  //   ) {
  //     fetchListMember();
  //   }
  //   return () => {
  //     isMounted.current = false;
  //   };
  // }, []);
  React.useEffect(() => {
    fetchUserStatus();

    return () => {
      isMounted.current = false;
    };
  }, []);

  const listActionsConversationGroup = [
    [
      {
        label:
          typeReadUnreadMessage === 'read'
            ? 'Đánh dấu là đã đọc'
            : 'Đánh dấu là chưa đọc',
        id: 'unRead',
        icon: 'fa-light fa-check',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          handleMarkUnread();
        }
      },
      {
        label: conversation.f ? 'Bỏ ghim' : 'Ghim',
        id: 'favorite',
        icon: 'fa-light fa-thumbtack',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          handleTogglePinnedConversation();
        }
      },
      {
        label: conversation.disableNotifications
          ? 'Bật thông báo'
          : 'Tắt thông báo',
        id: 'noti',
        icon: conversation.disableNotifications
          ? 'fa-light fa-bell-slash'
          : 'fa-light fa-bell',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          handleSettingNotification(conversation, setIsToastMessage, rocketId);
        }
      }
    ],
    // [
    //   {
    //     label: 'Gọi thoại',
    //     id: 'call',
    //     icon: 'fa-light fa-phone',
    //     styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
    //     action: () => {
    //       console.log('call');
    //     }
    //   },
    //   {
    //     label: 'Chat video',
    //     id: 'video',
    //     icon: 'fa-light fa-video',
    //     styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
    //     action: () => {
    //       console.log('call video');
    //     }
    //   }
    // ],
    [
      // {
      //   label: 'Lưu trữ đoạn chat',
      //   id: 'save',
      //   icon: 'fa-light fa-box-archive',
      //   styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
      //   action: () => {
      //     console.log('save');
      //   }
      // },
      isAdminGroup
        ? {
            label: 'Xóa đoạn chat',
            id: 'delete',
            icon: 'fa-light fa-trash-can',
            styleIcon: {
              fontWeight: '300',
              fontSize: '20px',
              color: '#767676'
            },
            action: () => {
              setOpenDialogDelete(true);
            }
          }
        : '',
      {
        label: 'Rời khỏi nhóm',
        id: 'out',
        icon: 'fa-light fa-arrow-right-from-bracket',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          setOpenDialogLeaveGroup(true);
        }
      }
    ]
  ];

  const listActionsConversationUser = [
    [
      conversation?.RoomsList?.length > 0
        ? {
            label:
              typeReadUnreadMessage === 'read'
                ? 'Đánh dấu là đã đọc'
                : 'Đánh dấu là chưa đọc',
            id: 'unRead',
            icon: 'fa-light fa-check',
            styleIcon: {
              fontWeight: '300',
              fontSize: '20px',
              color: '#767676'
            },
            action: () => {
              handleMarkUnread();
            }
          }
        : '',
      conversation && conversation.t === 'cp' && match.params?.key === 'chat'
        ? {
            label: 'Thêm nhãn',
            id: 'classify',
            icon: 'fa-light fa-tag',
            styleIcon: {
              fontWeight: '300',
              fontSize: '20px',
              color: '#767676'
            },
            action: () => {
              setIsOpenDialogAddLabel(true);
            }
          }
        : conversation?.RoomsList?.length > 0
        ? {
            label: conversation.f ? 'Bỏ ghim' : 'Ghim',
            id: 'favorite',
            icon: 'fa-light fa-thumbtack',
            styleIcon: {
              fontWeight: '300',
              fontSize: '20px',
              color: '#767676'
            },
            action: () => {
              handleTogglePinnedConversation();
            }
          }
        : '',
      conversation &&
        conversation.t !== 'cp' && {
          label: conversation.disableNotifications
            ? 'Bật thông báo'
            : 'Tắt thông báo',
          id: 'noti',
          icon: conversation.disableNotifications
            ? 'fa-light fa-bell-slash'
            : 'fa-light fa-bell',
          styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
          action: () => {
            handleSettingNotification(
              conversation,
              setIsToastMessage,
              rocketId
            );
          }
        },
      {
        label: 'Xem trang cá nhân',
        id: 'profile',
        icon: 'fa-light fa-id-badge',
        styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
        action: () => {
          handleLinkToUserPage(conversation, history, match);
        }
      }
    ],
    // [
    //   {
    //     label: 'Gọi thoại',
    //     id: 'call',
    //     icon: 'fa-light fa-phone',
    //     styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
    //     action: () => {
    //       console.log('call');
    //     }
    //   },
    //   {
    //     label: 'Chat video',
    //     id: 'video',
    //     icon: 'fa-light fa-video',
    //     styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
    //     action: () => {
    //       console.log('call video');
    //     }
    //   }
    // ],
    [
      // {
      //   label: 'Lưu trữ đoạn chat',
      //   id: 'save',
      //   icon: 'fa-light fa-box-archive',
      //   styleIcon: { fontWeight: '300', fontSize: '20px', color: '#767676' },
      //   action: () => {
      //     console.log('save');
      //   }
      // },
      conversation.t !== 'c' && conversation.t !== 'p'
        ? {
            label: 'Xóa đoạn chat',
            id: 'delete',
            icon: 'fa-light fa-trash-can',
            styleIcon: {
              fontWeight: '300',
              fontSize: '20px',
              color: '#767676'
            },
            action: () => {
              handleActionCleanHistory();
            }
          }
        : null
    ]
  ];
  // render list members seen messages

  const mainTopic = conversation?.topic
    ? conversation?.topic?.colors
    : conversation?.RoomsList?.[0]?.topic?.colors;
  const renderLastMessage = () => {
    if (conversation && !conversation.lastMessage) {
      return 'Chưa có tin nhắn nào.';
    }
    if (
      conversation &&
      conversation.lastMessage &&
      conversation.lastMessage?.reactions &&
      conversation.lastMessage?.u?._id !== meInfo.id
    ) {
      return collapseString('Đã bày tỏ cảm xúc về tin nhắn của bạn.', 20);
    }
    let sender;
    if (conversation?.t === 'c' || conversation?.t === 'p') {
      if (conversation?.lastMessage?.u?._id === rocketId) {
        sender = 'Bạn: ';
      } else {
        sender = `${
          conversation?.lastMessage?.u?.name
            ? conversation?.lastMessage?.u?.name?.split(' ').slice(-1)
            : ''
        }: `;
      }
    } else {
      if (conversation?.lastMessage?.u?._id === rocketId) {
        sender = 'Bạn: ';
      } else {
        sender = `${
          conversation?.lastMessage?.u?.name
            ? conversation?.lastMessage?.u?.name?.split(' ').slice(-1)
            : ''
        }: `;
      }
    }
    let content: any;
    const conditionTypeFile =
      conversation?.lastMessage?.file?.type?.split('/')?.[0];
    if (
      conversation &&
      conversation.lastMessage &&
      conversation.lastMessage.mentions &&
      conversation.lastMessage.mentions.length > 0
    ) {
      const regex = /@\[(\d+)\]\(([^)]+)\)/g;
      const result = conversation?.lastMessage?.msg.replace(regex, '@$2');
      const lastMessageMentions = conversation?.lastMessage?.msg.startsWith(
        `[ ](${urlRocketChat}`
      )
        ? conversation?.lastMessage?.msg.split(') ')[1].trim()
        : result;
      const regexp = /@\[([\w\s]+)\]/gm;
      let textRender;
      if (lastMessageMentions.includes('@[all]')) {
        textRender = '@mọi người';
      } else {
        textRender = lastMessageMentions.replace(regexp, username => {
          const objectMentions = conversation?.members?.find(
            el => `@[${el.username}]` === username
          );
          if (objectMentions) {
            return `@${objectMentions.name}`;
          }
        });
      }

      return collapseString(`${sender} ${textRender}`, 15);
    } else if (
      typeof conversation?.lastMessage?.msg === 'string' &&
      (conversation?.lastMessage?.msg.startsWith(':like_small: ') ||
        conversation?.lastMessage?.msg.startsWith(':like_big: ') ||
        conversation?.lastMessage?.msg.startsWith(':like_largest: '))
    ) {
      content = conversation?.customFields?.icon ? (
        <span style={{ fontSize: 15 }}>{conversation?.customFields?.icon}</span>
      ) : (
        <i
          className="fa-sharp fa-solid fa-thumbs-up"
          style={{
            color: mainTopic ? mainTopic[1] : buttonColor.backgroundColor,
            fontSize: 15
          }}
        ></i>
      );
      return (
        <span>
          <span>{sender}</span> {content}
        </span>
      );
    } else {
      const message = conversation?.lastMessage?.msg;
      const rid = conversation?.lastMessage?.rid;
      if (message !== rid && !conversation?.lastMessage?.file) {
        const type = conversation?.lastMessage?.type;
        if (conversation?.lastMessage.colors) {
          content = collapseString(
            `Đã đổi chủ đề cuộc trò chuyện thành ${message?.title}`,
            16
          );
        } else if (conversation?.lastMessage?.emoticon?.icon) {
          content = collapseString(
            `Đã đặt biểu tượng cảm xúc thành ${message}`,
            18
          );
        } else if (conversation?.lastMessage?.t) {
          if (conversation.lastMessage.t === 'au') {
            content = collapseString(
              `Đã thêm ${
                conversation.lastMessage.u._id === rocketId
                  ? 'bạn'
                  : conversation?.lastMessage?.u?.name
                  ? conversation.lastMessage.u.name.split(' ').slice(-1)
                  : ''
              }`,
              16
            );
          } else if (conversation.lastMessage.t === 'room-removed-read-only') {
            content = collapseString(
              'cho phép tất cả mọi người gửi tin nhắn.',
              18
            );
          } else if (conversation.lastMessage.t === 'room-set-read-only') {
            content = collapseString(
              'chỉ cho phép quản trị viên gửi tin nhắn',
              18
            );
          } else if (
            conversation.lastMessage.t === 'room_changed_privacy' &&
            message === 'public'
          ) {
            content = collapseString(
              'đã tắt tính năng phê duyệt thành viên và bất cứ ai đều có thể thêm người mới vào nhóm',
              18
            );
          } else if (
            conversation.lastMessage.t === 'room_changed_privacy' &&
            message === 'private'
          ) {
            content = collapseString(
              'đã bật tính năng phê duyệt thành viên và bất cứ ai đều có thể thêm người mới vào nhóm',
              18
            );
          } else if (conversation.lastMessage.t === 'room_changed_avatar') {
            content = collapseString('đã đổi ảnh đại diện nhóm', 18);
          } else if (conversation.lastMessage.t === 'ul') {
            content = collapseString(
              `${
                conversation.lastMessage?.u?.name
                  ? conversation.lastMessage.u.name.split(' ').slice(-1)
                  : ''
              } đã rời khỏi nhóm`,
              16
            );
          } else if (conversation.lastMessage.t === 'ru') {
            content = collapseString(
              `${
                conversation.lastMessage?.u?.name
                  ? conversation.lastMessage.u.name.split(' ').slice(-1)
                  : ''
              } đã xoá ${conversation.lastMessage.msg} khỏi nhóm`,
              16
            );
          } else if (conversation.lastMessage.t === 'r') {
            content = collapseString(
              `đã đặt tên nhóm là ${conversation.lastMessage?.msg} `,
              16
            );
          } else if (conversation.lastMessage.t === 'message_pinned') {
            content = collapseString(`${message}`, 16);
          } else if (conversation.lastMessage.t === 'subscription-role-added') {
            content = collapseString(
              `Đã thêm ${
                conversation.lastMessage.u._id === rocketId
                  ? 'bạn'
                  : conversation.lastMessage.u.name.split(' ').slice(-1)
              } làm quản trị viên`,
              16
            );
          } else if (
            conversation.lastMessage.t === 'subscription-role-removed'
          ) {
            content = collapseString(
              `Đã xoá ${
                conversation.lastMessage.u._id === rocketId
                  ? 'bạn'
                  : conversation.lastMessage.u.name.split(' ').slice(-1)
              } làm quản trị viên`,
              16
            );
          }
        } else if (conversation?.lastMessage?.emoticon?.icon === '') {
          content = collapseString(`đã gỡ biểu tượng cảm xúc nhanh`, 16);
        } else if (
          typeof message === 'string' &&
          message?.startsWith(`https://sdk.mojitok.im/`)
        ) {
          content = 'Đã gửi một nhãn dán';
        } else if (
          typeof message === 'string' &&
          message?.startsWith(`https://media`)
        ) {
          content = 'Đã gửi một gif';
        } else if (type === 'forward') {
          content = 'Đã chuyển tiếp một tin nhắn';
        } else if (
          typeof message === 'string' &&
          message?.startsWith(`[ ](${urlRocketChat}`)
        ) {
          const messageReplied = message.split(') ')[1];
          if (
            typeof messageReplied === 'string' &&
            messageReplied.startsWith(`:like_`)
          ) {
            content = (
              <i
                className="fa-sharp fa-solid fa-thumbs-up"
                style={{
                  color: mainTopic ? mainTopic[1] : buttonColor.backgroundColor,
                  fontSize: 15
                }}
              ></i>
            );
            return (
              <span>
                <span>{sender}</span> {content}
              </span>
            );
          } else if (
            typeof messageReplied === 'string' &&
            messageReplied.startsWith(`https://media`)
          ) {
            content = 'Đã gửi một gif';
          } else if (
            typeof messageReplied === 'string' &&
            messageReplied.startsWith(`https://sdk.mojitok.im/`)
          ) {
            content = 'Đã gửi một nhãn dán';
          } else {
            content = collapseString(messageReplied, 15);
          }
        } else {
          content = collapseString(message, 10);
        }
      } else if (conditionTypeFile) {
        switch (conditionTypeFile) {
          case 'image':
            content = 'Đã gửi một ảnh';
            break;
          case 'application':
          case 'text':
            content = 'Đã gửi một file';
            break;
          default:
            content = collapseString(`Đã gửi một ${conditionTypeFile}`, 10);
            break;
        }
      }
      return `${sender} ${content}`;
    }
  };
  const isShowItem =
    matches && (type === 'fullScreen' || 'chatPage')
      ? 'flex'
      : type === undefined || type === 'search'
      ? 'flex'
      : 'none';
  const renderUpdatedAt = () => {
    const timestamp = conversation.lastMessage
      ? conversation.lastMessage._updatedAt?.$date
      : conversation.ls
      ? conversation.ls?.$date
      : conversation._updatedAt?.$date;
    return handleTimeShow(timestamp);
  };

  const handleClick = async () => {
    try {
      handleClosePreview && handleClosePreview();
      if (!hoverAction) {
        if (type !== 'search') {
          if (type === 'fullScreen' || type === 'mess-pending') {
            const isPath =
              window.location.pathname ===
              `/messages/${conversation?.rid || conversation?._id}`;
            if (!isPath) {
              history.push(
                `/messages/${conversation?.rid || conversation?._id}`
              );
            }
            if (conversation?.pending) {
              dispatch(
                selectedUserBoxChat({
                  ...conversation,
                  type: 'user',
                  avatar_media: {
                    show_url:
                      conversation.show_url ||
                      conversation.avatar_media.show_url
                  },
                  displayName: conversation.fname
                })
              );
            }
          } else if (conversation.t === 'cp') {
            const isPath =
              window.location.search === `?rid=${conversation?.rid}`;
            if (match.path === PATHS.MARKETPLACE_SHOP) {
              history.push(
                `/marketplace/shop/chat?page_id=${pageId}&rid=${
                  conversation?.rid || conversation?._id
                }`
              );
            } else if (!isPath) {
              if (type == 'chatPage') {
                history.push(`/page/${pageId}/chat?rid=${conversation?.rid}`);
              } else {
                dispatch(selectedUserBoxChat(conversation));
                handleClose();
              }
            }
          } else {
            if (conversation?.avatar_media) {
              dispatch(selectedUserBoxChat(conversation));
            } else {
              dispatch(
                selectedUserBoxChat({
                  ...conversation,
                  avatar_media: {
                    show_url: `${urlRocketChat}/api/v1/users.getAvatar?userId=${conversation?.lastMessage?.u?._id}`
                  }
                })
              );
            }
            handleClose();
          }
        } else {
          if (['p', 'c'].includes(conversation.t) && type === 'search') {
            dispatch(selectedUserBoxChat(conversation));
            if (type === 'fullScreen') {
              history.push(
                `/messages/${conversation?.rid || conversation._id}`
              );
            }
          } else if (
            conversation &&
            conversation.t === 'cp' &&
            conversation.is_user_chat &&
            type === 'search'
          ) {
            history.push(
              `${history.location.pathname}?rid=${conversation.rid}`
            );
          } else {
            const response = await createDirectMessage(conversation?.username);

            if (response.status === 200) {
              const roomId = JSON.parse(response.data.message)?.result?.rid;

              if (roomId) {
                const res = await getSubscriptionRoom(roomId);
                dispatch(selectedUserBoxChat(res.data.subscription));

                if (typeBoxChat === 'fullScreen') {
                  history.push(`/messages/${roomId}`);
                }
              }
            }
          }

          handleClose && handleClose();
        }
        // handle seen message
        if (conversation.t === 'cp') {
          try {
            if (conversation.countUnreadPage > 0) {
              const response = await seenMessagePage(conversation.rid);
              if (response.status === 200) {
                // handles
                dispatch(
                  handleReadUnreadPageMessageSuccess(
                    conversation.rid,
                    'read',
                    'chatPage'
                  )
                );
              }
            }
          } catch (e) {}
        } else {
          if (
            conversation.unread > 0
            // ||
            // conversation?.lastMessage?.ts['$date'] === conversation?.ls?.$date
          ) {
            const response = await seenMessage(conversation.rid);
            if (response.status === 200) {
              // handles
              dispatch(
                handleReadUnreadMessageSuccess(conversation.rid, 'read')
              );
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleMarkUnread = async () => {
    try {
      let response;
      if (conversation.t === 'cp') {
        if (typeReadUnreadMessage === 'read') {
          response = await seenMessagePage(conversation.rid);
        } else {
          response = await unseenMessagePage(conversation.rid);
        }
      } else {
        if (typeReadUnreadMessage === 'read') {
          response = await seenMessage(conversation.rid);
        } else {
          response = await unSeenMessage(conversation.rid);
        }
      }

      if (response.status === 200) {
        if (conversation.t === 'cp') {
          dispatch(
            handleReadUnreadPageMessageSuccess(
              conversation.rid,
              typeReadUnreadMessage,
              'chatPage'
            )
          );
        } else {
          dispatch(
            handleReadUnreadMessageSuccess(
              conversation.rid,
              typeReadUnreadMessage
            )
          );
        }
        setHoverAction(false);
      }
    } catch (error) {}
  };

  const handleTogglePinnedConversation = async () => {
    try {
      const favorite: boolean = conversation.f ?? false;
      const response = await favoriteConversation(conversation.rid, !favorite);
      if (response.status === 200) {
        //handle
        dispatch(changedPinnedConversation(conversation.rid, !favorite));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchListMember = async () => {
  //   if (conversation && (conversation.t === 'p' || conversation.t === 'c')) {
  //     if (isMounted.current) {
  //       setListMemberGrChat(
  //         conversation.members.filter((member: any) => member._id !== meInfo.id)
  //       );
  //     }
  //   }
  // };

  // const statusMemberGroupChat = listMemberGrChat.map(el => el.status);

  // function checkOnlineMemberGroupChat(memberStatus: string): boolean {
  //   return memberStatus === 'online';
  // }
  const fetchUserStatus = async () => {
    try {
      if (
        type !== 'search' &&
        (conversation?.userId || conversation?.user?._id)
      ) {
        const response = await getUser(
          conversation?.t === 'cp'
            ? conversation?.user?._id
            : conversation?.userId
        );
        if (response.status === 200) {
          if (isMounted.current) {
            setUserStatus(response.data.user);
          }
        }
      }
    } catch (error) {
      console.log('[userStatus]', error);
    }
  };

  const conditionalChatPageRenderLastMessage =
    conversation &&
    conversation.t === 'cp' &&
    conversation.is_user_chat &&
    conversation.countUnreadPage > 0
      ? '#3578E5'
      : theme.palette.mode === 'light'
      ? '#65676b'
      : '#B0B3B8';
  const conditionalRenderLastMessage =
    (conversation &&
      conversation.unread > 0 &&
      conversation.lastMessage &&
      conversation.lastMessage.u &&
      conversation.lastMessage.u?._id !== meInfo?.id) ||
    (conversation?.lastMessage?.ts && conversation.lastMessage?.ts['$date']) ===
      conversation?.ls?.$date;
  const isRenderAvatarGroupChat = conversation?.avatarETag
    ? `${urlRocketChat}/avatar/room/${conversation?.rid}?etag=${conversation?.avatarETag}`
    : `${urlRocketChat}/avatar/room/${conversation?.rid}?etag=${conversation?.RoomsList?.[0]?.avatarETag}`;

  const LabelConversation = props => {
    const { label } = props;

    return (
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          paddingTop: '4px'
        }}
      >
        {label && label.length > 0 && label.length < 4
          ? label.slice(0, 3).map((el: any, index: any) => (
              <Tooltip key={index} title={el.title} arrow>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: '500',
                      padding: '1px 6px',
                      borderRadius: '4px',
                      border: `0.5px solid ${el.color}`,
                      color: `${el.color}`,
                      backgroundColor: `${el.color}20`
                    }}
                  >
                    {collapseString(el.title, 8)}
                  </Typography>
                </Box>
              </Tooltip>
            ))
          : label.slice(0, 2).map((el: any, index: any) => (
              <Tooltip key={index} title={el.title} arrow>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: '500',
                      padding: '1px 6px',
                      borderRadius: '4px',
                      border: `0.5px solid ${el.color}`,
                      color: `${el.color}`,
                      backgroundColor: `${el.color}20`
                    }}
                  >
                    {collapseString(el.title, 8)}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
        {label && label.length > 3 ? (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              padding: '1px 6px',
              backgroundColor: theme.palette.mode === 'light' ? '#ddd' : 'ccc',
              border: `1px solid `,
              borderRadius: '4px'
            }}
          >
            +{label.length - 2} nhãn khác
          </Typography>
        ) : null}
      </Box>
    );
  };

  const renderItem = (link, setState) => {
    return (
      <ListItem sx={{ paddingTop: '0px', paddingLeft: '4px' }}>
        <ListItemButton
          className={classes.listVideo}
          onClick={() => {
            setIsHovered && setIsHovered(false);
            history.push(link);
            setState && setState([]);
          }}
        >
          <Box className={classes.boxVideo}>
            <Box className={classes.iconTime}>
              <i
                className="fa-light fa-magnifying-glass"
                style={{
                  fontSize: '15px',
                  fontWeight: '500',
                  color: '#333333bd',
                  backgroundColor: '#cccccc40',
                  borderRadius: '50%',
                  padding: '10px '
                }}
              ></i>
            </Box>
            <Typography className={classes.lineClamp}>
              {conversation?.display_name ??
                conversation?.title ??
                conversation?.content ??
                conversation?.keyword}
            </Typography>
          </Box>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderBody = type => {
    switch (type) {
      case 'video':
        return renderItem(
          `/watchs/search?q=${conversation.content}&type=live`,
          setListSearchVideos
        );
      case 'moment':
        return renderItem(
          `/moments/search/top?q=${
            conversation?.display_name ??
            conversation?.title ??
            conversation?.content
          }`,
          null
        );
      case 'groups':
        return renderItem(`/groups/search/?q=${conversation.title}`, null);
      default:
        return (
          <ListItem
            disablePadding
            sx={{
              borderRadius: '8px',
              marginTop: '5px',
              padding: 0,
              height: type === 'search' ? '40px' : '72px',
              width: '100%',
              backgroundColor: conversation.focus
                ? theme.palette.mode === 'dark'
                  ? '#343434'
                  : '#f1f2f5'
                : (type === 'fullScreen' &&
                    match.params.id === conversation?.rid) ||
                  (type === 'chatPage' &&
                    query.get('rid') === conversation?.rid)
                ? theme.palette.mode === 'dark'
                  ? '#252f3c'
                  : '#eaf3ff'
                : null,
              ...styleConversation
            }}
            onMouseEnter={(event: any) => {
              event.preventDefault();
              setShowAction(true);
            }}
            onMouseLeave={(event: any) => {
              setShowAction(false);
            }}
            onClick={handleClick}
            secondaryAction={
              <>
                {showAction && type !== 'search' ? (
                  <Box
                    sx={{
                      marginBottom: '40px',
                      display: isShowItem
                    }}
                    onMouseEnter={(event: any) => {
                      event.preventDefault();

                      setHoverAction(true);
                    }}
                    onMouseLeave={() => {
                      setHoverAction(false);
                    }}
                  >
                    <IconButtonOptions
                      icon={
                        <i
                          style={{
                            fontSize: '18px',
                            color:
                              theme.palette.mode === 'dark'
                                ? '#f9f9f9'
                                : '#606770'
                          }}
                          className="fa-solid fa-ellipsis"
                        />
                      }
                      openPopup={true}
                      setShowPopper={setShowPopper}
                      typePopup="popover"
                      options={
                        conversation.t === 'c' || conversation.t === 'p'
                          ? listActionsConversationGroup
                          : listActionsConversationUser
                      }
                      styleListMenu={{ width: '350px' }}
                      horizontalAnchor="right"
                      horizontalTransform="right"
                      style={{
                        width: '35px',
                        height: '35px',
                        marginRight: '5px',
                        position: 'absolute',
                        zIndex: 100,
                        right: '10px',
                        borderRadius: '50%',
                        border:
                          theme.palette.mode === 'dark'
                            ? ''
                            : '1px solid #DADADA',
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#3E4042' : '#fff',
                        boxShadow: ' rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                      }}
                    />
                  </Box>
                ) : null}
              </>
            }
          >
            <ListItemButton
              sx={{
                width: '100%',
                height: '100%',
                paddingLeft: 0,
                display: 'flex',
                alignItems: 'center',
                paddingRight: '8px !important'
              }}
            >
              {/* avatar */}

              <Box
                style={{
                  width: '36px !important',
                  height: '36px !important'
                }}
              >
                {conversation.type === 'group' ||
                conversation.t === 'p' ||
                conversation.t === 'c' ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot"
                    sx={{
                      '& .MuiBadge-badge':
                        // statusMemberGroupChat.some(
                        //   checkOnlineMemberGroupChat
                        // ) ||
                        userStatus?.status === 'online'
                          ? meStatus &&
                            meStatus.status === 'online' && {
                              backgroundColor: '#44b700',
                              color: '#44b700',
                              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                              '&::after': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                animation: 'ripple 1.2s infinite ease-in-out',
                                border: '1px solid currentColor',
                                content: '""'
                              }
                            }
                          : null,
                      '@keyframes ripple': {
                        '0%': {
                          transform: 'scale(.8)',
                          opacity: 1
                        },
                        '100%': {
                          transform: 'scale(2.4)',
                          opacity: 0
                        }
                      }
                    }}
                  >
                    {conversation?.RoomsList?.[0]?.avatarETag ? (
                      <AvatarSocial
                        type="feed"
                        style={{
                          width: type === 'search' ? '36px' : '56px',
                          height: type === 'search' ? '36px' : '56px',
                          margin: '3px 10px 0px 10px',
                          borderRadius: '50%'
                        }}
                        avatarObj={isRenderAvatarGroupChat}
                      />
                    ) : (conversation &&
                        conversation.uids &&
                        conversation?.uids.length === 1) ||
                      (conversation?.members &&
                        conversation?.members.length === 1) ? (
                      <>
                        <Box
                          sx={{
                            position: 'relative',
                            width: type === 'search' ? '56px' : '76px',
                            height: type === 'search' ? '30px' : '59px'
                          }}
                        >
                          <AvatarSocial
                            type="feed"
                            style={{
                              width: type === 'search' ? '28px' : '56px',
                              height: type === 'search' ? '28px' : '56px',
                              zIndex: 1,
                              position: 'absolute',
                              left: '10px',
                              bottom: '0px',
                              borderRadius: '50%'
                            }}
                            isAvatarGroupChat={'yes'}
                            avatarObj={
                              conversation?.avatar_media?.avatar_top
                                ?.show_url ??
                              conversation?.avatar_media?.avatar_top //Tìm kiếm không có show_url
                            }
                          />
                          <AvatarSocial
                            type="feed"
                            style={{
                              width: type === 'search' ? '28px' : '56px',
                              height: type === 'search' ? '28px' : '56px',
                              borderRadius: '50%',
                              position: 'absolute',
                              right: '10px',

                              top: type === 'search' ? '-5px' : '0px',
                              zIndex: 0
                            }}
                            avatarObj={
                              conversation?.avatar_media?.avatar_bottom
                                ?.show_url ??
                              conversation?.avatar_media?.avatar_bottom //Tìm kiếm không có show_url
                            }
                          />
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          position: 'relative',
                          width: type === 'search' ? '56px' : '76px',
                          height: type === 'search' ? '30px' : '59px'
                        }}
                      >
                        <AvatarSocial
                          avatarObj={
                            conversation?.avatar_media?.avatar_top?.show_url ??
                            conversation?.avatar_media?.avatar_top //Tìm kiếm không có show_url
                          }
                          style={{
                            width: type === 'search' ? '28px' : '38px',
                            height: type === 'search' ? '28px' : '38px',
                            zIndex: 1,
                            position: 'absolute',
                            left: '5px',
                            bottom: '0px'
                          }}
                          isAvatarGroupChat={'yes'}
                        />
                        <AvatarSocial
                          type="feed"
                          avatarObj={
                            conversation?.avatar_media?.avatar_bottom
                              ?.show_url ??
                            conversation?.avatar_media?.avatar_bottom //Tìm kiếm không có show_url
                          }
                          style={{
                            width: type === 'search' ? '28px' : '38px',
                            height: type === 'search' ? '28px' : '38px',
                            position: 'absolute',
                            right: '10px',

                            top: type === 'search' ? '-5px' : '0px',
                            zIndex: 0
                          }}
                        />
                      </Box>
                    )}
                  </Badge>
                ) : conversation.type === 'user' ||
                  conversation.t === 'd' ||
                  conversation.t === 'cp' ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot"
                    sx={{
                      '& .MuiBadge-badge':
                        // statusMemberGroupChat.some(
                        //   checkOnlineMemberGroupChat
                        // ) ||
                        userStatus?.status === 'online'
                          ? meStatus &&
                            meStatus.status === 'online' && {
                              backgroundColor: '#44b700',
                              color: '#44b700',
                              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                              '&::after': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                animation: 'ripple 1.2s infinite ease-in-out',
                                border: '1px solid currentColor',
                                content: '""'
                              }
                            }
                          : null,
                      '@keyframes ripple': {
                        '0%': {
                          transform: 'scale(.8)',
                          opacity: 1
                        },
                        '100%': {
                          transform: 'scale(2.4)',
                          opacity: 0
                        }
                      }
                    }}
                  >
                    <AvatarSocial
                      type="feed"
                      style={{
                        width: type === 'search' ? '36px' : '56px',
                        height: type === 'search' ? '36px' : '56px',
                        margin: '3px 10px 0px 10px',
                        borderRadius: '50%'
                      }}
                      avatarObj={renderAllAvatar(conversation, match)}
                    />
                  </Badge>
                ) : (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    variant="dot"
                    sx={{
                      '& .MuiBadge-badge':
                        // statusMemberGroupChat.some(
                        //   checkOnlineMemberGroupChat
                        // ) ||
                        userStatus?.status === 'online'
                          ? meStatus &&
                            meStatus.status === 'online' && {
                              backgroundColor: '#44b700',
                              color: '#44b700',
                              boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                              '&::after': {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                animation: 'ripple 1.2s infinite ease-in-out',
                                border: '1px solid currentColor',
                                content: '""'
                              }
                            }
                          : null,
                      '@keyframes ripple': {
                        '0%': {
                          transform: 'scale(.8)',
                          opacity: 1
                        },
                        '100%': {
                          transform: 'scale(2.4)',
                          opacity: 0
                        }
                      }
                    }}
                  >
                    <AvatarSocial
                      type="feed"
                      style={{
                        width: type === 'search' ? '36px' : '56px',
                        height: type === 'search' ? '36px' : '56px',
                        margin: '3px 10px 0px 10px',
                        borderRadius: '50%'
                      }}
                      avatarObj={conversation}
                    />
                  </Badge>
                )}
              </Box>

              {/* body - name / lastMessage */}
              {/* {console.log('conversation', conversation)} */}
              <Box
                sx={{
                  height: type === 'search' ? '40px' : '56px',

                  width: 'calc(100% - 45px)',
                  display: isShowItem,
                  justifyContent: 'center',
                  flexDirection: 'column',
                  padding: '8.5px 4px'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    margin: type === 'search' ? null : '-5px auto 5px -5px',
                    fontWeight: conversation.unread > 0 ? '700' : '500',
                    paddingTop: '4px'
                  }}
                >
                  {collapseString(renderNameChat(conversation, match), 22)}
                  {/* {conversation.t === 'cp' &&
                  conversation?.identity_verification ? (
                    <VerifiedIcon
                      titleAccess="Emso đã xác nhận tài khoản này đã được xác minh danh tính và có tác động tích cực đến cộng đồng"
                      fontSize="small"
                      viewBox="0 -2 24 24"
                      sx={{
                        color: buttonColor.backgroundColor,
                        fontSize: '14px',
                        '&:hover': {
                          color: buttonColorHover.backgroundColor
                        },
                        marginLeft: '4px',
                        marginRight: '8px'
                      }}
                    />
                  ) : null} */}

                  {conversation.f ? (
                    <i
                      style={{
                        marginLeft: '5px',
                        color: '#FF6969',
                        transform: 'rotate(45deg)'
                      }}
                      className="fa-solid fa-thumbtack"
                    ></i>
                  ) : null}
                  {conversation.disableNotifications ? (
                    <i
                      style={{ marginLeft: '5px' }}
                      className="fa-light fa-bell-slash"
                    ></i>
                  ) : null}
                </Typography>
                {type === 'search' ? null : (
                  <Box
                    sx={{
                      fontSize: '12px',
                      margin: '0px auto 5px -5px',
                      fontFamily: 'inherit',
                      color:
                        conversation.t === 'cp'
                          ? conditionalChatPageRenderLastMessage
                          : conditionalRenderLastMessage
                          ? '#3578E5'
                          : theme.palette.mode === 'light'
                          ? '#65676b'
                          : '#B0B3B8',
                      fontWeight:
                        conditionalChatPageRenderLastMessage ||
                        conditionalRenderLastMessage
                          ? '600'
                          : '400'
                    }}
                  >
                    {conversation && !conversation.description ? (
                      <span>
                        <span>{renderLastMessage()}</span>
                        <span> · </span> {renderUpdatedAt()}
                      </span>
                    ) : (
                      <span>
                        {showAction && type !== 'search' ? null : (
                          <span>
                            <span> · </span>{' '}
                            {moment(conversation._updatedAt).fromNow()}
                          </span>
                        )}
                      </span>
                    )}
                    {conversation && conversation.label ? (
                      <LabelConversation label={conversation.label} />
                    ) : (
                      <Box sx={{ display: 'none' }}></Box>
                    )}
                  </Box>
                )}
              </Box>
              {/* unread / seen */}
              {type === 'search' ? null : (
                <Box
                  sx={{
                    height: '56px',
                    width:
                      listReadReceiptsLastMessage.length > 1 ? '42px' : '14px',
                    display: isShowItem,
                    alignItems: 'center'
                  }}
                >
                  {conversation.type !== 'group' &&
                  conversation.lastMessage?.u?._id === rocketId ? (
                    conversation.lastMessage?.unread ? (
                      <i
                        style={{
                          fontSize: '14px',
                          color: '#bdbdbd'
                        }}
                        className="fa-solid fa-circle-check"
                      ></i>
                    ) : (
                      <AvatarSocial
                        avatarObj={conversation.avatar_media}
                        style={{ width: '14px', height: '14px' }} //lỗi warning
                        type="feed"
                      />
                    )
                  ) : conversation.type === 'group' ? (
                    isMessageOfMeInGroupChat &&
                    listReadReceiptsLastMessage &&
                    listReadReceiptsLastMessage.length ? (
                      <AvatarGroup max={3}>
                        {listReadReceiptsLastMessage
                          .slice(0, 3)
                          .map((el: any, index: number) => (
                            <AvatarSocial
                              key={index}
                              type="feed"
                              avatarObj={el.avatar_media}
                              style={{
                                height: 14,
                                width: 14
                              }}
                              object={el}
                            />
                          ))}
                      </AvatarGroup>
                    ) : !isMessageOfMeInGroupChat ? (
                      <div></div>
                    ) : (
                      <i
                        style={{
                          fontSize: '14px',
                          color: '#bdbdbd'
                        }}
                        className="fa-solid fa-circle-check"
                      ></i>
                    )
                  ) : (
                    <div></div>
                  )}
                </Box>
              )}
            </ListItemButton>
          </ListItem>
        );
    }
  };

  return (
    <>
      {renderBody(type)}
      {openDialogLeaveGroup ? (
        <DialogLeaveGroup
          open={openDialogLeaveGroup}
          setOpen={setOpenDialogLeaveGroup}
          conversation={conversation}
          setIsToastMessage={setIsToastMessage}
          setTitleToastMessage={setTitleToastMessage}
          setCodeMessage={setCodeMessage}
          listAdmin={listAdmin}
        />
      ) : null}
      {openDialogAddLabel ? (
        <DialogAddLabel
          open={openDialogAddLabel}
          setOpen={setIsOpenDialogAddLabel}
          conversation={conversation}
          pageId={pageId}
          setCodeMessage={setCodeMessage}
          setTitleToastMessage={setTitleToastMessage}
          setIsToastMessage={setIsToastMessage}
        />
      ) : null}
      {openDialogDelete && (
        <DialogConfirmDelete
          open={openDialogDelete}
          handleClose={() => {
            setOpenDialogDelete(false);
          }}
          title="Xoá đoạn chat"
          text="Bạn không thể hoàn tác sau khi xoá bản sao của cuộc trò chuyện này."
          // loading={isLoadingFaq}
          action={handleActionCleanHistory}
        />
      )}
      {isToastMessage ? (
        <CustomizedSnackBars
          open={isToastMessage}
          setOpen={setIsToastMessage}
          message={titleToastMessage}
          code={codeMessage}
        />
      ) : null}
    </>
  );
}

export default React.memo(Conversation);
