export const SAVE_CACHE_PAGE = 'SAVE_CACHE_PAGE';
export const UPDATE_CACHE_PAGE = 'UPDATE_CACHE_PAGE';
export const SAVE_CACHE_PAGE_USER = 'SAVE_CACHE_PAGE_USER';
export const TYPE_CACHE = 'TYPE_CACHE';
export const SAVE_INDEX_POST = 'SAVE_INDEX_POST';
export const SAVE_GROUPS_SUGGEST = 'SAVE_GROUPS_SUGGEST';
export const SAVE_FRIENDS_SUGGEST = 'SAVE_FRIENDS_SUGGEST';
export const SAVE_PAGES_SUGGEST = 'SAVE_PAGES_SUGGEST';
export const SAVE_MOMENTS_SUGGEST = 'SAVE_MOMENTS_SUGGEST';
export const SAVE_LIST_FRIEND_REQUEST = 'SAVE_LIST_FRIEND_REQUEST';
export const SAVE_LIST_GROUP = 'SAVE_LIST_GROUP';
export const SAVE_LIST_PAGE = 'SAVE_LIST_PAGE';
export const SAVE_LIST_PAGE_MANAGE = 'SAVE_LIST_PAGE_MANAGE';
export const SAVE_LIST_EVENT = 'SAVE_LIST_EVENT';
export const SAVE_LIST_EVENT_HOST = 'SAVE_LIST_EVENT_HOST';
export const SAVE_LIST_GROUP_CHAT = 'SAVE_LIST_GROUP_CHAT';
export const SAVE_CHECK_ME_ONLINE = 'SAVE_CHECK_ME_ONLINE';
export const RESET_ACTIVITIES_CACHE_USER = 'RESET_ACTIVITIES_CACHE_USER';
export const RESET_ACTIVITIES_CACHE = 'RESET_ACTIVITIES_CACHE';
