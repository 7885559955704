import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Theme,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';

import { isEmpty, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import { getListGroupApi } from 'src/apis/socialGroup';
import { getListPagesApi } from 'src/apis/socialPages.api';
import { searchFriendUser } from 'src/apis/socialUser';
import CUPost from 'src/components/CUPost';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { modalBackdrop } from 'src/constants/styles';
import offersBackground from 'src/assets/images/offers_background.png';
import InputSearch from '../../Input/SocialInput/InputSearch';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      infoSharer: {
        margin: '0px 0px 5px 0px',
        width: '100%',
        height: '100%',
        borderRadius: '8px',
        paddingTop: '8px',
        border: theme.palette.border.solid
      },
      listGroup: {
        width: '100%',
        margin: '20px 0px 10px 0px',
        maxHeight: '300px',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '10px'
        },
        '&::-webkit-scrollbar-track': {
          background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
        },
        '&::-webkit-scrollbar-thumb': {
          background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
          borderRadius: '100px'
        }
      },
      itemGroup: {
        marginTop: 0,
        paddingTop: 0,
        borderRadius: '8px',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.button.primary.hover
        }
      }
    }),
  { index: 1 }
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DialogShareToGroup(props) {
  const {
    open,
    handleClose,
    eventShared,
    shareTo,
    post,
    pageInfo,
    projectShared,
    recruitShared,
    courseShared,
    productShared,
    setOpenAl,
    placeCreatePost,
    increment_reblog_count,
    isSharePost
  } = props;
  const classes = useStyles();
  const [listData, setListData] = React.useState<any>([]);
  const [itemSelected, setItemSelected] = React.useState<any>({});
  const [openDialogShare, setOpenDialogShare] = React.useState<boolean>(false);
  const [keyword, setKeyword] = React.useState('');
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState<any>(1);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [noData, setNoData] = React.useState<any>(false);
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const group =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};

  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );

  const handleSearch = (searchKeyword: string = '') => {
    setKeyword(searchKeyword);

    setPage(1);
    setHasMore(true);
    scrollToTop();
    debounceSearch(searchKeyword);
  };

  const debounceSearch = React.useCallback(
    _.debounce(async function (q) {
      if (isEmpty(q)) {
        fetchPostWait();
      } else {
        if (shareTo === 'friend') {
          let response: any = await searchFriendUser(meInfo?.id, {
            keyword: q,
            limit: 5
          });
          if (response.status === 200) {
            let newDataSearch: any =
              response.data?.data?.map((el: any) => ({
                id: el.id,
                title: el.display_name,
                avatar_icon: el?.avatar_media
                  ? el.avatar_media.show_url ||
                    el.avatar_media.preview_url ||
                    el.avatar_media.url
                  : el?.avatar_media,
                button: {
                  label: 'Mời',
                  style: { width: '50px', minWidth: '50px ' }
                },
                styleAvatar: { height: 40, width: 40 },
                styleImgAvatar: { height: 39, width: 39 }
              })) || [];

            setNoData(response.data.data.length === 0);
            if (response.data.data.length < 5) {
              setHasMore(false);
            }
            setListData(newDataSearch);
          } else setHasMore(false);
        } else if (shareTo === 'group') {
          const response = await getListGroupApi(
            !q
              ? {
                  tab: 'join',
                  limit: 5,
                  with_create_post: true,
                  page: page
                }
              : {
                  tab: 'join',
                  keyword: q,
                  limit: 5,
                  with_create_post: true,
                  page: page
                }
          );

          if (response.status === 200) {
            setIsLoading(false);
            setNoData(response.data.length === 0);
            if (response.data.data.length < 5) {
              setHasMore(false);
            }
            setListData(preValue => {
              if (page == 1) {
                return response.data.data;
              } else {
                return [...preValue, ...response.data.data];
              }
            });
          } else setHasMore(false);
        } else if (shareTo === 'page') {
          let response = await getListPagesApi({
            keyword: q,
            limit: 5,
            status: 'active'
          });
          if (response.status === 200) {
            let pageAdmin: any = response.data.map((el: any) => ({
              id: el.id,
              title: el.title,
              avatar_icon: el?.avatar_media
                ? el.avatar_media.show_url
                : el?.avatar_media,
              button: {
                label: 'Mời',
                style: { width: '50px', minWidth: '50px ' }
              },
              styleAvatar: { height: 40, width: 40 },
              styleImgAvatar: { height: 39, width: 39 }
            }));
            setNoData(response.data.length === 0);
            if (response.data.length < 5) {
              setHasMore(false);
            }
            setListData(pageAdmin);
          } else setHasMore(false);
        }
      }
    }, 300),
    []
  );

  const fetchDataGroup = async (keyword: any, page: any) => {
    let response = await getListGroupApi(
      keyword === ''
        ? {
            tab: 'join',
            limit: 5,
            with_create_post: true,
            page: page
          }
        : {
            tab: 'join',
            keyword: keyword,
            limit: 5,
            with_create_post: true,
            page: page
          }
    );
    if (response.status === 200) {
      setHasMore(response?.data?.data.length >= 5);
      setListData(prevListData => [
        ...prevListData,
        ...uniqBy([...response?.data?.data], 'id').filter(
          el => el.id !== group.id
        )
      ]);
      setIsLoading(false);
    } else {
      setHasMore(false);
      setIsLoading(false);
    }
  };

  async function fetchPostWait(maxId = null) {
    if (shareTo === 'friend') {
      let response = await searchFriendUser(meInfo?.id, {
        limit: 10,
        max_id: maxId
      });
      if (response.status === 200) {
        setIsLoading(false);
        let newDataSearch: any =
          response.data?.data?.map((el: any) => ({
            id: el.id,
            title: el.display_name,
            avatar_icon: el?.avatar_media
              ? el.avatar_media.show_url ||
                el.avatar_media.preview_url ||
                el.avatar_media.url
              : el?.avatar_media,
            styleAvatar: { height: 40, width: 40 },
            styleImgAvatar: { height: 39, width: 39 }
          })) || [];
        setListData(prev => _.uniqBy([...prev, ...newDataSearch], 'id'));
        setListData(prev =>
          maxId ? _.uniqBy([...prev, ...newDataSearch], 'id') : newDataSearch
        );
        if (response.data?.data.length < 10) {
          setHasMore(false);
          setNoData(response.data.data.length === 0 && listData.length === 0);
        } else {
          setNoData(false);
          setHasMore(true);
        }
      }
    } else if (shareTo === 'page') {
      let response = await getListPagesApi({
        limit: 10,
        page: page,
        status: 'active'
      });
      setPage(prev => prev + 1);
      if (response.status === 200) {
        setIsLoading(false);
        let pageAdmin: any = response.data.map((el: any) => ({
          id: el.id,
          title: el.title,
          avatar_icon: el?.avatar_media
            ? el.avatar_media.show_url
            : el?.avatar_media,
          button: {
            label: 'Mời',
            style: { width: '50px', minWidth: '50px ' }
          },
          styleAvatar: { height: 40, width: 40 },
          styleImgAvatar: { height: 39, width: 39 }
        }));
        setListData(prev => _.uniqBy([...prev, ...pageAdmin], 'id'));
        if (response.data.length < 10) {
          setHasMore(false);
          setNoData(response.data.length === 0 && listData.length === 0);
        }
      }
    } else if (shareTo === 'group') {
      fetchDataGroup('', page);
      setPage(prev => prev + 1);
    }
  }

  const funcLoad = () => {
    if (listData.length && shareTo === 'group') {
      fetchDataGroup(keyword, page + 1);
    } else if (shareTo === 'friend' || shareTo === 'page') {
      let mediaLast = [...listData].pop();
      let maxId = mediaLast?.id;

      if (listData.length) {
        fetchPostWait(maxId);
      }
    }
  };

  React.useEffect(() => {
    fetchPostWait();
  }, []);

  const renderItem = (item: any) => {
    return (
      <ListItem
        key={item.id}
        className={classes.itemGroup}
        onClick={() => {
          setItemSelected(item);
          setOpenDialogShare(true);
        }}
      >
        <ListItemAvatar>
          <Avatar
            style={{
              width: 40,
              height: 40
            }}
            src={shareTo === 'group' ? item?.banner?.url : item.avatar_icon}
            variant={shareTo === 'group' ? 'square' : 'circular'}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              fontSize="15px"
              fontWeight="500"
              sx={{
                wordBreak: 'break-word',
                overflow: 'hidden',
                WebkitLineClamp: 1,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                paddingRight: 3
              }}
            >
              {item.title}
            </Typography>
          }
          secondary={
            <Typography fontSize="12px" fontWeight="400">
              {shareTo === 'group'
                ? item.is_private
                  ? 'Nhóm riêng tư'
                  : 'Nhóm công khai'
                : null}
            </Typography>
          }
        />
        <i
          style={{ fontWeight: 200, fontSize: 20 }}
          className="fa-solid fa-chevron-right"
        ></i>
      </ListItem>
    );
  };

  const renderTitles = () => {
    let message = '';
    if (shareTo === 'group') {
      message = 'Tất cả các nhóm';
    } else if (shareTo === 'friend') {
      message = 'Tất cả bạn bè';
    } else if (shareTo === 'page') {
      message = 'Tất cả các trang';
    } else {
      return;
    }
    return message;
  };

  const renderSkeleton = () => {
    return (
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <List>
          {Array.from({ length: 5 }).map((_, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Skeleton variant="circle" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Skeleton variant="text" width={120} height={20} />}
                secondary={<Skeleton variant="text" width={80} height={15} />}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  };
  const scrollableDivRef = React.useRef(null);
  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        BackdropProps={modalBackdrop}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography
            style={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}
          >
            Chia sẻ lên{' '}
            {shareTo === 'group'
              ? 'nhóm'
              : shareTo === 'friend'
              ? 'trang cá nhân của bạn bè'
              : shareTo === 'page'
              ? 'Trang'
              : null}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* render info sharer */}
          {shareTo !== 'page' && (
            <Box className={classes.infoSharer}>
              <ListItem sx={{ marginTop: 0, paddingTop: 0 }}>
                <ListItemAvatar>
                  <AvatarSocial
                    type="feed"
                    style={{
                      width: 40,
                      height: 40
                    }}
                    avatarObj={
                      ['admin', 'owner'].includes(rolePage?.role)
                        ? rolePage?.avatar_media
                        : meInfo.avatar_media ?? meInfo?.avatar_static
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography fontSize="12px" fontWeight="400">
                      Chia sẻ với tư cách
                    </Typography>
                  }
                  secondary={
                    <Typography fontSize="15px" fontWeight="500">
                      {rolePage?.role === 'admin'
                        ? rolePage?.title
                        : meInfo.display_name}
                    </Typography>
                  }
                />
              </ListItem>
            </Box>
          )}
          {/* render box search group */}
          <div></div>
          {/* render list group */}
          <InputSearch
            label="Tìm kiếm trong"
            keyword={keyword}
            setKeyword={handleSearch}
          />
          {noData ? (
            <Box
              sx={{
                backgroundColor: 'background.primary',
                borderRadius: 10,
                width: '100%',
                padding: '10px',
                margin: '20px 0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div style={{ marginRight: '20px' }}>
                <Typography style={{ fontSize: 20 }}>
                  <b>{'Không còn dữ liệu nào'}</b>
                </Typography>
              </div>
              <img
                alt=""
                src={offersBackground}
                style={{ width: '50px' }}
              ></img>
            </Box>
          ) : (
            <div
              className={classes.listGroup}
              id="scrollableShareDiv"
              ref={scrollableDivRef}
            >
              <InfiniteScroll
                dataLength={listData.length}
                next={funcLoad}
                hasMore={hasMore}
                loader={renderSkeleton()}
                style={{ overflow: 'hidden' }}
                scrollableTarget="scrollableShareDiv"
                scrollThreshold={0.9}
              >
                <Typography
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    textAlign: 'start'
                  }}
                >
                  {renderTitles()}
                </Typography>
                {isLoading ? (
                  renderSkeleton()
                ) : (
                  <List>{listData.map((item, index) => renderItem(item))}</List>
                )}
              </InfiniteScroll>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
      {/* )} */}

      <CUPost
        post={post}
        share={true}
        type={productShared ? 'product' : 'header-post'}
        group={
          eventShared || projectShared || recruitShared || courseShared
            ? null
            : group?.id
            ? group
            : null
        }
        placeCreatePost={placeCreatePost}
        event={eventShared ?? null}
        project={projectShared}
        recruit={recruitShared}
        course={courseShared}
        groupShared={shareTo === 'group' ? itemSelected : null}
        product={productShared}
        friendShared={shareTo === 'friend' ? itemSelected : null}
        pageShared={
          pageInfo && shareTo !== 'page'
            ? pageInfo
            : shareTo === 'page'
            ? itemSelected
            : null
        }
        openCUPost={openDialogShare}
        setOpenCUPost={setOpenDialogShare}
        handleFinal={handleClose}
        pageInfo={pageInfo}
        shareTo={shareTo}
        setOpenAl={setOpenAl}
        increment_reblog_count={increment_reblog_count}
        isSharePost={isSharePost}
      />
    </div>
  );
}
