import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  addMusicPlaylist,
  addMusicToAlbum,
  deleteMusic,
  getCategoriesMusic,
  getListReportMusic,
  getPlaylistApi,
  removeAlbumApi
} from 'src/apis/SocialMusic.api';
import { createUpdatePostApi } from 'src/apis/socialPost.api';
import CUPost from 'src/components/CUPost';
import DialogConfirmDelete from 'src/components/Dialog/DialogConfirmDelete';
import DialogReport from 'src/components/Dialog/DialogReport';
import { PATHS } from 'src/constants/paths';
import { buttonColor } from 'src/constants/styles';
import { getDataPlaylist } from 'src/store/action/socialMusicAction';
import CreateAlbum from '../MusicSpaceHome/CreateAlbum';
import DialogAddPlaylist from './DialogAddPlaylist';
import { debounce, isEmpty } from 'lodash';

const useStyles = makeStyles((theme: any) => ({
  searchInput: {
    border: '1px solid #4B4B4B',
    borderRadius: '18px',
    background: theme.palette.mode === 'dark' ? '#4B4B4B' : '#fff',
    width: '300px',
    margin: '10px 0px'
  },
  boxNested: {
    '& .MuiMenuItem-root': {
      backgroundColor: 'unset !important'
    }
  },
  input: {
    outline: 'none',
    width: '100%',
    background: '#transparent',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000000'
  },
  textScroll: {
    display: 'inline-block',
    overflow: 'hidden',
    animation: '$scrollText 10s linear infinite',
    wordBreak: 'break-word'
  },
  scrollingText: {
    animation: '$scrollText 10s linear infinite',
    animationFillMode: 'forwards'
  },
  '@keyframes scrollText': {
    '0%': {
      // Giá trị ban đầu
      transform: 'translateX(100%)'
    },
    '100%': {
      // Giá trị cuối cùng
      transform: 'translateX(-100%)'
    }
  },
  avatarItem: {
    marginTop: '0px !important'
  },
  optionMenu: {
    '& .MuiMenuItem-root': {
      padding: '3px 16px !important'
    }
  },
  imageMusic: {
    width: '50px',
    height: '50px',
    borderRadius: '4px',
    objectFit: 'cover'
  }
}));

interface Props {
  setAnchorEl?: React.Dispatch<React.SetStateAction<any>> | any;
  anchorEl?: boolean | any;
  open?: boolean | any;
  idMusic?: String;
  type?: String;
  dataMusic?: any;
  setNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenSnackbar?: React.Dispatch<React.SetStateAction<boolean>> | any;
  dataAlbum?: any;
  setNewDataAfterDelete?: React.Dispatch<React.SetStateAction<any>> | any;
  handleRemoveMusicInPlaylist?: any;
  isShowRemoveMusicInPlaylist?: boolean;
}

export default function MenuOption(props: Props) {
  const {
    setAnchorEl,
    anchorEl,
    open,
    idMusic,
    type,
    dataMusic,
    setNoti,
    setOpenSnackbar,
    dataAlbum,
    setNewDataAfterDelete,
    handleRemoveMusicInPlaylist,
    isShowRemoveMusicInPlaylist
  } = props;
  const [openDialog, setOpenDialog] = React.useState<any>(false);
  const [openDialogCreate, setOpenDialogCreate] = React.useState<any>(false);
  const [listCategory, setListCategory] = React.useState<any>([]);
  const [openDialogDelete, setOpenDialogDelete] = useState<any>(false);
  const [openDialogDeleteMusic, setOpenDialogDeleteMusic] =
    useState<any>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<any>(false);
  const [dataPlaylistInitial, setDataPlaylistInitial] = useState<any>([]);
  const [dataPlaylist, setDataPlaylist] = useState<any>([]);
  const [openCUPostToFeed, setOpenCUPostToFeed] = useState<any>(false);
  const [openDialogReport, setOpenDialogReport] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const match = useRouteMatch();
  const meInfo = useSelector((state: any) => state.meReducer.info) || {};
  const [anchorPlaylist, setAnchorPlaylist] = useState(null);
  const openPlayList = Boolean(anchorPlaylist);

  // useEffect(() => {
  //   // Đăng ký sự kiện scroll khi component được render
  //   window.addEventListener('scroll', handleClose);

  //   // Hủy đăng ký sự kiện khi component bị unmount
  //   return () => {
  //     window.removeEventListener('scroll', handleClose);
  //   };
  // }, []);

  const handleClickPlaylist = (e: any) => setAnchorPlaylist(e.currentTarget);

  const handleSearchPlaylist = debounce(searchKeyword => {
    if (isEmpty(searchKeyword)) {
      setDataPlaylist(dataPlaylistInitial);
    } else {
      const playlistFiltered = dataPlaylist.filter(item =>
        item.name.toLowerCase().includes(searchKeyword.trim().toLowerCase())
      );
      setDataPlaylist(playlistFiltered);
    }
  }, 300);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const uniqueId = Date.now();
  const isPatchTrack = history.location.pathname.includes('/music_space/track');
  const showDataPlaylist = useSelector(
    (state: any) => state.musicReducer.dataDetailPlaylist
  );
  const [listReport, setListReport] = useState([]);

  const fetchListReportMusic = async () => {
    const response: any = await getListReportMusic();
    setListReport(
      response?.data?.map(item => {
        return { ...item, label: item.text };
      })
    );
  };

  useEffect(() => {
    fetchListReportMusic();
  }, []);

  const handleShareNow = async (id: any) => {
    try {
      let dataShareGrow = {
        visibility: 'public',
        extra_body: {},

        poll: null,
        mention_ids: null,
        reblog_of_id: null,
        post_type: null,
        status: '',
        shared_music_id: id
      };
      const response = await createUpdatePostApi(dataShareGrow, null);
      if (response.status === 200) {
        handleClose();
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Chia sẻ bài hát thành công'
        });
      }
    } catch (error) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Chia sẻ bài hát thất bại!'
      });
    }
  };
  const getPlaylist = async () => {
    try {
      let res = await getPlaylistApi({ limit: 10 });
      if (res.status === 200) {
        setDataPlaylist(res.data);
        setDataPlaylistInitial(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteAlbum = async () => {
    setIsLoadingDelete(true);
    try {
      let res = await removeAlbumApi(dataAlbum.id);
      if (res.status === 200) {
        setOpenDialogDelete(false);
        setIsLoadingDelete(false);
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Xoá album thành công'
        });

        history.go(0);
      }
    } catch (error) {
      setIsLoadingDelete(false);
      setOpenDialogDelete(false);
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Xoá album thất bại'
      });
    }
  };
  const getCategoriesMusicSpace = async () => {
    try {
      let res = await getCategoriesMusic();
      if (res.status === 200) {
        setListCategory(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    getCategoriesMusicSpace();
    if (type !== 'Album') {
      getPlaylist();
    }
  }, [type]);
  const handleAddMusicToPlaylist = async (data: any) => {
    try {
      let res = await addMusicPlaylist(data, idMusic);
      if (res.status === 200) {
        handleClose();
        setOpenSnackbar && setOpenSnackbar(true);
        setNoti &&
          setNoti({
            code: 200,
            message: 'Đã thêm bài hát vào danh sách phát'
          });

        if (type === 'musicPlaylistSuggest') {
          if (!isPatchTrack) {
            dispatch(
              getDataPlaylist([
                ...showDataPlaylist,
                {
                  id: uniqueId,
                  music: dataMusic,
                  position: 0
                }
              ])
            );
          }
        }
      }
    } catch (error) {
      handleClose();
      setOpenSnackbar && setOpenSnackbar(true);
      setNoti &&
        setNoti({
          code: 400,
          message: 'Thêm bài hát vào danh sách phát thất bại!'
        });
    }
  };

  const handleDeleteMusic = async () => {
    try {
      let res = await deleteMusic({ music_ids: dataMusic.id });
      if (res.status === 200) {
        setIsLoadingDelete(false);
        setOpenDialogDeleteMusic(false);
        setOpenSnackbar(true);
        setNewDataAfterDelete &&
          setNewDataAfterDelete(prev =>
            prev.filter((el: any) => el.id !== dataMusic.id)
          );
        if (history.location.pathname.includes('music_space/track')) {
          history.push('/music_space/search_music');
        }
        setAnchorEl(null);
        setNoti({
          code: 200,
          message: 'Xoá bài hát thành công.'
        });
      }
    } catch (error) {
      setOpenSnackbar(true);
      setNoti({
        code: 400,
        message: 'Xoá bài hát thất bại!.Vui lòng thử lại sau!'
      });
      setIsLoadingDelete(false);
      console.log(error);
    }
  };

  const handleAddMusicToAlbum = async (id: any) => {
    try {
      let res = await addMusicToAlbum(id, {
        music_ids: [idMusic]
      });
      if (res.status === 200) {
        setOpenSnackbar(true);
        setNoti({ code: 200, message: 'Thêm bài hát vào Album thành công' });
      }
    } catch (error) {
      setOpenSnackbar(true);
      setNoti({ code: 400, message: 'Thêm bài hát vào Album thất bại' });
    }
  };

  const listMenuOptions = [
    {
      id: 1,
      title: 'Chia sẻ ngay',
      icon: <i className="fa-solid fa-share fa-lg"></i>,
      actionMenu: () => {
        handleShareNow(idMusic);
      },
      visible: dataMusic?.status === 'approved' ? 'show' : 'hide'
    },
    {
      id: 2,
      title: 'Chia sẻ lên bảng tin',
      icon: <i className="fa-sharp fa-regular fa-pen-to-square fa-lg"></i>,
      actionMenu: () => {
        setOpenCUPostToFeed(true);
      },
      visible: dataMusic?.status === 'approved' ? 'show' : 'hide'
    },
    {
      id: 3,
      title: 'Sao chép link',
      icon: <i className="fa-solid fa-link fa-lg"></i>,
      actionMenu: () => {
        handleClose();
      },
      actionOption: e => {
        e.stopPropagation();
        let originLocation = window.location.origin;
        navigator.clipboard.writeText(
          `${originLocation}/music_space/track/${dataMusic.id}`
        );
        handleClose();
        setOpenSnackbar(true);
        setNoti({
          code: 200,
          message: 'Sao chép thành công'
        });
      },
      visible: dataMusic?.status === 'approved' ? 'show' : 'hide'
    },
    {
      id: 4,
      title: 'Báo cáo',
      icon: <i className="fa-regular fa-arrow-up-right-from-square fa-lg"></i>,
      actionMenu: () => {
        setOpenDialogReport(true);
      },
      visible:
        match.path !== PATHS.MUSICSPACE_UPLOADED &&
        ![dataMusic?.account?.id, dataMusic?.account_approve?.id].includes(
          meInfo?.id
        )
          ? 'show'
          : 'hide'
    },
    {
      id: 5,
      title: 'Xóa bài hát',
      icon: <i className="fa-light fa-trash fa-lg"></i>,
      actionMenu: () => {
        handleClose();
      },
      actionOption: e => {
        e.stopPropagation();
        setOpenDialogDeleteMusic(true);
      },
      visible:
        (['admin', 'owner'].includes(dataMusic?.page_owner?.page_relationship?.role) &&
          dataMusic?.status !== 'approved' &&
          !history.location.pathname.includes('music_space/search_music')) ||
        (dataMusic?.status !== 'approved' &&
          PATHS.MUSICSPACE_UPLOADED === match.path)
          ? 'show'
          : 'hide'
    },
    {
      id: 6,
      title: 'Gỡ khỏi danh sách phát này',
      icon: <i className="fa-light fa-trash fa-lg"></i>,
      actionMenu: () => {
        handleRemoveMusicInPlaylist();
        handleClose();
      },
      visible: isShowRemoveMusicInPlaylist ? 'show' : 'hide'
    }
  ];

  return (
    <Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {type === 'Album' ? (
          <Box sx={{ maxWidth: '330px' }}>
            <MenuItem
              onClick={event => {
                setOpenDialogCreate(true);
              }}
            >
              <Box>
                <i className="fa-solid fa-pen"></i>

                <Typography
                  variant="subtitle1"
                  component={'span'}
                  sx={{ paddingLeft: '8px' }}
                >
                  Chỉnh sửa Album
                </Typography>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDialogDelete(true);
              }}
            >
              <Box>
                <i className="fa-solid fa-trash"></i>

                <Typography
                  variant="subtitle1"
                  component={'span'}
                  sx={{ paddingLeft: '8px' }}
                >
                  Xoá Album
                </Typography>
              </Box>
            </MenuItem>
          </Box>
        ) : (
          <Box sx={{ maxWidth: '330px' }} className={classes.optionMenu}>
            <MenuItem
              onClick={e => {
                e.stopPropagation();
                handleClose();
              }}
            >
              <ListItem alignItems="flex-start">
                <ListItemAvatar className={classes.avatarItem}>
                  <img
                    alt="Remy Sharp"
                    src={dataMusic?.banner?.preview_url}
                    className={classes.imageMusic}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ overflow: 'hidden' }}
                  primary={
                    <Box
                      sx={{ width: 'fit-content' }}
                      onClick={event => {
                        event.stopPropagation();
                        history.push(`/music_space/track/${dataMusic.id}`);
                      }}
                    >
                      <Typography
                        className={`${classes.textScroll} ${classes.scrollingText}`}
                        component="span"
                      >
                        {dataMusic?.name}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <React.Fragment>
                      <Box
                        onClick={event => {
                          event.stopPropagation();
                          history.push(
                            `/page/${dataMusic?.page_owner?.id}/music`
                          );
                        }}
                      >
                        <Typography
                          sx={{
                            width: '100%',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            '&:hover': {
                              textDecoration: 'underline',
                              color: buttonColor.backgroundColor
                            }
                          }}
                          component="span"
                          variant="body2"
                          onClick={e => {
                            e.stopPropagation();
                            history.push(
                              `/music_space/detail_artist/${dataMusic?.page_owner?.id}/music`
                            );
                          }}
                        >
                          {dataMusic?.page_owner?.title}
                        </Typography>
                      </Box>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </MenuItem>
            {dataMusic.status === 'approved' && (
              <MenuItem
                onClick={e => {
                  e.stopPropagation();
                  handleClickPlaylist(e);
                }}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{
                      ':hover': {
                        backgroundColor: 'unset'
                      }
                    }}
                  >
                    <ListItemIcon>
                      <AddCircleOutlineIcon
                        sx={{ color: '#757575', marginLeft: '-3px' }}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Thêm vào danh sách phát" />
                  </ListItemButton>
                </ListItem>
                <Menu
                  anchorEl={anchorPlaylist}
                  open={openPlayList}
                  onClose={handleClose}
                >
                  <Box sx={{ width: '300px' }}>
                    <MenuItem>
                      <Box className={classes.searchInput}>
                        <input
                          type="search"
                          onKeyDown={e => e.stopPropagation()}
                          onChange={e => handleSearchPlaylist(e.target.value)}
                          placeholder="Tìm danh sách phát"
                          className={classes.input}
                          style={{
                            padding: '10px',
                            border: 'none',
                            background: 'transparent'
                          }}
                        />
                      </Box>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    >
                      <Box sx={{ margin: '5px 0px' }}>
                        <Typography variant="subtitle1">
                          <i
                            className="fa-sharp fa-solid fa-square-plus fa-lg"
                            style={{ paddingRight: '7px' }}
                          ></i>
                          Tạo danh sách phát mới
                        </Typography>
                      </Box>
                    </MenuItem>
                    {dataPlaylist &&
                      dataPlaylist.map((el: any, index: any) => {
                        return (
                          <MenuItem
                            key={index}
                            onClick={e => {
                              e.stopPropagation();
                              handleAddMusicToPlaylist(el.id);
                            }}
                          >
                            <Box sx={{ margin: '5px 0px' }}>
                              <Typography variant="subtitle1">
                                <i
                                  className="fa-duotone fa-list-music fa-lg"
                                  style={{ paddingRight: '7px' }}
                                ></i>
                                {el.name}
                              </Typography>
                            </Box>
                          </MenuItem>
                        );
                      })}
                  </Box>
                </Menu>
              </MenuItem>
            )}
            {listMenuOptions
              .filter(item => item.visible === 'show')
              .map((el, index) => {
                return (
                  <MenuItem
                    onClick={() => {
                      el.actionMenu();
                    }}
                    key={index}
                  >
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{
                          ':hover': {
                            backgroundColor: 'unset'
                          }
                        }}
                        onClick={e => {
                          if (el.actionOption) {
                            el.actionOption(e);
                          }
                        }}
                      >
                        <ListItemIcon>{el.icon}</ListItemIcon>
                        <ListItemText primary={el.title} />
                      </ListItemButton>
                    </ListItem>
                  </MenuItem>
                );
              })}
            {/* {dataMusic?.page_owner?.page_relationship?.role === 'admin' && (
              <MenuItem>
                <Box sx={{ width: '100%' }} className={classes.boxNested}>
                  <NestedMenuItem
                    leftIcon={
                      <LibraryMusicIcon
                        sx={{ color: '#757575', marginLeft: '-3px' }}
                      />
                    }
                    rightIcon={''}
                    label="Thêm bài hát vào Album"
                    parentMenuOpen={open}
                  >
                    <Box sx={{ width: '300px' }}>
                      {listAlbum &&
                      listAlbum.filter(
                        (e: any) =>
                          e?.page_owner?.page_relationship?.role === 'admin'
                      ).length > 0 ? (
                        listAlbum
                          .filter(
                            (e: any) =>
                              e?.page_owner?.page_relationship?.role === 'admin'
                          )
                          .map((el: any, index: any) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  handleAddMusicToAlbum(el.id);
                                  // setOpenMenuLever2(null);
                                }}
                              >
                                <Box
                                  sx={{
                                    margin: '5px 0px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <img
                                    src={el.banner.preview_url}
                                    alt=""
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      objectFit: 'cover',
                                      borderRadius: '6px'
                                    }}
                                  />
                                  <Box sx={{ marginLeft: '10px' }}>
                                    <Typography variant="subtitle1">
                                      {el.name}
                                    </Typography>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: 300,
                                        fontSize: 12,
                                        lineHeight: '1rem'
                                      }}
                                    >
                                      {el.page_owner.title}
                                    </Typography>
                                  </Box>
                                </Box>
                              </MenuItem>
                            );
                          })
                      ) : (
                        <Box sx={{ height: '320px', textAlign: 'center' }}>
                          Bạn không có album nào
                        </Box>
                      )}
                    </Box>
                  </NestedMenuItem>
                </Box>
              </MenuItem>
            )} */}
          </Box>
        )}
      </Menu>
      {openDialog && (
        <DialogAddPlaylist
          open={openDialog}
          setOpen={setOpenDialog}
          idMusic={idMusic}
          handleAddMusicToPlaylist={handleAddMusicToPlaylist}
        />
      )}
      {openDialogCreate && (
        <CreateAlbum
          type="updateAlbum"
          valueTab="2"
          setOpenDialogCreate={setOpenDialogCreate}
          openDialogCreate={openDialogCreate}
          // listCategory={listCategory}
          // getCategoriesMusicSpace={getCategoriesMusicSpace}
          setNoti={setNoti}
          setOpenSnackbar={setOpenSnackbar}
          dataAlbum={dataAlbum}
        />
      )}
      {openDialogDelete && (
        <DialogConfirmDelete
          title="Xóa album"
          text="Bạn có chắc chắn muốn xóa ALbum này không?"
          open={openDialogDelete}
          handleClose={() => setOpenDialogDelete(false)}
          action={handleDeleteAlbum}
          labelButton="Xóa"
          loading={isLoadingDelete}
        />
      )}
      {openDialogDeleteMusic && (
        <DialogConfirmDelete
          title="Xóa bài hát"
          text="Bạn có chắc chắn muốn xóa bài này không?"
          open={openDialogDeleteMusic}
          handleClose={() => {
            setOpenDialogDeleteMusic(false);
            setIsLoadingDelete(false);
          }}
          action={() => {
            setIsLoadingDelete(true);
            handleDeleteMusic();
          }}
          labelButton="Xóa"
          loading={isLoadingDelete}
        />
      )}

      {openDialogReport && (
        <DialogReport
          open={openDialogReport}
          handleClose={() => setOpenDialogReport(false)}
          title={'Báo cáo bài hát vi phạm'}
          listCategoriesReport={listReport}
          typeReport="reportMusic"
          musicId={idMusic}
          selectCategories={true}
          inputDescription={true}
          stepDialog={1}
        />
      )}

      <CUPost
        type="music"
        typePost={'only-post'}
        music={dataMusic}
        openCUPost={openCUPostToFeed}
        setOpenCUPost={setOpenCUPostToFeed}
        handleFinal={() => setOpenCUPostToFeed(false)}
      />
    </Box>
  );
}
