import * as types from 'src/constants/store/scrollSocial';

export const scrollSocial = type => ({
  type: types.FIXED_SCROLL_BODY,
  payload: type
});

export const scrollSocialHeader = (options) => ({
  type: types.HEADER_RESPONSIVE,
  payload: options,
});