import * as types from 'src/constants/store/scrollSocial';

const initialState = {
  position: 'static',
  marginRight: '0px',
  widthHeader: 'calc(100% - 50px)',
  widthHeaderGlobal: '100%',
  paddingRight: '0px',
  widthHeaderGroup: 'calc(100% - 360px)',
};

export const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FIXED_SCROLL_BODY: {
      let type = action.payload;
      return {
        ...state,
        position: type
      };
    }
    case types.HEADER_RESPONSIVE: {
      const options = action.payload;
      return {
        ...state,
        ...options
      };
    }
    default:
      return state;
  }
};
